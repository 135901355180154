import { useCallback, useState } from 'react';
import { framesList } from '../framesList/framesList';
import FrameAnimation from '../frameAnimation/FrameAnimation';
import styles from './selectFrame.module.scss';
import BackgroundSVG from '../framePreview/backgroundSVG/BackgroundSVG';
import PriceBanner from '../../../connectivity/frameCreator/priceBanner/PriceBanner';

function SelectFrame({
  frameId,
  setFrameId,
  setStep,
  setColorScheme,
  promoCode,
  product,
  currency,
}) {
  const [showAnimation, setShowAnimation] = useState(false);

  const handleFrameClick = useCallback(
    (frame) => {
      setFrameId(frame.id);
      setColorScheme(frame.defaultColorScheme);
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
        setStep(2);
      }, 500);
    },
    [setColorScheme, setFrameId, setStep]
  );

  const nonIndividualSportFrames = framesList.filter(
    (frame) => frame.individualSport === false
  );

  const individualSportFrames = framesList.filter(
    (frame) => frame.individualSport === true
  );

  return (
    <div className={styles.selectFrame}>
      {product && currency && !promoCode && (
        <PriceBanner currency={currency} product={product} />
      )}
      <h2 className={styles.heading}>Select a template to get started</h2>
      <p className={styles.subHeading}>
        Click on the template you wish to style. You will be able to change the
        color and upload your logo in the next step.
      </p>

      <div className={styles.frames}>
        {nonIndividualSportFrames.map((frame) => {
          return (
            <div
              key={frame.id}
              className={styles.clickableElement}
              onClick={() => {
                handleFrameClick(frame);
              }}
            >
              {frame.component}
              {showAnimation && frame.id === frameId && <FrameAnimation />}
              <BackgroundSVG className={styles.svgBackground} />
            </div>
          );
        })}
      </div>
      <h5 className={styles.individualSportFramesHeading}>
        Individual templates
      </h5>
      <p className={styles.subHeading}>
        These templates doesn't have a player number
      </p>
      <div className={styles.frames}>
        {individualSportFrames.map((frame) => {
          return (
            <div
              key={frame.id}
              className={styles.clickableElement}
              onClick={() => {
                handleFrameClick(frame);
              }}
            >
              {frame.component}
              {showAnimation && frame.id === frameId && <FrameAnimation />}
              <BackgroundSVG className={styles.svgBackground} />
            </div>
          );
        })}
      </div>
      <p className={styles.contactParagraph}>
        Can't find what you're looking for?{' '}
        <a
          href="mailto:contact@striketradingcards.com?subject=Card creator"
          className={styles.contactLink}
        >
          Contact us for a customized design.
        </a>
      </p>
    </div>
  );
}

export default SelectFrame;
