import styles from './card.module.scss';

const Card = (props) => {
  return <section className={styles.card}>{props.children}</section>;
};

const CardHeader = (props) => {
  return <header className={styles.cardHead}>{props.children}</header>;
};

export { Card, CardHeader };
