import { API_URL, getBaseRequestConfig } from '../../baseRequestConfig';
import httpRequest from '../../httpRequest';

export function getCategories() {
  const baseRequestConfig = getBaseRequestConfig();
  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/public-api/v1/categories`,
    method: 'GET',
  });

  return httpRequest(requestConfig);
}
