export const strings = {
  logout: 'Logout',
  login: 'Login',
  email: 'Email',
  password: 'Password',
  code: 'Code',
  frameName: 'Frame name',
  width: 'Width',
  height: 'Height',
  priority: 'Priority',
  categories: 'Categories',
  design: 'DESIGN',
  frame: 'fame',
  created: 'Created',
  name: 'Name',
  phone: 'Phone',
  sport: 'Sport',
  country: 'Country',
  logotype: 'Logotype',
  clubCupOrCampName: 'Club, cup or camp - name',
  extraCategoryNeededToBeAdded: 'Extra category needed to be added',
  categoryTypes: {
    nationalTeam: 'National Team',
    role: 'Role',
    sportsType: 'Sports Type',
    club: 'Club',
    brand: 'Brand',
    country: 'Country',
    event: 'Event',
  },
  frameStatus: {
    disable: 'Disable frame',
    enable: 'Enable frame',
  },
  frameOrders: 'Frame orders',
  frameOrder: 'Frame order',
  approved: 'Approved',
  declined: 'Declined',
  decline: 'Decline',
  remove: 'Remove',
  cancel: 'Cancel',
  defaultImage: 'Default image',
  backgroundImage: 'Background image',
  type: 'Type',
  contentKey: 'Component key',
  hintText: 'Hint text',
  fontSize: 'Font size',
  fontColor: 'Font color',
  hintColor: 'Hint color',
  chosenColors: 'Chosen colors',
  highlightedColor: 'Highlighted color',
  chosenCategories: 'Chosen categories',
  letterSpacing: 'Letter spacing',
  fontFamily: 'Font family',
  fontWeight: 'Font weight',
  textAllCaps: 'Text all caps',
  privateData: 'Sensitive data',
  positionXGravity: 'x - gravity',
  positionXOffset: 'x - offset',
  positionYGravity: 'y - gravity',
  positionYOffset: 'y - offset',
  rotationAnchor: 'Rotation anchor',
  rotationValue: 'Rotation value',
  publishUpdates: 'Publish updates',
  publishFrame: 'Create & publish frame',
  components: 'Components',
  component: 'Component',
  mainData: 'Main data',
  copyFrame: 'Copy frame',
  newBackgroundImage: 'New background image',
  newDefaultImage: 'New default image',
  addComponent: 'Add component',
  selectAnOption: 'Select an option',
  removeComponent: 'Remove component',
  hint: 'Hint',
  componentContentKeys: {
    firstName: 'FIRST NAME',
    lastName: 'LAST NAME',
    team: 'TEAM',
    club: 'CLUB',
    playerNumber: '#',
    fullName: 'FIRST NAME LAST NAME',
    fullName2: 'FIRST NAME LAST NAME',
    forehandBackhand: 'FOREHAND',
    forehandBackhandShort: 'FH',
    age: 'AGE',
    skillLevel: 'SKILL 5',
    year: 'YYYY',
    seasonInformation: 'Season information',
  },
  languages: {
    eng: 'English',
    sv: 'Swedish',
  },
  confirm: {
    deleteCategory: 'Are you sure that you want to delete this category?',
  },
  error: {
    deleteCategory:
      'Something went wrong and the category might not have been deleted.',
    getLanguages: 'Failed to load available languages. Try to reload the page.',
    createCategory:
      'Something went wrong and the category might not have been created.',
    editCategory:
      'Something went wrong and your changes might not have been saved.',
  },
  success: {
    createCategory: 'Successfully created the category!',
    editCategory: 'Successfully updated the category!',
  },
  addCategory: 'Add category',
  createCategory: 'Create category',
  deleteCategory: 'Delete category',
  editCategory: 'Edit category',
  frames: 'Frames',
  categoryName: 'Category name',
  categoryNamePlaceholder: 'Hammarby Fotboll',
  categoryNameSynonyms: 'Synonyms (comma separated)',
  categoryNameSynonymsPlaceholder: 'HIF, Bajen',
  back: 'Back',
  saveChanges: 'Save changes',
  preview: 'Preview',
  review: 'REVIEW',
  view: 'VIEW',
  disable: 'disable',
  enable: 'enable',
  doYouWantToRemoveCategory: 'Do you want to remove category:',
  alert: {
    unableToFetchFrameOrder: 'Unable to fetch frame order',
    somethingWentWrong: 'Something went wrong',
    logoIsTooSmall: 'Logo is too small',
    unableToCompleteFrame: 'Unable to complete frame',
    unableToSaveChanges: 'Unable to save changes',
    couldNotDeclineOrder: 'Could not decline order',
  },
  SelectedSeasonFormat: 'Selected season format',
  seasonFormat: 'Season format',
  logoPlacementInPercent: 'Logo placement in %',
  ToSeeNewLogoPlacement:
    'To see the new logo placement, click the preview-button',
  xAxisLabel: 'X axis (% counted from left)',
  yAxisLabel: 'Y axis (% counted from top)',
  approvalOfFinalDesign: 'APPROVAL OF FINAL DESIGN',
  declineDesign: 'DECLINE DESIGN',
  tryWithDifferentBackgroundColors: 'Try with different background colors',
  thisIsAPreviewOfTheFinalDesign:
    'This is a preview of the final version of the frame, please check all the details before approving the design.',
  areYouSureYouWantToDecline:
    'Are you sure you want to decline this frame design',
  amendDesign: 'AMEND DESIGN',
  noIChangedMyMind: 'NO, I CHANGED MY MIND',
  approveDesign: 'APPROVE DESIGN',
  yesDeclineDesign: 'YES, DECLINE DESIGN',
  daysLeftToApprove: 'days left to approve',
  couldNot: 'Could not',
};
