import { useCallback, useEffect, useState } from 'react';
import {
  getCategoriesMap,
  CATEGORY_TYPES,
} from '../../../connectivity/admin/ApiAdminCategories';
import { strings } from '../../../localization/Localization';
import { ROUTES } from '../../../index';
import { DeleteCategoryButton } from '../../../components/deleteCategoryButton/DeleteCategoryButton';
import { Add } from '../../../components/icons/Add';
import { Edit } from '../../../components/icons/Edit';
import { Button } from '@carbon/react/es/components/Button';
import { ArrowLeft } from '../../../components/icons/ArrowLeft';
import { getLocalization } from '../../../connectivity/admin/ApiLocalization';
import { Header } from '../../../components/header/Header';
import { Main } from '../../../components/main/Main';
import { Card, CardHeader } from '../../../components/card/Card';
import styles from './categories.module.scss';
import { BackHeader } from '../../../components/header/BackHeader';

export const Categories = () => {
  const [categoryTypesData, setCategoryTypesData] = useState([]);
  const [languages, setLanguages] = useState([]);

  const refreshData = useCallback(() => {
    getCategoriesMap()
      .then((response) => {
        setCategoryTypesData(response.data.categoryMap);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getLocalization()
      .then((response) => {
        setLanguages(response.data.languages);
      })
      .catch((error) => console.log(error));

    refreshData();
  }, [refreshData]);

  return (
    <>
      <BackHeader href={ROUTES.admin} />
      <Main>
        <h1>{strings.categories}</h1>
        {categoryTypesData.map((categoryTypeData) => {
          return (
            <Card key={categoryTypeData.type}>
              <CardHeader>
                <h2>
                  {strings.categoryTypes[
                    CATEGORY_TYPES[categoryTypeData.type]
                  ] ?? categoryTypeData.type}
                </h2>
                <Button
                  href={`${ROUTES.category}?type=${categoryTypeData.type}`}
                  renderIcon={(props) => (
                    <Add width={16} height={16} {...props} />
                  )}
                  style={{ textDecoration: 'none' }}
                >
                  {strings.addCategory}
                </Button>
              </CardHeader>
              {languages.length > 0 ? (
                <div className={styles.tableWrapper}>
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th></th>
                        {[...languages].map((language) => (
                          <th key={language}>{strings.languages[language]}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {categoryTypeData.categories.map((category) => (
                        <tr key={category.id}>
                          <td>
                            <span className={styles.buttons}>
                              <Button
                                hasIconOnly
                                iconDescription={strings.editCategory}
                                tooltipAlignment="start"
                                style={{ textDecoration: 'none' }}
                                href={`${ROUTES.category}/${category.id}`}
                              >
                                <Edit width="16" height="16" />
                              </Button>
                              <DeleteCategoryButton
                                categoryId={category.id}
                                className="bx--btn--sm"
                                callback={refreshData}
                              />
                            </span>
                          </td>
                          {[...languages].map((language) => {
                            const categoryNameInCurrentLanguage =
                              category.name.find(
                                (name) => name.languageKey === language
                              );

                            return (
                              <td key={language}>
                                {categoryNameInCurrentLanguage && (
                                  <>
                                    <span className={styles.category}>
                                      {categoryNameInCurrentLanguage.value}
                                    </span>
                                    <br />
                                    <span className={styles.synonyms}>
                                      {categoryNameInCurrentLanguage.synonyms.map(
                                        (synonym, index) => (
                                          <span
                                            key={synonym}
                                            className={styles.synonym}
                                          >
                                            {`${synonym}${
                                              index !==
                                              categoryNameInCurrentLanguage
                                                .synonyms.length -
                                                1
                                                ? ', '
                                                : ''
                                            }`}
                                          </span>
                                        )
                                      )}
                                    </span>
                                  </>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className={styles.errorMessage}>
                  {strings.error.getLanguages}
                </p>
              )}
            </Card>
          );
        })}
      </Main>
    </>
  );
};
