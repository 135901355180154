import { addAlphaChannelToHexCode } from '../../../../utils/frameCreatorUtils';

export const frameTwelveTextComponents = (seasonInformation, colorScheme) => {
  return [
    {
      type: 'text',
      content: {
        key: 'lastName',
        cardHint: [
          {
            key: 'sv',
            iosLanguageKey: 'sv',
            value: 'EFTERNAMN',
          },
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'LAST NAME',
          },
        ],
      },
      font: {
        size: 0.0562963,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0217105,
        family: 'TitilliumWeb',
        weight: 'Bold',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.0296296,
        },
        y: {
          gravity: 'middle',
          offset: 0.285926,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'firstName',
        cardHint: [
          {
            key: 'sv',
            iosLanguageKey: 'sv',
            value: 'FÖRNAMN',
          },
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'FIRST NAME',
          },
        ],
      },
      font: {
        size: 0.0333333,
        color: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0217778,
        family: 'TitilliumWeb',
        weight: 'SemiBold',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.0296296,
        },
        y: {
          gravity: 'middle',
          offset: 0.356667,
        },
        rotation: null,
      },
    },
    {
      content: {
        cardHint: [
          {
            key: 'eng',
            value: seasonInformation,
          },
        ],
        key: 'seasonInformation',
      },
      font: {
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        size: 0.0311111,
        letterSpacing: 0.0833333,
        family: 'TitilliumWeb',
        weight: 'SemiBold',
        textAllCaps: true,
      },
      position: {
        rotation: null,
        x: {
          gravity: 'start',
          offset: 0.0296296,
        },
        y: {
          gravity: 'middle',
          offset: 0.423333,
        },
      },
      privateData: true,
      type: 'hardcoded_text',
    },
    {
      type: 'text',
      content: {
        key: 'team',
        cardHint: [
          {
            key: 'sv',
            iosLanguageKey: 'sv',
            value: 'LAG',
          },
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'TEAM',
          },
        ],
      },
      font: {
        size: 0.0333333,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0833333,
        family: 'TitilliumWeb',
        weight: 'SemiBold',
        textAllCaps: true,
      },
      privateData: false,
      position: {
        x: {
          gravity: 'end',
          offset: 0.38,
        },
        y: {
          gravity: 'middle',
          offset: 0.423333,
        },
        rotation: null,
      },
    },
  ];
};
