export const Orders = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" fill="transparent" />
      <path d="M8 11H15V12H8V11Z" fill="white" />
      <path d="M8 4H15V5H8V4Z" fill="white" />
      <path d="M4 6V2H3V2.5H2V3.5H3V6H2V7H3H4H5V6H4Z" fill="white" />
      <path
        d="M5 14H2V12C2 11.7348 2.10536 11.4804 2.29289 11.2929C2.48043 11.1054 2.73478 11 3 11H4V10H2V9H4C4.26522 9 4.51957 9.10536 4.70711 9.29289C4.89464 9.48043 5 9.73478 5 10V11C5 11.2652 4.89464 11.5196 4.70711 11.7071C4.51957 11.8946 4.26522 12 4 12H3V13H5V14Z"
        fill="white"
      />
    </svg>
  );
};
