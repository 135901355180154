import React, { Component } from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import styles from './content.module.scss';
class Content extends Component {
  render() {
    return (
      <main className={styles.main}>
        <Container fluid>
          <Row className={`align-items-center ${styles.intro}`}>
            <Col xs={12} md={12} lg={6}>
              <Row>
                <Col>
                  <h2 className={styles.heading}>
                    Create and share your own sportcards
                  </h2>
                </Col>
              </Row>
              <Row className={`${styles.badges} text-center`}>
                <Col xs={12} md={6} className={styles.appstore}>
                  <a
                    href="https://apps.apple.com/se/app/strike-trading-cards/id1438521483"
                    target="_BLANK"
                    rel="noreferrer"
                  >
                    <img
                      src="./img/Download_on_the_App_Store_Badge.svg"
                      alt=""
                      className={styles.appBadge}
                    />
                  </a>
                </Col>
                <Col xs={12} md={6} className={styles.gplay}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.strike.app&hl=en_US"
                    target="_BLANK"
                    rel="noreferrer"
                  >
                    <img
                      src="./img/google-play-badge.svg"
                      alt=""
                      className={styles.appBadge}
                    />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <Image
                src="./img/Strike_Phone-Framesx.png"
                alt=""
                className={styles.preview}
              />
            </Col>
          </Row>
          <Row className={styles.about}>
            <Col
              xs={{ span: 12, order: 12 }}
              md={12}
              lg={{ span: 6, order: 1 }}
            >
              <Image src="./img/Image_strike_Isolated1@3x.png" alt="logo-bp" />
              <Image
                src="./img/Image_strike_Isolated2@3x.png"
                alt="logo-skuru"
              />
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              md={12}
              lg={{ span: 6, order: 12 }}
            >
              <h3 className={styles.heading}>What is Strike?</h3>
              <p>
                Strike works together with sports associations to help young
                people feel like real stars, by allowing them to celebrate their
                sports memories by creating their very own sports cards.
              </p>
              <p>
                We do this by providing the digital conditions needed to bolster
                the spirit of the association and its members, as well as
                strengthening their brand in the eyes of partners and sponsors.
              </p>
              <p>
                If you represent an association and you’d like to learn more
                about how Strike works, please{' '}
                <a href="mailto:contact@striketradingcards.com">get in touch</a>
                .
              </p>
            </Col>
          </Row>
          <Row className={styles.contacts}>
            <Col xs={12} md={12} lg={6}>
              <Row className="align-items-center">
                <Col xs={12} md={4} className="text-center">
                  <Image
                    src="./img/mathias.png"
                    roundedCircle
                    className={styles.contactImage}
                  />
                </Col>
                <Col xs={12} md={8}>
                  <div className={styles.contactInfo}>
                    <span className={styles.contactName}>Mathias Lind</span>
                    <span className={styles.contactTitle}>CEO & Founder</span>
                    <span className={styles.contactPhone}>
                      <a href="tel:+46 70 729 37 22">+46 70 729 37 22</a>
                    </span>
                    <span className="contactMail">
                      <a href="mailto:mathias.lind@striketradingcards.com">
                        mathias.lind@striketradingcards.com
                      </a>
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <Row className="align-items-center">
                <Col xs={12} md={4} className="text-center">
                  <Image
                    src="./img/janis.png"
                    roundedCircle
                    className={styles.contactImage}
                  />
                </Col>
                <Col xs={12} md={8}>
                  <div className={styles.contactInfo}>
                    <span className={styles.contactName}>Janis Petrov</span>
                    <span className={styles.contactTitle}>COO & Founder</span>
                    <span className={styles.contactPhone}>
                      <a href="tel:+46 70 54 54 258">+46 70 54 54 258</a>
                    </span>
                    <span className="contactMail">
                      <a href="mailto:janis.petrov@striketradingcards.com">
                        janis.petrov@striketradingcards.com
                      </a>
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </main>
    );
  }
}

export default Content;
