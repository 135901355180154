import { getBaseRequestConfig, API_URL } from '../baseRequestConfig';
import httpRequest from '../httpRequest';

export function getCategoriesMap() {
  const baseRequestConfig = getBaseRequestConfig();
  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/public-api/v1/categories/map`,
  });

  return httpRequest(requestConfig);
}

// Endpoint exists but not used by admin
// export function getCategories() {
//   const baseRequestConfig = getBaseRequestConfig();
//   const requestConfig = Object.assign({}, baseRequestConfig, {
//     url: `${API_URL}/public-api/v1/categories`,
//   });

//   return httpRequest(requestConfig);
// }

export function getCategory(categoryId) {
  const baseRequestConfig = getBaseRequestConfig();
  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/public-api/v1/categories/${categoryId}`,
  });

  return httpRequest(requestConfig);
}

export function postCategory(accessToken, data) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);
  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/admin-api/v1/categories`,
    method: 'POST',
    data,
  });

  return httpRequest(requestConfig);
}

export function putCategory(accessToken, categoryId, data) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);
  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/admin-api/v1/categories/${categoryId}`,
    method: 'PUT',
    data,
  });

  return httpRequest(requestConfig);
}

export function deleteCategory(accessToken, categoryId) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);
  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/admin-api/v1/categories/${categoryId}`,
    method: 'DELETE',
  });

  return httpRequest(requestConfig);
}

export const CATEGORY_TYPES = {
  BRAND: 'brand',
  CLUB: 'club',
  COUNTRY: 'country',
  EVENT: 'event',
  NATIONAL_TEAM: 'nationalTeam',
  ROLE: 'role',
  SPORTS_TYPE: 'sportsType',
};
