import { API_URL, getBaseRequestConfig } from '../baseRequestConfig';
import httpRequest from '../httpRequest';

export function postOrder(data) {
  const baseRequestConfig = getBaseRequestConfig();
  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/public-api/v1/orders`,
    method: 'POST',
    data,
  });

  return httpRequest(requestConfig);
}

export function completeOrder(adminAccessToken, orderId, data) {
  const baseRequestConfig = getBaseRequestConfig(adminAccessToken);
  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/public-api/v1/orders/${orderId}/complete`,
    method: 'PUT',
    data,
  });

  return httpRequest(requestConfig);
}
