import { API_URL, getBaseRequestConfig } from '../baseRequestConfig';
import httpRequest from '../httpRequest';

export function getPrices() {
  const baseRequestConfig = getBaseRequestConfig();
  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/public-api/v1/payments/prices`,
    method: 'GET',
  });

  return httpRequest(requestConfig);
}

export function createIntent(data) {
  const baseRequestConfig = getBaseRequestConfig();
  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/public-api/v1/payments/create-intent`,
    method: 'POST',
    data,
  });

  return httpRequest(requestConfig);
}

export function updateIntent(data) {
  const baseRequestConfig = getBaseRequestConfig();
  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/public-api/v1/payments/update-intent`,
    method: 'PUT',
    data,
  });

  return httpRequest(requestConfig);
}
