import { emptyAsUndefined } from './stringUtils';

export const COOKIES_ADMIN = 'cookiesAdmin';
export const COOKIE_ADMIN_ACCESS_TOKEN_STAGE = 'stageAccessToken';
export const COOKIE_ADMIN_ACCESS_TOKEN = 'prodAccessToken';

export function getCookie(cookies, key) {
  return emptyAsUndefined(cookies[key]);
}

export function getAccessToken(cookies) {
  return getCookie(cookies, COOKIE_ADMIN_ACCESS_TOKEN);
}
