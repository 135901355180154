import FrameOne from '../frameComponents/frameOne/FrameOne';
import FrameTwo from '../frameComponents/frameTwo/FrameTwo';
import FrameThree from '../frameComponents/frameThree/FrameThree';
import FrameFour from '../frameComponents/frameFour/FrameFour';
import FrameFive from '../frameComponents/frameFive/FrameFive';
import FrameSix from '../frameComponents/frameSix/FrameSix';
import FrameSeven from '../frameComponents/frameSeven/FrameSeven';
import FrameEight from '../frameComponents/frameEight/FrameEight';
import FrameNine from '../frameComponents/frameNine/FrameNine';
import FrameTen from '../frameComponents/frameTen/FrameTen';
import FrameEleven from '../frameComponents/frameEleven/FrameEleven';
import FrameTwelve from '../frameComponents/frameTwelve/FrameTwelve';

const colorSchemes = {
  frameOne: {
    frameColors: {
      primary: '#00C4CB',
      secondary: '#757575',
      tertiary: undefined,
    },
    textColors: {
      primary: '#000000',
      secondary: '#FFFFFF',
      tertiary: undefined,
    },
  },
  frameTwo: {
    frameColors: {
      primary: '#00C4CB',
      secondary: '#757575',
      tertiary: undefined,
    },
    textColors: {
      primary: '#000000',
      secondary: '#FFFFFF',
      tertiary: undefined,
    },
  },
  frameThree: {
    frameColors: {
      primary: '#00C4CB',
      secondary: '#000000',
      tertiary: '#757575',
    },
    textColors: {
      primary: '#FFFFFF',
      secondary: undefined,
      tertiary: undefined,
    },
  },
  frameFour: {
    frameColors: {
      primary: '#00C4CB',
      secondary: '#757575',
      tertiary: '#757575',
    },
    textColors: {
      primary: '#FFFFFF',
      secondary: '#000000',
      tertiary: undefined,
    },
  },
  frameFive: {
    frameColors: {
      primary: '#00C4CB',
      secondary: '#757575',
      tertiary: '#FFFFFF',
    },
    textColors: {
      primary: '#FFFFFF',
      secondary: undefined,
      tertiary: undefined,
    },
  },
  frameSix: {
    frameColors: {
      primary: '#00C4CB',
      secondary: '#757575',
      tertiary: '#FFFFFF',
    },
    textColors: {
      primary: '#FFFFFF',
      secondary: '#000000',
      tertiary: '#00C4CB',
    },
  },
  frameSeven: {
    frameColors: {
      primary: '#00C4CB',
      secondary: '#757575',
      tertiary: '#00C4CB',
    },
    textColors: {
      primary: '#FFFFFF',
      secondary: '#000000',
      tertiary: '#FFFFFF',
    },
  },
  frameEight: {
    frameColors: {
      primary: '#757575',
      secondary: '#00C4CB',
      tertiary: '#000000',
    },
    textColors: {
      primary: '#FFFFFF',
      secondary: '#000000',
      tertiary: '#FFFFFF',
    },
  },
  frameNine: {
    frameColors: {
      primary: '#00C4CB',
      secondary: '#757575',
      tertiary: undefined,
    },
    textColors: {
      primary: '#000000',
      secondary: '#FFFFFF',
      tertiary: undefined,
    },
  },
  frameTen: {
    frameColors: {
      primary: '#00C4CB',
      secondary: '#757575',
      tertiary: '#FFFFFF',
    },
    textColors: {
      primary: '#FFFFFF',
      secondary: undefined,
      tertiary: undefined,
    },
  },
  frameEleven: {
    frameColors: {
      primary: '#00C4CB',
      secondary: '#757575',
      tertiary: '#FFFFFF',
    },
    textColors: {
      primary: '#FFFFFF',
      secondary: '#000000',
      tertiary: '#00C4CB',
    },
  },
  frameTwelve: {
    frameColors: {
      primary: '#757575',
      secondary: '#00C4CB',
      tertiary: '#000000',
    },
    textColors: {
      primary: '#FFFFFF',
      secondary: '#000000',
      tertiary: undefined,
    },
  },
};

const frameStyle = {
  position: 'relative',
  zIndex: 2,
};

const frameProps = {
  showLogoPlaceHolder: true,
  showText: true,
  showSeasonYear: true,
};

export const framesList = [
  {
    component: (
      <FrameOne
        {...frameProps}
        colorScheme={colorSchemes.frameOne}
        style={frameStyle}
      />
    ),
    id: 1,
    logoPlacementInPercent: {
      x: 22,
      y: 80,
    },
    logoSizeInPx: {
      width: 207,
      height: 207,
    },
    individualSport: false,
    defaultColorScheme: colorSchemes.frameOne,
  },
  {
    component: (
      <FrameTwo
        {...frameProps}
        colorScheme={colorSchemes.frameTwo}
        style={frameStyle}
      />
    ),
    id: 2,
    logoSizeInPx: {
      width: 220,
      height: 220,
    },
    logoPlacementInPercent: {
      x: 8,
      y: 74,
    },
    individualSport: false,
    defaultColorScheme: colorSchemes.frameTwo,
  },
  {
    component: (
      <FrameThree
        {...frameProps}
        colorScheme={colorSchemes.frameThree}
        style={frameStyle}
      />
    ),
    id: 3,
    logoSizeInPx: {
      width: 205,
      height: 205,
    },
    logoPlacementInPercent: {
      x: 44,
      y: 68,
    },
    individualSport: false,
    defaultColorScheme: colorSchemes.frameThree,
  },
  {
    component: (
      <FrameFour
        {...frameProps}
        colorScheme={colorSchemes.frameFour}
        style={frameStyle}
      />
    ),
    id: 4,
    logoPlacementInPercent: {
      x: 21,
      y: 80,
    },
    logoSizeInPx: {
      width: 207,
      height: 207,
    },
    individualSport: false,
    defaultColorScheme: colorSchemes.frameFour,
  },
  {
    component: (
      <FrameFive
        {...frameProps}
        colorScheme={colorSchemes.frameFive}
        style={frameStyle}
      />
    ),
    id: 5,
    logoSizeInPx: {
      width: 200,
      height: 200,
    },
    logoPlacementInPercent: {
      x: 43,
      y: 72,
    },
    individualSport: false,
    defaultColorScheme: colorSchemes.frameFive,
  },
  {
    component: (
      <FrameSix
        {...frameProps}
        colorScheme={colorSchemes.frameSix}
        style={frameStyle}
      />
    ),
    id: 6,
    logoPlacementInPercent: {
      x: 5,
      y: 5,
    },
    logoSizeInPx: {
      width: 209,
      height: 209,
    },
    individualSport: false,
    defaultColorScheme: colorSchemes.frameSix,
  },
  {
    component: (
      <FrameSeven
        {...frameProps}
        colorScheme={colorSchemes.frameSeven}
        style={frameStyle}
      />
    ),
    id: 7,
    logoPlacementInPercent: {
      x: 3,
      y: 80,
    },
    logoSizeInPx: {
      width: 207,
      height: 207,
    },
    individualSport: false,
    defaultColorScheme: colorSchemes.frameSeven,
  },
  {
    component: (
      <FrameEight
        {...frameProps}
        colorScheme={colorSchemes.frameEight}
        style={frameStyle}
      />
    ),
    id: 8,
    logoPlacementInPercent: {
      x: 80,
      y: 76,
    },
    logoSizeInPx: {
      width: 207,
      height: 207,
    },
    individualSport: false,
    defaultColorScheme: colorSchemes.frameEight,
  },
  {
    component: (
      <FrameNine
        {...frameProps}
        colorScheme={colorSchemes.frameNine}
        style={frameStyle}
      />
    ),
    id: 9,
    logoSizeInPx: {
      width: 204,
      height: 204,
    },
    logoPlacementInPercent: {
      x: 8,
      y: 74,
    },
    individualSport: true,
    defaultColorScheme: colorSchemes.frameNine,
  },
  {
    component: (
      <FrameTen
        {...frameProps}
        colorScheme={colorSchemes.frameTen}
        style={frameStyle}
      />
    ),
    id: 10,
    logoSizeInPx: {
      width: 200,
      height: 200,
    },
    logoPlacementInPercent: {
      x: 43,
      y: 72,
    },
    individualSport: true,
    defaultColorScheme: colorSchemes.frameTen,
  },
  {
    component: (
      <FrameEleven
        {...frameProps}
        colorScheme={colorSchemes.frameEleven}
        style={frameStyle}
      />
    ),
    id: 11,
    logoPlacementInPercent: {
      x: 3,
      y: 80,
    },
    logoSizeInPx: {
      width: 209,
      height: 209,
    },
    individualSport: true,
    defaultColorScheme: colorSchemes.frameEleven,
  },
  {
    component: (
      <FrameTwelve
        {...frameProps}
        colorScheme={colorSchemes.frameTwelve}
        style={frameStyle}
      />
    ),
    id: 12,
    logoPlacementInPercent: {
      x: 80,
      y: 76,
    },
    logoSizeInPx: {
      width: 207,
      height: 207,
    },
    individualSport: true,
    defaultColorScheme: colorSchemes.frameTwelve,
  },
];
