import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../components/button/Button';
import ImageUploader from '../../components/imageUploader/ImageUploader';
import { MultiSelectInput } from '../../components/multiSelectInput/MultiSelectInput';
import TextInput from '../../components/textInput/TextInput';
import {
  uploadDefaultImage,
  uploadFrameImage,
} from '../../connectivity/admin/images/ApiAdminImages';
import {
  getCategoriesMap,
  CATEGORY_TYPES,
} from '../../connectivity/admin/ApiAdminCategories';
import { FONTS } from '../../fonts/FontUtils';
import { strings } from '../../localization/Localization';
import { getTranslationValue } from '../../localization/localtizationUtils';
import {
  COMPONENT_CONTENT_KEY,
  COMPONENT_TYPE,
  formToFrame,
  POSITION_GRAVITY,
} from '../frameUtils';
import { environment } from '../../connectivity/environments';

import styles from './editFrameFields.module.scss';
import TextComponent from './textComponent/TextComponent';

export const FORM_KEYS = {
  code: 'code',
  name: 'name',
  backgroundImage: 'backgroundImage',
  defaultImage: 'defaultImage',
  sizeWidth: 'sizeWidth',
  sizeHeight: 'sizeHeight',
  priority: 'priority',
  components: 'components',
};

export const FORM_KEYS_COMPONENT = {
  positionRotationAnchor: 'position.rotation.anchorPoint',
  positionRotationValue: 'position.rotation.value',
  positionXOffset: 'position.x.offset',
  positionYOffset: 'position.y.offset',
  positionXGravity: 'position.x.gravity',
  positionYGravity: 'position.y.gravity',
  textAllCaps: 'textAllCaps',
  privateData: 'privateData',
  fontWeight: 'font.weight',
  fontFamily: 'font.family',
  fontLetterSpacing: 'font.letterSpacing',
  fontHighlightedColor: 'font.highlightedColor',
  fontHintColor: 'font.hintColor',
  fontColor: 'font.color',
  fontSize: 'font.size',
  cardHint: 'cardHint',
  contentKey: 'content.key',
  type: 'type',
};

function getComponentFormName(position, componentFormKey) {
  return `${FORM_KEYS.components}.${position}.${componentFormKey}`;
}

function EditFrameFields({ initialData = {}, onFrameUpdated, accessToken }) {
  const lastComponentRef = useRef(null);
  const { control, handleSubmit, watch, setValue } = useForm();

  const [textComponents, setTextComponents] = useState(
    initialData?.components?.map((component) => ({
      ...component,
      collapsed: true,
    })) ?? []
  );
  const [categoryTypesData, setCategoryTypesData] = useState([]);

  const codeFieldValue = watch(FORM_KEYS.code, '');

  useEffect(() => {
    getCategoriesMap()
      .then((response) => {
        setCategoryTypesData(response.data.categoryMap);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedFrame = formToFrame(watch(), initialData, textComponents);
      if (updatedFrame) {
        onFrameUpdated(updatedFrame);
      }
    }, 2000);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [initialData, onFrameUpdated, watch, textComponents]);

  const onBackgroundImageSelected = (file) => {
    uploadFrameImage(accessToken, file)
      .then((response) => {
        setValue(FORM_KEYS.backgroundImage, response.data.src);
      })
      .catch((error) => {
        console.error(error);
        // todo failed to upload image
        alert('Failed to upload image');
      });
  };

  const onDefaultImageSelected = (file) => {
    uploadDefaultImage(accessToken, file)
      .then((response) => {
        setValue(FORM_KEYS.defaultImage, response.data.src);
      })
      .catch((error) => {
        console.error(error);
        // todo failed to upload image
        alert('Failed to upload image');
      });
  };

  const onRemoveComponent = (index) => {
    const newComponents = textComponents.slice(0);
    newComponents[index].removed = true;
    setTextComponents(newComponents);
  };

  const onAddComponent = () => {
    setTextComponents([
      ...textComponents,
      {
        content: {
          key: COMPONENT_CONTENT_KEY.selectAnOption.key,
          cardHint: [
            {
              key: 'eng',
              value: strings.hint,
            },
          ],
        },
        font: {
          size: 0.05185,
          family: FONTS.TITILLIUM.family,
          color: '#FF000000',
          hintColor: '#66000000',
          highlightedColor: '#FF42c1c9',
          letterSpacing: 0.03129,
          textAllCaps: true,
          weight: FONTS.TITILLIUM.weights[0],
        },
        position: {
          x: {
            gravity: POSITION_GRAVITY.middle,
            offset: '0',
          },
          y: {
            gravity: POSITION_GRAVITY.middle,
            offset: '0',
          },
        },
        privateData: false,
        type: COMPONENT_TYPE.text,
      },
    ]);
    setTimeout(() => {
      lastComponentRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  };

  const onSubmit = () => {};
  return (
    <div className={styles.editFrameFields}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.mainFrameData}>
          <h3>{strings.mainData}</h3>
          <TextInput
            defaultValue={getTranslationValue(initialData.name)}
            control={control}
            className={styles.input}
            name={FORM_KEYS.name}
            labelText={strings.frameName}
          />
          <TextInput
            defaultValue={initialData.code}
            control={control}
            className={styles.input}
            name={FORM_KEYS.code}
            labelText={strings.code}
            helperText={
              codeFieldValue && (
                <a
                  href={`https://chart.apis.google.com/chart?cht=qr&chs=300x300&chl=${environment.APP_URL}/code/${codeFieldValue}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Show QR-code
                </a>
              )
            }
          />
          <TextInput
            defaultValue={initialData.size?.width}
            control={control}
            type="number"
            className={styles.input}
            name={FORM_KEYS.sizeWidth}
            labelText={strings.width}
          />
          <TextInput
            defaultValue={initialData.size?.height}
            control={control}
            type="number"
            className={styles.input}
            name={FORM_KEYS.sizeHeight}
            labelText={strings.height}
          />
          <TextInput
            defaultValue={initialData.priority}
            control={control}
            type="number"
            className={styles.input}
            name={FORM_KEYS.priority}
            labelText={strings.priority}
          />
          {categoryTypesData.length > 0 && (
            <>
              <h5
                className={styles.componentTitle}
                style={{ position: 'static', marginTop: '2rem' }}
              >
                {strings.categories}
              </h5>
              {categoryTypesData.map((categoryTypeData) => {
                const items = categoryTypeData.categories.map((category) => ({
                  id: category.id,
                  text: category.name.find((obj) => obj.languageKey === 'eng')
                    ?.value,
                }));
                const initialSelectedItems = items.filter((item) =>
                  initialData.categoryIds?.includes(item.id)
                );
                return (
                  <MultiSelectInput
                    key={categoryTypeData.type}
                    name={`category-${categoryTypeData.type.toLowerCase()}`}
                    label={
                      strings.categoryTypes[
                        CATEGORY_TYPES[categoryTypeData.type]
                      ] ?? categoryTypeData.type
                    }
                    initialSelectedItems={initialSelectedItems}
                    control={control}
                    items={items}
                  />
                );
              })}
              <hr style={{ borderColor: '#000' }} />
            </>
          )}
          <ImageUploader
            text={strings.newBackgroundImage}
            className={styles.newBackgroundImage}
            onImageSelected={onBackgroundImageSelected}
          />
          <TextInput
            defaultValue={initialData.backgroundImage?.src}
            control={control}
            className={styles.input}
            name={FORM_KEYS.backgroundImage}
            labelText={strings.backgroundImage}
          />
          <ImageUploader
            text={strings.newDefaultImage}
            className={styles.newDefaultImage}
            onImageSelected={onDefaultImageSelected}
          />
          <TextInput
            defaultValue={initialData.defaultImage?.src}
            control={control}
            imageUpload={true}
            className={styles.input}
            name={FORM_KEYS.defaultImage}
            labelText={strings.defaultImage}
          />
        </div>
        <div className={styles.components}>
          <h3>{strings.components}</h3>
          <Button size="sm" onClick={onAddComponent}>
            {strings.addComponent}
          </Button>
          {textComponents.map((component, index) => {
            return (
              <TextComponent
                key={index}
                allTextComponents={textComponents}
                textComponent={component}
                onRemoveComponent={onRemoveComponent}
                lastComponentRef={lastComponentRef}
                control={control}
                watch={watch}
                index={index}
                getComponentFormName={getComponentFormName}
              />
            );
          })}
        </div>
      </form>
    </div>
  );
}

export default EditFrameFields;
