import { TextArea as CarbonTextArea } from '@carbon/react/es/components/TextArea';
import React from 'react';
import { Controller } from 'react-hook-form';

function TextArea({
  name,
  className,
  defaultValue,
  control,
  helperText,
  invalidText,
  labelText,
  placeholder,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, onChange }) => {
        return (
          <CarbonTextArea
            id={field.name}
            onChange={onChange}
            invalidText={invalidText}
            labelText={labelText}
            placeholder={placeholder}
            helperText={helperText}
            className={className}
            {...field}
          />
        );
      }}
    />
  );
}

export default TextArea;
