import classNames from 'classnames';
import React from 'react';
import Button from '../../components/button/Button';
import { strings } from '../../localization/Localization';
import { getTranslationValue } from '../../localization/localtizationUtils';
import { isDisabled } from '../frameUtils';
import FrameViewer, { PREVIEW_TYPES } from '../frameViewer/FrameViewer';

import styles from './frameTeaser.module.scss';
import { updateFrameStatus } from '../../connectivity/admin/frames/ApiAdminFrames';

function FrameTeaser({ frame, onClick, className, onCopyFrame, accessToken }) {
  const statusIsEnabled = frame.status === 'ACTIVE';

  const onChangeStatusClick = (status) => {
    updateFrameStatus(accessToken, frame.id, status)
      .then(() => {
        location.reload();
      })
      .catch((error) => {
        console.error(error);
        alert(
          `${strings.couldNot} ${
            statusIsEnabled ? strings.disable : strings.enable
          } ${strings.frame}`
        );
      });
  };

  return (
    <div
      onClick={() => onClick(frame)}
      className={classNames(
        styles.frameTeaser,
        isDisabled(frame) && styles.disabled,
        className
      )}
    >
      <div className={styles.textContent}>
        <p>{getTranslationValue(frame.name)}</p>
        <p>
          {strings.code}: {frame.code}
        </p>
        <p>
          {strings.priority}: {frame.priority}
        </p>
        <div className={styles.buttonContainer}>
          {onCopyFrame && (
            <Button
              className={styles.copyButton}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onCopyFrame(frame);
              }}
            >
              {strings.copyFrame}
            </Button>
          )}
          <Button
            kind={statusIsEnabled ? 'danger' : 'secondary'}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onChangeStatusClick(statusIsEnabled ? 'DISABLED' : 'ACTIVE');
            }}
          >
            {statusIsEnabled
              ? strings.frameStatus.disable
              : strings.frameStatus.enable}
          </Button>
        </div>
      </div>
      <FrameViewer
        previewType={PREVIEW_TYPES.example}
        frameWidth={80}
        frame={frame}
      />
    </div>
  );
}

export default FrameTeaser;
