import { API_URL, getBaseRequestConfig } from '../../baseRequestConfig';
import httpRequest from '../../httpRequest';

export function getPromoCodes(accessToken, page) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/admin-api/v1/promo-codes?page=${page}`,
    method: 'GET',
  });

  return httpRequest(requestConfig);
}

export function createPromoCodes(accessToken, data) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/admin-api/v1/promo-codes`,
    method: 'POST',
    data: data,
  });

  return httpRequest(requestConfig);
}
