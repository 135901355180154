function getErrorFromErrorCode(errorCode) {
  const errorCodesEnabled = false;

  if ((!errorCode || !errorCodesEnabled) && errorCode !== 0) {
    return;
  }

  switch (errorCode) {
    case 0: // General error
      return '';
    case 100:
      return '';
  }
}

function getFormattedHttpReqError(error) {
  const errorFormat =
    error?.status || error?.data?.errorCode || error?.errorCode;
  const errorFromErrorCode = getErrorFromErrorCode(errorFormat);
  if (errorFromErrorCode) {
    return errorFromErrorCode;
  } else if (error?.data?.errors?.length > 0) {
    return error.data.errors.reduce((accum, error) => {
      return `${accum}${error.field}: ${error.defaultMessage}, `;
    }, '');
  } else if (error?.data?.errors?.description) {
    return error.data.errors.description;
  } else if (error?.data?.message) {
    return error.data.message;
  } else {
    return error?.statusText || error?.toString() || 'Something went wrong';
  }
}

export { getFormattedHttpReqError };
