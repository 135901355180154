import styles from './approveDeclineFrameDesignModal.module.scss';
import { Button } from '@carbon/react/es/components/Button';
import { Edit } from '../../../../../components/icons/Edit';
import { Approve } from '../../../../../components/icons/Approve';
import { Decline } from '../../../../../components/icons/Decline';
import { useState } from 'react';
import { strings } from '../../../../../localization/Localization';

function ApproveDeclineFrameDesignModal({
  approve,
  imageSource,
  closeModal,
  onDeclineClick,
  onApproveClick,
}) {
  const [imageBackgroundColor, setImageBackgroundColor] = useState('#c2c2c2');
  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h3 className={styles.title}>
          {approve ? strings.approvalOfFinalDesign : strings.declineDesign}
        </h3>
        {imageSource && (
          <>
            <div
              className={styles.imageWrapper}
              style={{
                backgroundColor: imageBackgroundColor,
              }}
            >
              <img
                className={styles.image}
                alt="frame-design-preview"
                src={imageSource}
              />
            </div>
            <div className={styles.colorPickerContainer}>
              <input
                className={styles.colorPicker}
                type="color"
                onChange={(event) => {
                  setImageBackgroundColor(event.target.value);
                }}
                value={imageBackgroundColor}
              />
              <p className={styles.colorPickerParagraph}>
                {strings.tryWithDifferentBackgroundColors}
              </p>
            </div>
          </>
        )}
        <p className={styles.paragraph}>
          {approve
            ? strings.thisIsAPreviewOfTheFinalDesign
            : strings.areYouSureYouWantToDecline}
        </p>
        <div className={styles.buttonContainer}>
          <Button
            renderIcon={(props) => <Edit width={16} height={16} {...props} />}
            kind="secondary"
            onClick={closeModal}
          >
            {approve ? strings.amendDesign : strings.noIChangedMyMind}
          </Button>
          {approve ? (
            <Button
              renderIcon={(props) => (
                <Approve width={16} height={16} {...props} />
              )}
              onClick={() => {
                onApproveClick();
                closeModal();
              }}
            >
              {strings.approveDesign}
            </Button>
          ) : (
            <Button
              kind="danger"
              renderIcon={(props) => (
                <Decline width={16} height={16} {...props} />
              )}
              onClick={() => {
                onDeclineClick();
                closeModal();
              }}
            >
              {strings.yesDeclineDesign}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ApproveDeclineFrameDesignModal;
