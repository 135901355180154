import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { Categories } from '../../components/icons/Categories';
import { LogOut } from '../../components/icons/LogOut';
import { Button } from '@carbon/react/es/components/Button';
import { getFrames } from '../../connectivity/admin/frames/ApiAdminFrames';
import FrameList from '../../frames/frameList/FrameList';
import { ROUTES } from '../../index';
import { strings } from '../../localization/Localization';
import LoginForm from '../../login/loginForm/LoginForm';
import {
  COOKIE_ADMIN_ACCESS_TOKEN,
  COOKIES_ADMIN,
  getCookie,
} from '../../utils/cookieUtils';
import { PARAM_NEW } from './editFrame/EditFramePage';
import { Header } from '../../components/header/Header';
import { Main } from '../../components/main/Main';
import styles from './adminPage.module.scss';
import { Orders } from '../../components/icons/Orders';
import { getFrameOrders } from '../../connectivity/admin/frameOrders/apiAdminFrameOrders';

function AdminPage() {
  const [cookies, , removeCookie] = useCookies([COOKIES_ADMIN]);
  const accessToken = getCookie(cookies, COOKIE_ADMIN_ACCESS_TOKEN);
  const [frames, setFrames] = useState([]);
  const [numberOfOrders, setNumberOfOrders] = useState(0);
  const history = useHistory();
  const onFrameClick = (frame) => {
    history.push(`${ROUTES.adminFrameEditNoId}${frame.id}`);
  };
  const onCopyFrame = (frame) => {
    history.push({
      pathname: `${ROUTES.adminFrameEditNoId}${PARAM_NEW}`,
      state: { frame: frame },
    });
  };
  useEffect(() => {
    if (accessToken) {
      getFrames(accessToken)
        .then((response) => {
          setFrames(response.data.frames);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    getFrameOrders(accessToken)
      .then((response) => {
        setNumberOfOrders(
          response.data.orders.filter(
            (order) => order.orderStatus === 'PROCESSING'
          ).length
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [accessToken]);

  if (!accessToken) {
    return (
      <div className={styles.loginPage}>
        <LoginForm />
      </div>
    );
  }

  return (
    <>
      <Header>
        <nav className={styles.nav}>
          <div className={styles.leftSideButtons}>
            <Button
              href={ROUTES.categories}
              renderIcon={(props) => (
                <Categories width={16} height={16} {...props} />
              )}
              style={{ textDecoration: 'none' }}
            >
              {strings.categories}
            </Button>

            <Button
              href={ROUTES.frameOrders}
              renderIcon={(props) => (
                <Orders width={16} height={16} {...props} />
              )}
              style={{ textDecoration: 'none' }}
            >
              <span className={styles.numberOfOrdersSpan}>
                {numberOfOrders}
              </span>
              {strings.frameOrders}
            </Button>
            <Button href={ROUTES.promoCodes} style={{ textDecoration: 'none' }}>
              Promo codes
            </Button>
          </div>
          <Button
            kind="danger"
            onClick={() => {
              removeCookie(COOKIE_ADMIN_ACCESS_TOKEN);
              window.location.reload();
            }}
            renderIcon={(props) => <LogOut width={16} height={16} {...props} />}
          >
            {strings.logout}
          </Button>
        </nav>
      </Header>
      <Main>
        <h1>{strings.frames}</h1>
        <div className={styles.frames}>
          <FrameList
            onTeaserClick={onFrameClick}
            onCopyFrame={onCopyFrame}
            frames={frames}
            accessToken={accessToken}
          />
        </div>
      </Main>
    </>
  );
}

export default AdminPage;
