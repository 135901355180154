import styles from './palette.module.scss';
import React from 'react';
import classNames from 'classnames';

function Palette({
  colors,
  updateColors,
  frameLabel,
  className,
  disabled = false,
}) {
  return (
    <div className={classNames(styles.palette, className)}>
      <div className={styles.paletteRow}>
        <div className={styles.labelAndInputs}>
          <label htmlFor="primary-color-picker" className={styles.label}>
            {frameLabel === 'frameColor' ? 'Primary color' : 'Text color 1'}
          </label>
          <div className={styles.inputs}>
            <input
              className={styles.colorInput}
              value={colors.primary}
              id="primary-color-picker"
              name="primary"
              type="color"
              onChange={(event) => {
                updateColors(event.target);
              }}
              disabled={disabled}
            />
            <input
              className={styles.textInput}
              type="text"
              value={colors.primary}
              onChange={(event) => {
                updateColors(event.target);
              }}
              name="primary"
              id="primary-color-picker-text"
              disabled={disabled}
            />
          </div>
        </div>
        {colors.secondary && (
          <div className={styles.labelAndInputs}>
            <label htmlFor="primary-color-picker" className={styles.label}>
              {frameLabel === 'frameColor' ? 'Secondary color' : 'Text color 2'}
            </label>
            <div className={styles.inputs}>
              <input
                className={styles.colorInput}
                value={colors.secondary}
                id="secondary-color-picker"
                name="secondary"
                type="color"
                onChange={(event) => {
                  updateColors(event.target);
                }}
                disabled={disabled}
              />
              <input
                className={styles.textInput}
                type="text"
                value={colors.secondary}
                onChange={(event) => {
                  updateColors(event.target);
                }}
                name="secondary"
                id="secondary-color-picker-text"
                disabled={disabled}
              />
            </div>
          </div>
        )}
        {colors.tertiary && (
          <div className={styles.labelAndInputs}>
            <label htmlFor="primary-color-picker" className={styles.label}>
              {frameLabel === 'frameColor' ? 'Third color' : 'Text color 3'}
            </label>
            <div className={styles.inputs}>
              <input
                className={styles.colorInput}
                value={colors.tertiary}
                id="tertiary-color-picker"
                name="tertiary"
                type="color"
                onChange={(event) => {
                  updateColors(event.target);
                }}
                disabled={disabled}
              />
              <input
                className={styles.textInput}
                type="text"
                value={colors.tertiary}
                onChange={(event) => {
                  updateColors(event.target);
                }}
                name="tertiary"
                id="tertiary-color-picker-text"
                disabled={disabled}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Palette;
