import styles from './helpModal.module.scss';
import closeIcon from '../../../img/close-icon.svg';

function HelpModal({ onClose }) {
  return (
    <div onClick={onClose} className={styles.helpModal}>
      <div
        onClick={(event) => event.stopPropagation()}
        className={styles.modal}
      >
        <div className={styles.titleWrapper}>
          <h2 className={styles.title}>Guide</h2>
          <button
            className={styles.close}
            onClick={() => {
              onClose();
            }}
            aria-label="close-modal"
          >
            <img height={24} width={24} alt="close-icon" src={closeIcon} />
          </button>
        </div>
        <h4 className={styles.subHeading}>Choose a frame that you like</h4>
        <p className={styles.paragraph}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
          sagittis lectus consequat diam tempus varius. In accumsan, neque vitae
          auctor venenatis, tellus nisi cursus ligula, viverra tempus leo nulla
          in nisl. Mauris et eleifend massa, vel condimentum nisi. Pellentesque
          interdum tortor eget laoreet lobortis. Duis faucibus congue aliquam.
          Maecenas condimentum blandit suscipit. Nam eget mi nibh. Suspendisse
          blandit sem odio, in ultricies elit maximus lobortis. Nam ut nulla vel
          enim finibus pellentesque non non metus.
        </p>
        <h4 className={styles.subHeading}>Customize the theme</h4>
        <p className={styles.paragraph}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
          sagittis lectus consequat diam tempus varius. In accumsan, neque vitae
          auctor venenatis, tellus nisi cursus ligula, viverra tempus leo nulla
          in nisl. Mauris et eleifend massa, vel condimentum nisi. Pellentesque
          interdum tortor eget laoreet lobortis. Duis faucibus congue aliquam.
          Maecenas condimentum blandit suscipit. Nam eget mi nibh. Suspendisse
          blandit sem odio, in ultricies elit maximus lobortis. Nam ut nulla vel
          enim finibus pellentesque non non metus.
        </p>
        <h4 className={styles.subHeading}>Add information</h4>
        <p className={styles.paragraph}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
          sagittis lectus consequat diam tempus varius. In accumsan, neque vitae
          auctor venenatis, tellus nisi cursus ligula, viverra tempus leo nulla
          in nisl. Mauris et eleifend massa, vel condimentum nisi. Pellentesque
          interdum tortor eget laoreet lobortis. Duis faucibus congue aliquam.
          Maecenas condimentum blandit suscipit. Nam eget mi nibh. Suspendisse
          blandit sem odio, in ultricies elit maximus lobortis. Nam ut nulla vel
          enim finibus pellentesque non non metus.
        </p>
      </div>
    </div>
  );
}

export default HelpModal;
