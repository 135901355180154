import styles from './frameAnimation.module.scss';
import React from 'react';

function FrameAnimation() {
  return (
    <div className={styles.frameAnimation}>
      <div className={styles.innerContainer}>
        <hr className={styles.blurredLineOne} />
        <hr className={styles.blurredLineTwo} />
      </div>
    </div>
  );
}

export default FrameAnimation;
