import { useHistory } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import TextInput from '../../../components/textInput/TextInput';
import { Button } from '@carbon/react/es/components/Button';
import { Add } from '../../../components/icons/Add';
import { ROUTES } from '../../../index';
import { Main } from '../../../components/main/Main';
import { Card } from '../../../components/card/Card';
import { useCookies } from 'react-cookie';
import { COOKIES_ADMIN, getAccessToken } from '../../../utils/cookieUtils';
import styles from './createPromoCodes.module.scss';
import { BackHeader } from '../../../components/header/BackHeader';
import { createPromoCodes } from '../../../connectivity/admin/promoCodes/apiAdminPromoCodes';
import { useState } from 'react';
import { emptyAsUndefined } from '../../../utils/stringUtils';
import { downloadFile } from '../../../utils/downloadUtils';

export const CreatePromoCodesPage = () => {
  const history = useHistory();
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(undefined);
  const { control, handleSubmit, reset } = useForm();
  const [cookies] = useCookies([COOKIES_ADMIN]);
  const accessToken = getAccessToken(cookies);
  const onSubmit = (data) => {
    setLoading(true);
    createPromoCodes(accessToken, {
      multiple: emptyAsUndefined(data.multiple),
      code: data.multiple === 1 ? emptyAsUndefined(data.code) : undefined,
      customerName: emptyAsUndefined(data.customer),
    })
      .then((response) => {
        const promoCodes = response.data.promoCodes;
        downloadFile(
          `codes_${new Date().toISOString()}.txt`,
          promoCodes
            .map((promoCode, index) => {
              if (index === promoCodes.length - 1) {
                return promoCode.code;
              } else {
                return `${promoCode.code}\n`;
              }
            })
            .join('')
        );
        reset();
        history.push({
          pathname: ROUTES.promoCodes,
        });
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  const multiple = useWatch({
    control,
    defaultValue: '1', // default value before the render
    name: 'multiple', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both// default value before the render
  });
  return (
    <>
      <BackHeader href={ROUTES.promoCodes}></BackHeader>
      <Main>
        <h1>Create free promo codes</h1>
        <Card>
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <TextInput
              className={styles.textInput}
              defaultValue={1}
              name={'multiple'}
              type={'number'}
              labelText={'Number of codes'}
              control={control}
            />
            {multiple === '1' ? (
              <TextInput
                className={styles.textInput}
                defaultValue={''}
                name={'code'}
                labelText={'Code'}
                control={control}
              />
            ) : (
              <p className={styles.randomCodesText}>
                Random codes will be generated
              </p>
            )}
            <TextInput
              className={styles.textInput}
              defaultValue={''}
              name={'customer'}
              labelText={'Customer'}
              control={control}
            />
            <br />
            <p>{error}</p>

            <div className={styles.buttons}>
              <Button
                type="submit"
                renderIcon={(props) => (
                  <Add width={16} height={16} {...props} />
                )}
                disabled={loading}
              >
                Create promo codes
              </Button>
            </div>
          </form>
        </Card>
      </Main>
    </>
  );
};
