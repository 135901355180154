import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import Button from '../../components/button/Button';
import ErrorAndLoader from '../../components/errorAndLoader/ErrorAndLoader';
import TextInput from '../../components/textInput/TextInput';
import { login } from '../../connectivity/admin/login/ApiAdminLogin';
import { strings } from '../../localization/Localization';
import { useForm } from 'react-hook-form';
import {
  COOKIE_ADMIN_ACCESS_TOKEN,
  COOKIES_ADMIN,
} from '../../utils/cookieUtils';
import styles from './loginForm.module.scss';

const FORM_KEYS = {
  email: 'email',
  password: 'password',
};

function LoginForm() {
  const { control, handleSubmit } = useForm();
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(undefined);
  const [, setCookie] = useCookies([COOKIES_ADMIN]);
  const onSubmit = (data) => {
    setLoading(true);
    setError(undefined);
    login(data.email, data.password)
      .then((response) => {
        setLoading(undefined);
        setCookie(COOKIE_ADMIN_ACCESS_TOKEN, response.data.accessToken);
        window.location.reload();
      })
      .catch((error) => {
        setLoading(undefined);
        setError(error.message);
      });
  };

  return (
    <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        defaultValue="test"
        className={styles.input}
        control={control}
        name={FORM_KEYS.email}
        labelText={strings.email}
      />
      <TextInput
        control={control}
        className={styles.input}
        name={FORM_KEYS.password}
        type="password"
        labelText={strings.password}
      />
      <ErrorAndLoader loading={loading} error={error} />
      <Button type="submit">{strings.login}</Button>
    </form>
  );
}

export default LoginForm;
