import styles from './frameOrders.module.scss';
import Button from '@carbon/react/es/components/Button';
import { Header } from '../../../components/header/Header';
import { strings } from '../../../localization/Localization';
import { ROUTES } from '../../../index';
import { ArrowLeft } from '../../../components/icons/ArrowLeft';
import { Main } from '../../../components/main/Main';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { COOKIES_ADMIN, getAccessToken } from '../../../utils/cookieUtils';
import { getFrameOrders } from '../../../connectivity/admin/frameOrders/apiAdminFrameOrders';
import FrameOrderCard from './frameOrderCard/FrameOrderCard';
import LoginForm from '../../../login/loginForm/LoginForm';

function FrameOrders() {
  const [cookies] = useCookies([COOKIES_ADMIN]);
  const accessToken = getAccessToken(cookies);

  const [frameOrders, setFrameOrders] = useState([]);

  useEffect(() => {
    getFrameOrders(accessToken)
      .then((response) => {
        setFrameOrders(response.data.orders);
      })
      .catch((error) => {
        console.error(error);
        alert('Could not fetch frame orders');
      });
  }, [accessToken]);

  const ordersUnderProcess = frameOrders
    .filter((frameOrder) => frameOrder.orderStatus === 'PROCESSING')
    .sort((a, b) => parseFloat(a.daysLeft) - parseFloat(b.daysLeft));

  const approvedOrders = frameOrders.filter(
    (frameOrder) => frameOrder.orderStatus === 'APPROVED'
  );

  const declinedOrders = frameOrders.filter(
    (frameOrder) => frameOrder.orderStatus === 'DENIED'
  );

  if (!accessToken) {
    return (
      <div className={styles.loginPage}>
        <LoginForm />
      </div>
    );
  }

  return (
    <div>
      <Header>
        <nav>
          <Button
            hasIconOnly
            iconDescription={strings.back}
            style={{ textDecoration: 'none' }}
            href={ROUTES.admin}
            tooltipPosition="bottom"
          >
            <ArrowLeft fill="white" width="16" height="16" />
          </Button>
        </nav>
      </Header>
      <Main>
        <h1>{strings.frameOrders}</h1>
        {ordersUnderProcess.map((frameOrder) => (
          <FrameOrderCard key={frameOrder.id} frameOrder={frameOrder} />
        ))}
        {!!approvedOrders.length && (
          <>
            <h2 className={styles.h2}>{strings.approved}</h2>
            {approvedOrders.map((frameOrder) => (
              <FrameOrderCard key={frameOrder.id} frameOrder={frameOrder} />
            ))}
          </>
        )}
        {!!declinedOrders.length && (
          <>
            <h2 className={styles.h2}>{strings.declined}</h2>
            {declinedOrders.map((frameOrder) => (
              <FrameOrderCard key={frameOrder.id} frameOrder={frameOrder} />
            ))}
          </>
        )}
      </Main>
    </div>
  );
}

export default FrameOrders;
