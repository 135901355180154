import styles from './priceBanner.module.scss';

function PriceBanner({ product, currency }) {
  const foundProduct = product.priceList.find(
    (price) => price.currency === currency
  );

  return (
    <div className={styles.priceBanner}>
      <p className={styles.paragraph}>
        {foundProduct.amount} {foundProduct.currency}
      </p>
    </div>
  );
}

export default PriceBanner;
