import styles from './backgroundSVG.module.scss';

function BackgroundSVG({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 348 435"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="348" height="435" rx="16" fill="black" />
      <g opacity="0.1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M161.97 53.2742L80.7704 278.185L128.738 206.029L161.97 53.2742Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M178.749 13.7483L149.511 194.392L197.2 226.415L178.749 13.7483Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215.477 238.215L267.23 273.889L197.63 82.489L215.477 238.215Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M202.171 381.296L84.2074 303.068L144.111 213.096L262.074 291.325L202.171 381.296Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

export default BackgroundSVG;
