export default class HttpApiCallError extends Error {
  constructor(message, status, data, errorCode) {
    super(message);
    this.status = status;
    this.data = data;
    this.errorCode = errorCode;
    this.stack = new Error(message).stack;
    this.name = 'HttpApiCallError';
  }
}
