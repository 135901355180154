import { getBaseRequestConfig, API_URL } from '../../baseRequestConfig';
import httpRequest from '../../httpRequest';

const BASE_PATH = `${API_URL}/admin-api/v1/images`;

export function uploadFrameImage(accessToken, file) {
  const formData = new FormData();
  formData.append('image', file, file.name);
  const baseRequestConfig = getBaseRequestConfig(accessToken);
  const url = `${BASE_PATH}`;

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: url,
    method: 'POST',
    data: formData,
  });

  return httpRequest(requestConfig);
}

export function uploadDefaultImage(accessToken, file) {
  const formData = new FormData();
  formData.append('image', file, file.name);
  const baseRequestConfig = getBaseRequestConfig(accessToken);
  const url = `${BASE_PATH}/default`;

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: url,
    method: 'POST',
    data: formData,
  });

  return httpRequest(requestConfig);
}
