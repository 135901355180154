export const FONT_WEIGHT = {
  EXTRA_LIGHT: 'ExtraLight',
  EXTRA_BLACK: 'ExtraBlack',
  REGULAR: 'Regular',
  SEMI_BOLD: 'SemiBold',
  BLACK: 'Black',
  BOLD: 'Bold',
  MEDIUM: 'Medium',
};

export const FONTS = {
  TITILLIUM: {
    family: 'TitilliumWeb',
    weights: [
      FONT_WEIGHT.BLACK,
      FONT_WEIGHT.BOLD,
      FONT_WEIGHT.EXTRA_LIGHT,
      FONT_WEIGHT.REGULAR,
      FONT_WEIGHT.SEMI_BOLD,
    ],
  },
  TEKO: {
    family: 'Teko',
    weights: [FONT_WEIGHT.BOLD, FONT_WEIGHT.MEDIUM, FONT_WEIGHT.SEMI_BOLD],
  },
  SHARP_GROTESK: {
    family: 'SharpGrotesk',
    weights: [FONT_WEIGHT.BOLD],
  },
  MONTSERRAT: {
    family: 'Montserrat',
    weights: [FONT_WEIGHT.SEMI_BOLD],
  },
  PROXIMA_NOVA: {
    family: 'ProximaNova',
    weights: [FONT_WEIGHT.SEMI_BOLD],
  },
};

export function generateFontFamily(family, weight) {
  return family + (weight ? weight : '');
}

export function getFontWeightsForFamily(family) {
  if (!family) {
    return [];
  }
  return (
    Object.values(FONTS).find((font) => font.family === family)?.weights ?? []
  );
}
