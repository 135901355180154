import { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  getCategory,
  putCategory,
  postCategory,
  CATEGORY_TYPES,
} from '../../../connectivity/admin/ApiAdminCategories';
import { useForm } from 'react-hook-form';
import { strings } from '../../../localization/Localization';
import TextInput from '../../../components/textInput/TextInput';
import TextArea from '../../../components/textArea/TextArea';
import { DeleteCategoryButton } from '../../../components/deleteCategoryButton/DeleteCategoryButton';
import { Button } from '@carbon/react/es/components/Button';
import { Add } from '../../../components/icons/Add';
import { ArrowLeft } from '../../../components/icons/ArrowLeft';
import { Save } from '../../../components/icons/Save';
import { ROUTES } from '../../../index';
import { getLocalization } from '../../../connectivity/admin/ApiLocalization';
import { Header } from '../../../components/header/Header';
import { Main } from '../../../components/main/Main';
import { Card, CardHeader } from '../../../components/card/Card';
import { useCookies } from 'react-cookie';
import { COOKIES_ADMIN, getAccessToken } from '../../../utils/cookieUtils';
import styles from './category.module.scss';
import { BackHeader } from '../../../components/header/BackHeader';

export const Category = () => {
  const { categoryId } = useParams();
  const history = useHistory();
  const [category, setCategory] = useState();
  const [languages, setLanguages] = useState();
  const [status, setStatus] = useState();
  const { control, handleSubmit, formState, reset } = useForm();
  const [cookies] = useCookies([COOKIES_ADMIN]);
  const accessToken = getAccessToken(cookies);

  const handleStatus = useCallback((status) => {
    setStatus(status);
    setTimeout(() => {
      setStatus(null);
    }, 8000);
  }, []);

  useEffect(() => {
    getLocalization()
      .then((response) => {
        setLanguages(response.data.languages);
      })
      .catch((error) => console.log(error));

    if (categoryId) {
      getCategory(categoryId)
        .then((response) => {
          setCategory(response.data.category);
        })
        .catch((error) => console.log(error));
    }
  }, [setCategory, categoryId]);

  return (
    <>
      <BackHeader href={ROUTES.categories} />
      <Main>
        <h1>{categoryId ? strings.editCategory : strings.createCategory}</h1>
        <Card>
          <CardHeader>
            <h2>
              {
                strings.categoryTypes[
                  CATEGORY_TYPES[
                    category?.type ??
                      new URLSearchParams(window.location.search).get('type')
                  ]
                ]
              }
            </h2>
            {categoryId && <DeleteCategoryButton categoryId={categoryId} />}
          </CardHeader>
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(
                data,
                category,
                languages,
                handleStatus,
                accessToken,
                history,
                reset
              )
            )}
          >
            <div className={styles.fieldsets}>
              {languages?.map((language) => {
                const name = category?.name?.find(
                  (name) => name.languageKey === language
                );

                return (
                  <fieldset
                    className={styles.fieldset}
                    key={language}
                    name={language}
                  >
                    <legend className={styles.legend}>
                      {strings.languages[language]}
                    </legend>
                    {categoryId ? (
                      category && (
                        <>
                          <TextInput
                            defaultValue={name?.value}
                            name={`${language}-name`}
                            labelText={strings.categoryName}
                            placeholder={strings.categoryNamePlaceholder}
                            control={control}
                          />
                          <TextArea
                            defaultValue={name?.synonyms?.join(', ')}
                            name={`${language}-synonyms`}
                            labelText={strings.categoryNameSynonyms}
                            placeholder={
                              strings.categoryNameSynonymsPlaceholder
                            }
                            control={control}
                          />
                        </>
                      )
                    ) : (
                      <>
                        <TextInput
                          name={`${language}-name`}
                          labelText={strings.categoryName}
                          placeholder={strings.categoryNamePlaceholder}
                          control={control}
                        />
                        <TextArea
                          name={`${language}-synonyms`}
                          labelText={strings.categoryNameSynonyms}
                          placeholder={strings.categoryNameSynonymsPlaceholder}
                          control={control}
                        />
                      </>
                    )}
                  </fieldset>
                );
              })}
            </div>
            <div className={styles.buttons}>
              <Button
                type="submit"
                renderIcon={(props) =>
                  categoryId ? (
                    <Save width={16} height={16} {...props} />
                  ) : (
                    <Add width={16} height={16} {...props} />
                  )
                }
                disabled={!formState.isDirty}
              >
                {categoryId ? strings.saveChanges : strings.createCategory}
              </Button>
              {status?.message && (
                <p
                  className={`${styles.statusMessage} ${
                    status.type === 'error'
                      ? styles.statusErrorMessage
                      : styles.statusSuccessMessage
                  }`}
                >
                  {status.message}
                </p>
              )}
            </div>
          </form>
        </Card>
      </Main>
    </>
  );
};

const onSubmit = (
  data,
  category,
  languages,
  handleStatus,
  accessToken,
  history,
  reset
) => {
  let endpoint;
  const name = languages.map((language) => {
    const dataByLanguageArray = Object.entries(data).filter(
      (entry) => entry[0].split('-')[0] === language
    );

    return {
      key: language,
      value: dataByLanguageArray[0][1],
      synonyms: dataByLanguageArray[1][1]
        ? dataByLanguageArray[1][1].split(',').map((synonym) => synonym.trim())
        : [],
    };
  });
  const type =
    category?.type ?? new URLSearchParams(window.location.search).get('type');

  if (category?.id) {
    endpoint = putCategory(accessToken, category.id, {
      name,
      type,
    });
  } else {
    endpoint = postCategory(accessToken, {
      name,
      type,
    });
  }

  endpoint
    .then((response) => {
      reset();
      handleStatus({
        message: `${
          category?.id
            ? strings.success.editCategory
            : strings.success.createCategory
        }`,
        type: 'success',
      });
      history?.push({
        pathname: `${ROUTES.category}/${response.data.category.id}`,
      });
    })
    .catch((error) => {
      handleStatus({
        message: category?.id
          ? strings.error.editCategory
          : strings.error.createCategory,
        type: 'error',
      });
      console.error(error);
    });
};
