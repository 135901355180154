import styles from './cardCreatorHeader.module.scss';
import strikeLogo from '../../../img/strike-logo.svg';
import helpIcon from '../../../img/help-circle.svg';
import { ROUTES } from '../../../index';

function CardCreatorHeader({ onHelpClick }) {
  return (
    <header className={styles.cardCreatorHeader}>
      <nav className={styles.innerWrapper}>
        <a href={'https://www.striketradingcards.com/'}>
          <img
            height={40}
            width={70}
            src={strikeLogo}
            className={styles.headerLogo}
            alt="strike-logo"
          />
        </a>
        <div className={styles.titleContainer}>
          <a className={styles.titleLink} href={ROUTES.frameCreator}>
            <h1 className={styles.title}>SPORTS CARD CREATOR</h1>
          </a>
        </div>
        {/*<button
          className={styles.button}
          onClick={onHelpClick}
          aria-label="help"
        >
          <img height={24} width={24} alt="help-icon" src={helpIcon} />
        </button>*/}
      </nav>
    </header>
  );
}

export default CardCreatorHeader;
