import { getBaseRequestConfig, API_URL } from '../../baseRequestConfig';
import httpRequest from '../../httpRequest';

const BASE_PATH = `${API_URL}/public-api/v1/admin/login`;

export function login(email, password) {
  const baseRequestConfig = getBaseRequestConfig();
  const url = BASE_PATH;

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: url,
    method: 'POST',
    data: {
      email,
      password,
    },
  });

  return httpRequest(requestConfig);
}
