import { addAlphaChannelToHexCode } from '../../../../utils/frameCreatorUtils';

export const frameThreeTextComponents = (seasonInformation, colorScheme) => {
  return [
    {
      type: 'text',
      content: {
        key: 'lastName',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'LAST NAME',
          },
        ],
      },
      font: {
        size: 0.0792593,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0250467,
        family: 'Teko',
        weight: 'Medium',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.037037,
        },
        y: {
          gravity: 'middle',
          offset: 0.340589,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'playerNumber',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: '#',
          },
        ],
      },
      font: {
        size: 0.0666667,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0245556,
        family: 'Teko',
        weight: 'Medium',
        textAllCaps: true,
      },
      privateData: false,
      position: {
        x: {
          gravity: 'start',
          offset: 0.914815,
        },
        y: {
          gravity: 'middle',
          offset: 0.340589,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'firstName',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'FIRST NAME',
          },
        ],
      },
      font: {
        size: 0.037037,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.13,
        family: 'Teko',
        weight: 'Medium',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.037037,
        },
        y: {
          gravity: 'middle',
          offset: 0.408041,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'team',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'TEAM',
          },
        ],
      },
      font: {
        size: 0.037037,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.13,
        family: 'Teko',
        weight: 'Medium',
        textAllCaps: true,
      },
      privateData: false,
      position: {
        x: {
          gravity: 'start',
          offset: 0.605,
        },
        y: {
          gravity: 'middle',
          offset: 0.408041,
        },
        rotation: null,
      },
    },
    {
      content: {
        cardHint: [
          {
            key: 'eng',
            value: seasonInformation,
          },
        ],
        key: 'seasonInformation',
      },
      font: {
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        family: 'Teko',
        highlightedColor: '#FF42c1c9',
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        letterSpacing: 0.15,
        size: 0.036,
        textAllCaps: true,
        weight: 'SemiBold',
      },
      position: {
        rotation: null,
        x: {
          gravity: 'end',
          offset: 0.025,
        },
        y: {
          gravity: 'middle',
          offset: 0.408041,
        },
      },
      privateData: true,
      type: 'hardcoded_text',
    },
  ];
};
