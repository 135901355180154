import { useRef } from 'react';
import Button from '../button/Button';

function ImageUploader({ onImageSelected, className, text }) {
  const fileInput = useRef(null);
  const onFileChange = (event) => {
    onImageSelected(event.target.files[0]);
  };
  return (
    <div className={className}>
      <input
        type="file"
        ref={fileInput}
        onChange={onFileChange}
        style={{ display: 'none' }}
      />
      <Button onClick={() => fileInput.current.click()}>{text}</Button>
    </div>
  );
}

export default ImageUploader;
