import { addAlphaChannelToHexCode } from '../../../../utils/frameCreatorUtils';

export const frameElevenTextComponents = (seasonInformation, colorScheme) => {
  return [
    {
      type: 'text',
      content: {
        key: 'lastName',
        cardHint: [
          {
            key: 'sv',
            iosLanguageKey: 'sv',
            value: 'EFTERNAMN',
          },
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'LAST NAME',
          },
        ],
      },
      font: {
        size: 0.0681482,
        color: addAlphaChannelToHexCode(colorScheme.textColors.tertiary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.tertiary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0217391,
        family: 'TitilliumWeb',
        weight: 'Bold',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.27,
        },
        y: {
          gravity: 'middle',
          offset: 0.225,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'firstName',
        cardHint: [
          {
            key: 'sv',
            iosLanguageKey: 'sv',
            value: 'FÖRNAMN',
          },
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'FIRST NAME',
          },
        ],
      },
      font: {
        size: 0.0392593,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.002,
        family: 'TitilliumWeb',
        weight: 'Bold',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.281481,
        },
        y: {
          gravity: 'middle',
          offset: 0.308519,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'team',
        cardHint: [
          {
            key: 'sv',
            iosLanguageKey: 'sv',
            value: 'LAG',
          },
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'TEAM',
          },
        ],
      },
      font: {
        size: 0.0311111,
        color: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.002,
        family: 'TitilliumWeb',
        weight: 'Bold',
        textAllCaps: true,
      },
      privateData: false,
      position: {
        x: {
          gravity: 'start',
          offset: 0.281481,
        },
        y: {
          gravity: 'middle',
          offset: 0.402963,
        },
        rotation: null,
      },
    },
    {
      content: {
        cardHint: [
          {
            key: 'eng',
            value: seasonInformation,
          },
        ],
        key: 'seasonInformation',
      },
      font: {
        color: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        family: 'TitilliumWeb',
        highlightedColor: '#FF42c1c9',
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        letterSpacing: 0.02,
        size: 0.0311111,
        textAllCaps: true,
        weight: 'Bold',
      },
      position: {
        rotation: null,
        x: {
          gravity: 'middle',
          offset: 0.2,
        },
        y: {
          gravity: 'middle',
          offset: 0.402963,
        },
      },
      privateData: true,
      type: 'hardcoded_text',
    },
  ];
};
