import Resizer from 'react-image-file-resizer';
import { framesList } from '../pages/frameCreator/framesList/framesList';
import FrameOne from '../pages/frameCreator/frameComponents/frameOne/FrameOne';
import FrameTwo from '../pages/frameCreator/frameComponents/frameTwo/FrameTwo';
import FrameThree from '../pages/frameCreator/frameComponents/frameThree/FrameThree';
import FrameFour from '../pages/frameCreator/frameComponents/frameFour/FrameFour';
import FrameFive from '../pages/frameCreator/frameComponents/frameFive/FrameFive';
import FrameSix from '../pages/frameCreator/frameComponents/frameSix/FrameSix';
import FrameSeven from '../pages/frameCreator/frameComponents/frameSeven/FrameSeven';
import FrameEight from '../pages/frameCreator/frameComponents/frameEight/FrameEight';
import FrameNine from '../pages/frameCreator/frameComponents/frameNine/FrameNine';
import FrameTen from '../pages/frameCreator/frameComponents/frameTen/FrameTen';
import FrameEleven from '../pages/frameCreator/frameComponents/frameEleven/FrameEleven';
import FrameTwelve from '../pages/frameCreator/frameComponents/frameTwelve/FrameTwelve';
import { frameOneTextComponents } from '../pages/frameCreator/frameComponents/frameOne/frameOneTextComponents';
import { frameTwoTextComponents } from '../pages/frameCreator/frameComponents/frameTwo/frameTwoTextComponents';
import { frameThreeTextComponents } from '../pages/frameCreator/frameComponents/frameThree/frameThreeTextComponents';
import { frameFourTextComponents } from '../pages/frameCreator/frameComponents/frameFour/frameFourTextComponents';
import { frameFiveTextComponents } from '../pages/frameCreator/frameComponents/frameFive/frameFiveTextComponents';
import { frameSixTextComponents } from '../pages/frameCreator/frameComponents/frameSix/frameSixTextComponents';
import { frameSevenTextComponents } from '../pages/frameCreator/frameComponents/frameSeven/frameSevenTextComponents';
import { frameEightTextComponents } from '../pages/frameCreator/frameComponents/frameEight/frameEightTextComponents';
import { frameNineTextComponents } from '../pages/frameCreator/frameComponents/frameNine/frameNineTextComponents';
import { frameTenTextComponents } from '../pages/frameCreator/frameComponents/frameTen/frameTenTextComponents';
import { frameElevenTextComponents } from '../pages/frameCreator/frameComponents/frameEleven/frameElevenTextComponents';
import { frameTwelveTextComponents } from '../pages/frameCreator/frameComponents/frameTwelve/frameTwelveTextComponents';

export const resizeFile = (file, maxWidth, maxHeight) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'PNG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });

export const returnLogoPlacement = (frameId) => {
  return framesList.find((frame) => frame.id === frameId)
    .logoPlacementInPercent;
};

export const getLogoSizeRequirements = (frameId) => {
  return framesList.find((frame) => frame.id === frameId)?.logoSizeInPx;
};

export const FrameById = ({ frameId, frameProps }) => {
  return frameId === 1 ? (
    <FrameOne {...frameProps} />
  ) : frameId === 2 ? (
    <FrameTwo {...frameProps} />
  ) : frameId === 3 ? (
    <FrameThree {...frameProps} />
  ) : frameId === 4 ? (
    <FrameFour {...frameProps} />
  ) : frameId === 5 ? (
    <FrameFive {...frameProps} />
  ) : frameId === 6 ? (
    <FrameSix {...frameProps} />
  ) : frameId === 7 ? (
    <FrameSeven {...frameProps} />
  ) : frameId === 8 ? (
    <FrameEight {...frameProps} />
  ) : frameId === 9 ? (
    <FrameNine {...frameProps} />
  ) : frameId === 10 ? (
    <FrameTen {...frameProps} />
  ) : frameId === 11 ? (
    <FrameEleven {...frameProps} />
  ) : frameId === 12 ? (
    <FrameTwelve {...frameProps} />
  ) : (
    <></>
  );
};

export const getCurrency = (country) => {
  const sweden = 'SE';
  const remainingEUCountries = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'GB',
  ];
  if (country === sweden) {
    return 'sek';
  } else if (remainingEUCountries.includes(country)) {
    return 'eur';
  } else {
    return 'usd';
  }
};

export const addAlphaChannelToHexCode = (code) => {
  const hexCode = code.replace('#', '');
  return `#FF${hexCode}`;
};

export const getTextComponents = (frameId, colorScheme, seasonInformation) => {
  if (frameId === 1) {
    return frameOneTextComponents(seasonInformation, colorScheme);
  }
  if (frameId === 2) {
    return frameTwoTextComponents(seasonInformation, colorScheme);
  }
  if (frameId === 3) {
    return frameThreeTextComponents(seasonInformation, colorScheme);
  }
  if (frameId === 4) {
    return frameFourTextComponents(seasonInformation, colorScheme);
  }
  if (frameId === 5) {
    return frameFiveTextComponents(seasonInformation, colorScheme);
  }
  if (frameId === 6) {
    return frameSixTextComponents(seasonInformation, colorScheme);
  }
  if (frameId === 7) {
    return frameSevenTextComponents(seasonInformation, colorScheme);
  }
  if (frameId === 8) {
    return frameEightTextComponents(seasonInformation, colorScheme);
  }
  if (frameId === 9) {
    return frameNineTextComponents(seasonInformation, colorScheme);
  }
  if (frameId === 10) {
    return frameTenTextComponents(seasonInformation, colorScheme);
  }
  if (frameId === 11) {
    return frameElevenTextComponents(seasonInformation, colorScheme);
  }
  if (frameId === 12) {
    return frameTwelveTextComponents(seasonInformation, colorScheme);
  }
};
