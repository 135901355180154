import { getBaseRequestConfig, API_URL } from '../../baseRequestConfig';
import httpRequest from '../../httpRequest';

const BASE_PATH = `${API_URL}/admin-api/v1/frames`;

export function getFrames(accessToken) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);
  const url = BASE_PATH;

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: url,
    method: 'GET',
  });

  return httpRequest(requestConfig);
}

export function getFrame(accessToken, frameId) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);
  const url = `${BASE_PATH}/${frameId}`;

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: url,
    method: 'GET',
  });

  return httpRequest(requestConfig);
}

export function updateFrame(accessToken, frameId, data) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);
  const url = `${BASE_PATH}/${frameId}`;

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: url,
    method: 'PUT',
    data,
  });

  return httpRequest(requestConfig);
}

export function createFrame(accessToken, data) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);
  const url = `${BASE_PATH}`;

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: url,
    method: 'POST',
    data,
  });

  return httpRequest(requestConfig);
}

export function updateFrameStatus(accessToken, frameId, status) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);
  const url = `${BASE_PATH}/${frameId}/status?status=${status}`;

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: url,
    method: 'PUT',
  });

  return httpRequest(requestConfig);
}
