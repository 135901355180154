import { addAlphaChannelToHexCode } from '../../../../utils/frameCreatorUtils';

export const frameSixTextComponents = (seasonInformation, colorScheme) => {
  return [
    {
      type: 'text',
      content: {
        key: 'lastName',
        cardHint: [
          {
            key: 'sv',
            iosLanguageKey: 'sv',
            value: 'EFTERNAMN',
          },
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'LAST NAME',
          },
        ],
      },
      font: {
        size: 0.09,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: null,
        family: 'Teko',
        weight: 'SemiBold',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.0257,
        },
        y: {
          gravity: 'start',
          offset: 0.7,
        },
        rotation: {
          anchorPoint: 'start',
          value: 270,
        },
      },
    },
    {
      type: 'text',
      content: {
        key: 'firstName',
        cardHint: [
          {
            key: 'sv',
            iosLanguageKey: 'sv',
            value: 'FÖRNAMN',
          },
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'FIRST NAME',
          },
        ],
      },
      font: {
        size: 0.05,
        color: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.002,
        family: 'Teko',
        weight: 'SemiBold',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.1205,
        },
        y: {
          gravity: 'start',
          offset: 0.7,
        },
        rotation: {
          anchorPoint: 'start',
          value: 270,
        },
      },
    },
    {
      type: 'text',
      content: {
        key: 'playerNumber',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: '#',
          },
          {
            key: 'sv',
            iosLanguageKey: 'sv',
            value: '#',
          },
        ],
      },
      font: {
        size: 0.09,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0246,
        family: 'Teko',
        weight: 'SemiBold',
        textAllCaps: true,
      },
      privateData: false,
      position: {
        x: {
          gravity: 'start',
          offset: 0.0257,
        },
        y: {
          gravity: 'start',
          offset: 0.809,
        },
        rotation: {
          anchorPoint: 'start',
          value: 270,
        },
      },
    },
    {
      type: 'text',
      content: {
        key: 'team',
        cardHint: [
          {
            key: 'sv',
            iosLanguageKey: 'sv',
            value: 'LAG',
          },
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'TEAM',
          },
        ],
      },
      font: {
        size: 0.037037,
        color: addAlphaChannelToHexCode(colorScheme.textColors.tertiary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.tertiary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0246,
        family: 'Teko',
        weight: 'SemiBold',
        textAllCaps: true,
      },
      privateData: false,
      position: {
        x: {
          gravity: 'middle',
          offset: 0.05,
        },
        y: {
          gravity: 'middle',
          offset: 0.4164,
        },
        rotation: null,
      },
    },
    {
      content: {
        cardHint: [
          {
            key: 'eng',
            value: seasonInformation,
          },
        ],
        key: 'seasonInformation',
      },
      font: {
        color: addAlphaChannelToHexCode(colorScheme.textColors.tertiary),
        family: 'Teko',
        highlightedColor: '#FF42c1c9',
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.tertiary),
        letterSpacing: 0.02,
        size: 0.037037,
        textAllCaps: true,
        weight: 'SemiBold',
      },
      position: {
        rotation: null,
        x: {
          gravity: 'middle',
          offset: 0.33,
        },
        y: {
          gravity: 'middle',
          offset: 0.4164,
        },
      },
      privateData: true,
      type: 'hardcoded_text',
    },
  ];
};
