import axios from 'axios';
import { getFormattedHttpReqError } from '../utils/connectivityUtils';
import HttpApiCallError from './HttpApiCallError';

export default function httpRequest(requestConfig) {
  return axios(requestConfig).then(
    (response) => {
      return response;
    },
    (responseError) => {
      const error = responseError?.response;
      const message = getFormattedHttpReqError(error);
      throw new HttpApiCallError(
        message,
        error?.status,
        error?.data,
        error?.data?.errorCode
      );
    }
  );
}
