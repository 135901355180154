import { addAlphaChannelToHexCode } from '../../../../utils/frameCreatorUtils';

export const frameNineTextComponents = (seasonInformation, colorScheme) => {
  return [
    {
      type: 'text',
      content: {
        key: 'lastName',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'LAST NAME',
          },
        ],
      },
      font: {
        size: 0.0592593,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.02175,
        family: 'Teko',
        weight: 'SemiBold',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.565741,
        },
        y: {
          gravity: 'middle',
          offset: 0.34525,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'firstName',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'FIRST NAME',
          },
        ],
      },
      font: {
        size: 0.0414815,
        color: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0217857,
        family: 'Teko',
        weight: 'SemiBold',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.565741,
        },
        y: {
          gravity: 'middle',
          offset: 0.39,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'team',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'TEAM',
          },
        ],
      },
      font: {
        size: 0.037037,
        color: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0218,
        family: 'Teko',
        weight: 'SemiBold',
        textAllCaps: true,
      },
      privateData: false,
      position: {
        x: {
          gravity: 'end',
          offset: 0.34,
        },
        y: {
          gravity: 'middle',
          offset: 0.43,
        },
        rotation: null,
      },
    },
    {
      content: {
        cardHint: [
          {
            key: 'eng',
            value: seasonInformation,
          },
        ],
        key: 'seasonInformation',
      },
      font: {
        color: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        family: 'Teko',
        highlightedColor: '#FF42c1c9',
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        letterSpacing: 0.0218,
        size: 0.036,
        textAllCaps: true,
        weight: 'SemiBold',
      },
      position: {
        rotation: null,
        x: {
          gravity: 'end',
          offset: 0.07,
        },
        y: {
          gravity: 'middle',
          offset: 0.43,
        },
      },
      privateData: true,
      type: 'hardcoded_text',
    },
  ];
};
