import { API_URL, getBaseRequestConfig } from '../baseRequestConfig';
import httpRequest from '../httpRequest';

export function postLogo(logoUrl) {
  const baseRequestConfig = getBaseRequestConfig(
    undefined,
    'multipart/form-data'
  );
  const formData = new FormData();
  formData.append('image', logoUrl);

  const requestConfig = {
    ...baseRequestConfig,
    url: `${API_URL}/public-api/v1/files/logo`,
    method: 'POST',
    data: formData,
  };

  return httpRequest(requestConfig);
}
