import React from 'react';
import FrameTeaser from '../frameTeaser/FrameTeaser';

import styles from './frameList.module.scss';

function FrameList({ frames, onTeaserClick, onCopyFrame, accessToken }) {
  return (
    <div className={styles.frameList}>
      {frames.map((frame, index) => {
        return (
          <FrameTeaser
            className={styles.frame}
            key={index}
            frame={frame}
            onClick={onTeaserClick}
            onCopyFrame={onCopyFrame}
            accessToken={accessToken}
          />
        );
      })}
    </div>
  );
}

export default FrameList;
