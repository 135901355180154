import styles from '../editFrameFields.module.scss';
import { strings } from '../../../localization/Localization';
import Button from '../../../components/button/Button';
import {
  SelectInput,
  SelectInputItem,
} from '../../../components/selectInput/SelectInput';
import {
  COMPONENT_CONTENT_KEY,
  COMPONENT_TYPE,
  POSITION_GRAVITY,
  POSITION_ROTATION_ANCHOR,
} from '../../frameUtils';
import TextInput from '../../../components/textInput/TextInput';
import { getTranslationValue } from '../../../localization/localtizationUtils';
import { FONTS, getFontWeightsForFamily } from '../../../fonts/FontUtils';
import React, { useState } from 'react';
import { FORM_KEYS_COMPONENT } from '../EditFrameFields';

function TextComponent({
  textComponent,
  allTextComponents,
  onRemoveComponent,
  control,
  lastComponentRef,
  watch,
  index,
  getComponentFormName,
}) {
  const [component, setComponent] = useState(textComponent);
  const componentName =
    watch(
      getComponentFormName(index, FORM_KEYS_COMPONENT.contentKey),
      undefined
    ) ?? component.content.key;
  const key = `${componentName}-${index}`;
  if (component.removed) {
    return <div key={key} />;
  }
  return (
    <div
      ref={
        index === allTextComponents.length - 1 ? lastComponentRef : undefined
      }
      className={styles.component}
      key={key}
    >
      <h5 className={styles.stickyHeading}>
        <button
          onClick={() => {
            setComponent({ ...component, collapsed: !component.collapsed });
          }}
          className={styles.collapseButton}
        >{`${strings.component} - ${componentName}`}</button>
      </h5>

      <div className={component.collapsed ? styles.collapsed : null}>
        <Button
          size="sm"
          kind="danger"
          onClick={() => onRemoveComponent(index)}
        >
          {strings.removeComponent}
        </Button>
        <SelectInput
          defaultValue={component.type}
          control={control}
          className={styles.input}
          name={getComponentFormName(index, FORM_KEYS_COMPONENT.type)}
          labelText={strings.type}
        >
          {Object.values(COMPONENT_TYPE).map((type, index) => {
            return <SelectInputItem key={index} text={type} value={type} />;
          })}
        </SelectInput>
        <SelectInput
          defaultValue={component.content.key}
          control={control}
          className={styles.input}
          name={getComponentFormName(index, FORM_KEYS_COMPONENT.contentKey)}
          labelText={strings.contentKey}
        >
          {Object.values(COMPONENT_CONTENT_KEY).map((contentKey, index) => {
            return (
              <SelectInputItem
                key={index}
                text={contentKey.key}
                value={contentKey.key}
              />
            );
          })}
        </SelectInput>
        <TextInput
          defaultValue={getTranslationValue(component.content.cardHint)}
          control={control}
          className={styles.input}
          name={getComponentFormName(index, FORM_KEYS_COMPONENT.cardHint)}
          labelText={strings.hintText}
        />
        <TextInput
          defaultValue={component.font.size}
          control={control}
          className={styles.input}
          type="number"
          name={getComponentFormName(index, FORM_KEYS_COMPONENT.fontSize)}
          labelText={strings.fontSize}
        />
        <TextInput
          defaultValue={component.font.color}
          control={control}
          className={styles.input}
          name={getComponentFormName(index, FORM_KEYS_COMPONENT.fontColor)}
          labelText={strings.fontColor}
        />
        <TextInput
          defaultValue={component.font.hintColor}
          control={control}
          className={styles.input}
          name={getComponentFormName(index, FORM_KEYS_COMPONENT.fontHintColor)}
          labelText={strings.hintColor}
        />
        <TextInput
          defaultValue={component.font.highlightedColor}
          control={control}
          className={styles.input}
          name={getComponentFormName(
            index,
            FORM_KEYS_COMPONENT.fontHighlightedColor
          )}
          labelText={strings.highlightedColor}
        />
        <TextInput
          defaultValue={component.font.letterSpacing}
          control={control}
          className={styles.input}
          type="number"
          name={getComponentFormName(
            index,
            FORM_KEYS_COMPONENT.fontLetterSpacing
          )}
          labelText={strings.letterSpacing}
        />
        <SelectInput
          defaultValue={component.font.family}
          control={control}
          className={styles.input}
          name={getComponentFormName(index, FORM_KEYS_COMPONENT.fontFamily)}
          labelText={strings.fontFamily}
        >
          {Object.values(FONTS).map((font, index) => {
            return (
              <SelectInputItem
                key={index}
                text={font.family}
                value={font.family}
              />
            );
          })}
        </SelectInput>
        <SelectInput
          defaultValue={component.font.weight}
          control={control}
          className={styles.input}
          name={getComponentFormName(index, FORM_KEYS_COMPONENT.fontWeight)}
          labelText={strings.fontWeight}
        >
          {getFontWeightsForFamily(
            watch(
              getComponentFormName(index, FORM_KEYS_COMPONENT.fontFamily),
              undefined
            )
          ).map((weight, index) => {
            return <SelectInputItem key={index} text={weight} value={weight} />;
          })}
        </SelectInput>
        <SelectInput
          defaultValue={component.font.textAllCaps}
          control={control}
          className={styles.input}
          name={getComponentFormName(index, FORM_KEYS_COMPONENT.textAllCaps)}
          labelText={strings.textAllCaps}
        >
          <SelectInputItem key={0} text={'true'} value={'true'} />
          <SelectInputItem key={1} text={'false'} value={'false'} />
        </SelectInput>
        <SelectInput
          defaultValue={component.privateData}
          control={control}
          className={styles.input}
          name={getComponentFormName(index, FORM_KEYS_COMPONENT.privateData)}
          labelText={strings.privateData}
        >
          <SelectInputItem key={0} text={'true'} value={'true'} />
          <SelectInputItem key={1} text={'false'} value={'false'} />
        </SelectInput>
        <SelectInput
          defaultValue={component.position.x.gravity}
          control={control}
          className={styles.input}
          name={getComponentFormName(
            index,
            FORM_KEYS_COMPONENT.positionXGravity
          )}
          labelText={strings.positionXGravity}
        >
          {Object.values(POSITION_GRAVITY).map((gravity, index) => {
            return (
              <SelectInputItem key={index} text={gravity} value={gravity} />
            );
          })}
        </SelectInput>
        <TextInput
          defaultValue={component.position.x.offset}
          control={control}
          className={styles.input}
          type="number"
          name={getComponentFormName(
            index,
            FORM_KEYS_COMPONENT.positionXOffset
          )}
          labelText={strings.positionXOffset}
        />
        <SelectInput
          defaultValue={component.position.y.gravity}
          control={control}
          className={styles.input}
          name={getComponentFormName(
            index,
            FORM_KEYS_COMPONENT.positionYGravity
          )}
          labelText={strings.positionYGravity}
        >
          {Object.values(POSITION_GRAVITY).map((gravity, index) => {
            return (
              <SelectInputItem key={index} text={gravity} value={gravity} />
            );
          })}
        </SelectInput>
        <TextInput
          defaultValue={component.position.y.offset}
          control={control}
          className={styles.input}
          type="number"
          name={getComponentFormName(
            index,
            FORM_KEYS_COMPONENT.positionYOffset
          )}
          labelText={strings.positionYOffset}
        />
        <SelectInput
          defaultValue={component.position.rotation?.anchorPoint}
          control={control}
          className={styles.input}
          name={getComponentFormName(
            index,
            FORM_KEYS_COMPONENT.positionRotationAnchor
          )}
          labelText={strings.rotationAnchor}
        >
          {Object.values(POSITION_ROTATION_ANCHOR).map((anchor, index) => {
            return <SelectInputItem key={index} text={anchor} value={anchor} />;
          })}
        </SelectInput>
        <TextInput
          defaultValue={component.position.rotation?.value}
          control={control}
          className={styles.input}
          name={getComponentFormName(
            index,
            FORM_KEYS_COMPONENT.positionRotationValue
          )}
          labelText={strings.rotationValue}
        />
      </div>
    </div>
  );
}

export default TextComponent;
