export const Add = (props) => {
  return (
    <svg
      version="1.1"
      id="icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      className={props.className}
    >
      <polygon points="17,15 17,8 15,8 15,15 8,15 8,17 15,17 15,24 17,24 17,17 24,17 24,15 " />
    </svg>
  );
};
