import { getBaseRequestConfig, API_URL } from '../baseRequestConfig';
import httpRequest from '../httpRequest';

export function getLocalization() {
  const baseRequestConfig = getBaseRequestConfig();
  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/public-api/v1/localization`,
  });

  return httpRequest(requestConfig);
}
