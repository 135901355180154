import { Select } from '@carbon/react/es/components/Select';
import { SelectItem } from '@carbon/react/es/components/SelectItem';

function SelectOrganisationType({ types, onChange, information }) {
  return (
    <Select
      labelText="Club, cup or camp (optional)"
      id="information-organisation-type"
      name="organisationType"
      size="lg"
      onChange={(event) => {
        onChange(event.target);
      }}
      defaultValue={information.organisationType ?? 'placeholder-item'}
    >
      <>
        <SelectItem text="Select type" disabled value="placeholder-item" />
        {types.map((type) => {
          return <SelectItem key={type} text={type} value={type} />;
        })}
      </>
    </Select>
  );
}

export default SelectOrganisationType;
