import { environment } from './environments';

function getBaseRequestConfig(accessToken, contentType = 'application/json') {
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': contentType,
    },
    timeout: 14000,
  };

  if (accessToken) {
    config.headers['Access-Token-Admin'] = `${accessToken}`;
  }

  return config;
}

const API_URL = environment.API_URL;

export { getBaseRequestConfig, API_URL };
