const environments = {
  local: {
    API_URL: 'http://localhost:2323',
    APP_URL: 'http://localhost:3000',
    STRIPE_PUBLISHABLE_KEY:
      'pk_test_51N1VC8G8aYlHdb6ZOvPqtIF09XeIAoW7lCgHPhUXYav48JvKGCEeYKsYYRMqiMEE3r3pz5PP07AoB2LSAWbMeqUJ008fPDzBef',
    STRIPE_SUCCESS_URL: 'http://localhost:3000/card-creator/success',
    LOCATION_PIXEL_URL:
      'https://stage-static.striketradingcards.com/location.png',
  },
  stage: {
    API_URL: 'https://stage-api.striketradingcards.com',
    APP_URL: 'https://strike-stage.firebaseapp.com',
    STRIPE_PUBLISHABLE_KEY:
      'pk_test_51N1VC8G8aYlHdb6ZOvPqtIF09XeIAoW7lCgHPhUXYav48JvKGCEeYKsYYRMqiMEE3r3pz5PP07AoB2LSAWbMeqUJ008fPDzBef',
    STRIPE_SUCCESS_URL:
      'https://strike-stage.firebaseapp.com/card-creator/success',
    LOCATION_PIXEL_URL:
      'https://stage-static.striketradingcards.com/location.png',
  },
  production: {
    API_URL: 'https://api.striketradingcards.com',
    APP_URL: 'https://www.striketradingcards.com',
    STRIPE_PUBLISHABLE_KEY:
      'pk_live_51N1VC8G8aYlHdb6Zl5GJRu3oKLAB5ES8RkM4ji31Y2fEnegVAHBuKE2kbHcMRis6qQ9jRa8K0PQ7GP89Sq25iTtl00DcBssYMo',
    STRIPE_SUCCESS_URL:
      'https://admin.striketradingcards.com/card-creator/success',
    LOCATION_PIXEL_URL: 'https://static.striketradingcards.com/location.png',
  },
};

const getEnvironment = (hostname) => {
  if (hostname === 'localhost') {
    return 'local';
  }
  if (hostname === 'localhost' || hostname.includes('strike-stage')) {
    return 'stage';
  } else {
    return 'production';
  }
};

export const environment =
  environments[getEnvironment(window.location.hostname)];
