import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useParams } from 'react-router';
import { getFrame } from '../../../connectivity/admin/frames/ApiAdminFrames';
import EditFrameFields from '../../../frames/editFrameFields/EditFrameFields';
import FrameHolder from '../../../frames/frameHolder/FrameHolder';
import { COOKIES_ADMIN, getAccessToken } from '../../../utils/cookieUtils';
import { Button } from '@carbon/react/es/components/Button';
import { ROUTES } from '../../../index';
import { ArrowLeft } from '../../../components/icons/ArrowLeft';
import { Header } from '../../../components/header/Header';
import { Main } from '../../../components/main/Main';
import styles from './editFramePage.module.scss';
import { strings } from '../../../localization/Localization';

export const PARAM_NEW = 'new';

function EditFramePage() {
  const location = useLocation();
  const [cookies] = useCookies([COOKIES_ADMIN]);
  const accessToken = getAccessToken(cookies);
  const [frame, setFrame] = useState(undefined);
  const [previewFrame, setPreviewFrame] = useState(undefined);
  const [loadingFrame, setLoadingFrame] = useState(true);
  let { frameId } = useParams();

  useEffect(() => {
    const getPageData = () => {
      if (frameId === PARAM_NEW) {
        let copyFrame = location?.state?.frame;
        if (copyFrame) {
          copyFrame = {
            ...copyFrame,
            id: undefined,
            code: 'ninja',
          };
        }
        setFrame(copyFrame);
        setPreviewFrame(copyFrame);
        setLoadingFrame(false);
      } else {
        setLoadingFrame(true);
        getFrame(accessToken, frameId)
          .then((response) => {
            setFrame(response.data.frame);
            setPreviewFrame(response.data.frame);
            setLoadingFrame(false);
          })
          .catch((error) => {
            setLoadingFrame(false);
            console.error(error);
          });
      }
    };
    getPageData();
  }, [accessToken, frameId, location?.state?.frame]);

  const onFrameUpdated = useCallback((updatedFrame) => {
    setPreviewFrame(updatedFrame);
  }, []);

  return (
    <>
      <Header>
        <nav>
          <Button
            hasIconOnly
            iconDescription={strings.back}
            style={{ textDecoration: 'none' }}
            href={ROUTES.admin}
            tooltipPosition="bottom"
          >
            <ArrowLeft width="16" height="16" />
          </Button>
        </nav>
      </Header>
      <Main>
        <div className={styles.content}>
          <div className={styles.leftContainer}>
            {previewFrame && (
              <FrameHolder
                className={styles.frameHolder}
                frame={previewFrame}
              />
            )}
          </div>
          <div className={styles.rightContainer}>
            {!loadingFrame && (
              <EditFrameFields
                accessToken={accessToken}
                onFrameUpdated={onFrameUpdated}
                initialData={frame}
              />
            )}
          </div>
        </div>
      </Main>
    </>
  );
}

export default EditFramePage;
