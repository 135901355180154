import { strings } from '../localization/Localization';
import {
  emptyAndUndefinedAsZero,
  emptyAsUndefined,
} from '../utils/stringUtils';
import { FORM_KEYS } from './editFrameFields/EditFrameFields';

export function formToFrame(formData, preFrame, components) {
  const width = formData[FORM_KEYS.sizeWidth];
  if (!width) {
    return;
  }
  let defaultImage;
  if (emptyAsUndefined(formData[FORM_KEYS.defaultImage])) {
    defaultImage = {
      type: 'url',
      src: formData[FORM_KEYS.defaultImage],
    };
  }
  return {
    ...preFrame,
    name: [
      {
        key: 'eng',
        value: emptyAsUndefined(formData[FORM_KEYS.name]),
      },
    ],
    size: {
      width: emptyAndUndefinedAsZero(formData[FORM_KEYS.sizeWidth]),
      height: emptyAndUndefinedAsZero(formData[FORM_KEYS.sizeHeight]),
    },
    backgroundImage: {
      type: 'url',
      src: formData[FORM_KEYS.backgroundImage],
    },
    defaultImage: defaultImage,
    code: emptyAsUndefined(formData[FORM_KEYS.code]),
    priority: emptyAndUndefinedAsZero(formData[FORM_KEYS.priority]),
    categoryIds: getCategoryIds(formData),
    components:
      formData[FORM_KEYS.components]
        ?.filter((component, index) => components[index]?.removed === undefined)
        .map((component) => {
          return generateComponent(component);
        }) ?? [],
  };
}

const getCategoryIds = (formData) => {
  const formDataCategoryKeys = Object.keys(formData).filter((key) =>
    key.startsWith('category-')
  );
  const categoryIds = [];

  formDataCategoryKeys.forEach((categoryKey) => {
    formData[categoryKey].selectedItems?.forEach((item) => {
      categoryIds.push(item.id);
    });
  });

  return categoryIds;
};

function generateComponent(formData) {
  let positionRotation;
  if (emptyAsUndefined(formData.position.rotation?.anchorPoint)) {
    positionRotation = {
      anchorPoint: formData.position.rotation.anchorPoint,
      value: emptyAndUndefinedAsZero(formData.position.rotation.value),
    };
  }

  return {
    type: emptyAsUndefined(formData.type),
    content: {
      key: emptyAsUndefined(formData.content.key),
      cardHint: [
        {
          key: 'eng',
          value: emptyAsUndefined(formData.cardHint),
        },
      ],
    },
    font: {
      size: emptyAndUndefinedAsZero(formData.font.size),
      color: emptyAsUndefined(formData.font.color),
      hintColor: emptyAsUndefined(formData.font.hintColor),
      highlightedColor: emptyAsUndefined(formData.font.highlightedColor),
      letterSpacing: emptyAndUndefinedAsZero(formData.font.letterSpacing),
      family: emptyAsUndefined(formData.font.family),
      weight: emptyAsUndefined(formData.font.weight),
      textAllCaps: formData.font.textAllCaps,
    },
    privateData: formData.privateData,
    position: {
      x: {
        gravity: emptyAsUndefined(formData.position.x.gravity),
        offset: emptyAndUndefinedAsZero(formData.position.x.offset),
      },
      y: {
        gravity: emptyAsUndefined(formData.position.y.gravity),
        offset: emptyAndUndefinedAsZero(formData.position.y.offset),
      },
      rotation: positionRotation,
    },
  };
}

export const COMPONENT_TYPE = {
  text: 'text',
  number: 'number',
  hardcoded_text: 'hardcoded_text',
};

export const COMPONENT_CONTENT_KEY = {
  selectAnOption: {
    key: 'selectAnOption',
    hintText: strings.selectAnOption,
  },
  firstName: {
    key: 'firstName',
    hintText: strings.componentContentKeys.firstName,
  },
  lastName: {
    key: 'lastName',
    hintText: strings.componentContentKeys.lastName,
  },
  team: {
    key: 'team',
    hintText: strings.componentContentKeys.team,
  },
  club: {
    key: 'club',
    hintText: strings.componentContentKeys.club,
  },
  playerNumber: {
    key: 'playerNumber',
    hintText: strings.componentContentKeys.playerNumber,
  },
  fullName: {
    key: 'fullName',
    hintText: strings.componentContentKeys.fullName,
  },
  fullName2: {
    key: 'fullName2',
    hintText: strings.componentContentKeys.fullName2,
  },
  forehandBackhand: {
    key: 'forehandBackhand',
    hintText: strings.componentContentKeys.forehandBackhand,
  },
  forehandBackhandShort: {
    key: 'forehandBackhandShort',
    hintText: strings.componentContentKeys.forehandBackhandShort,
  },
  age: {
    key: 'age',
    hintText: strings.componentContentKeys.age,
  },
  skillLevel: {
    key: 'skillLevel',
    hintText: strings.componentContentKeys.skillLevel,
  },
  year: {
    key: 'year',
    hintText: strings.componentContentKeys.year,
  },
  seasonInformation: {
    key: 'seasonInformation',
    hintText: strings.componentContentKeys.year,
  },
};

export const POSITION_GRAVITY = {
  start: 'start',
  middle: 'middle',
  end: 'end',
};

export const POSITION_ROTATION_ANCHOR = {
  start: 'start',
  middle: 'middle',
};

export function isDisabled(frame) {
  return frame.status === 'DISABLED';
}
