import { SelectItem } from '@carbon/react/es/components/SelectItem';
import { Select } from '@carbon/react/es/components/Select';

function SelectSport({
  sports,
  onChange,
  information,
  setOtherSportIsChosen,
  invalid,
  invalidText,
}) {
  const updateSportsInformation = (event) => {
    const { value, name } = event;
    const chosenSportObject = sports.find((sport) => sport.id === value);
    if (!chosenSportObject) {
      onChange({ name: 'otherSport', value: '' });
      onChange({ name, value: chosenSportObject });
      setOtherSportIsChosen(true);
    } else {
      onChange({ name: 'otherSport', value: '' });
      onChange({ name, value: chosenSportObject });
      setOtherSportIsChosen(false);
    }
  };

  return (
    <Select
      id="information-country"
      labelText="Sport"
      placeholder="Sport"
      onChange={(event) => {
        updateSportsInformation(event.target);
      }}
      defaultValue={information.sport ?? 'placeholder-item'}
      invalid={invalid}
      invalidText={invalidText}
      size="lg"
      name="sport"
    >
      <>
        <SelectItem text="Select sport" disabled value="placeholder-item" />
        {sports
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map((sport, index) => {
            return (
              <SelectItem key={index} text={sport.name} value={sport.id} />
            );
          })}
      </>
    </Select>
  );
}

export default SelectSport;
