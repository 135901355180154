import { ComboBox } from '@carbon/react/es/components/ComboBox';

function SelectCountry({
  countries,
  onChange,
  information,
  invalid,
  invalidText,
}) {
  const updateCountryInformation = (event) => {
    onChange({
      name: 'country',
      value: event.selectedItem ?? undefined,
    });
  };
  return (
    <ComboBox
      id="combo-box-country"
      items={countries.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })}
      onChange={(event) => {
        updateCountryInformation(event);
      }}
      initialSelectedItem={information.country ?? ''}
      itemToString={(item) => (item ? item.name : '')}
      placeholder="Country"
      titleText="Country"
      size="lg"
      invalid={invalid}
      invalidText={invalidText}
    />
  );
}

export default SelectCountry;
