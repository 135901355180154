import React from 'react';
import styles from './errorAndLoader.module.scss';

function ErrorAndLoader({ error, loading }) {
  return (
    <div className={styles.errorAndLoader}>
      {error && !loading && <p className={styles.error}>{error}</p>}
      {loading && <p className={styles.loading}>...</p>}
    </div>
  );
}

export default ErrorAndLoader;
