export const Edit = (props) => {
  return (
    <svg
      fillRule="evenodd"
      viewBox="0 0 16 16"
      width={props.width}
      height={props.height}
      className={props.className}
    >
      <g fillRule="nonzero">
        <path d="M7.926 3.38L1.002 9.72V12h2.304l6.926-6.316L7.926 3.38zm.738-.675l2.308 2.304 1.451-1.324-2.308-2.309-1.451 1.329zM.002 9.28L9.439.639a1 1 0 0 1 1.383.03l2.309 2.309a1 1 0 0 1-.034 1.446L3.694 13H.002V9.28zM0 16.013v-1h16v1z"></path>
      </g>
    </svg>
  );
};
