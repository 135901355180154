import { deleteCategory } from '../../connectivity/admin/ApiAdminCategories';
import { ROUTES } from '../../index';
import { useHistory } from 'react-router-dom';
import { strings } from '../../localization/Localization';
import { TrashCan } from '../icons/TrashCan';
import { Button } from '@carbon/react/es/components/Button';
import { useCookies } from 'react-cookie';
import { COOKIES_ADMIN, getAccessToken } from '../../utils/cookieUtils';

export const DeleteCategoryButton = (props) => {
  const history = useHistory();
  const [cookies] = useCookies([COOKIES_ADMIN]);
  const accessToken = getAccessToken(cookies);

  return (
    <Button
      kind="danger"
      hasIconOnly
      onClick={() =>
        onDelete(accessToken, props.categoryId, history, props.callback)
      }
      iconDescription={strings.deleteCategory}
    >
      <TrashCan width="16" height="16" />
    </Button>
  );
};

const onDelete = (accessToken, categoryId, history, callback) => {
  if (confirm(strings.confirm.deleteCategory)) {
    deleteCategory(accessToken, categoryId)
      .then(() => {
        history?.push({
          pathname: ROUTES.categories,
        });

        callback && callback();
      })
      .catch((error) => {
        alert(strings.error.deleteCategory);
        console.error(error);
      });
  }
};
