export function getTranslationValue(languageTexts, locale) {
  if (!languageTexts || languageTexts?.length === 0) {
    return undefined;
  }
  const localeLookup = locale ?? 'eng';
  const item =
    languageTexts.find((item) => {
      return item.key === localeLookup;
    }) ?? languageTexts[0];
  return item.value;
}
