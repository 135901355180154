import styles from './buttonsRow.module.scss';
import classNames from 'classnames';

function ButtonsRow({
  step,
  setStep,
  nextButtonIsDisabled,
  nextButtonText,
  onNextClick,
  submit = false,
  onBackButtonClick = undefined,
  errorMessage,
}) {
  return (
    <div className={styles.buttonsRow}>
      <p className={styles.errorMessage}>{errorMessage}</p>
      <button
        className={classNames(styles.button, styles.backButton)}
        onClick={() => {
          onBackButtonClick ? onBackButtonClick() : setStep(step - 1);
        }}
      >
        Back
      </button>
      <button
        type={submit ? 'submit' : 'button'}
        className={styles.button}
        disabled={nextButtonIsDisabled}
        onClick={onNextClick ?? null}
      >
        {nextButtonText}
      </button>
    </div>
  );
}

export default ButtonsRow;
