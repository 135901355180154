import { addAlphaChannelToHexCode } from '../../../../utils/frameCreatorUtils';

export const frameOneTextComponents = (seasonInformation, colorScheme) => {
  return [
    {
      content: {
        cardHint: [{ key: 'eng', iosLanguageKey: 'en', value: '#' }],
        key: 'playerNumber',
      },
      font: {
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        family: 'TitilliumWeb',
        highlightedColor: '#FF42c1c9',
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        letterSpacing: -0.00766667,
        size: 0.0666667,
        textAllCaps: true,
        weight: 'Bold',
      },
      position: {
        rotation: null,
        x: {
          gravity: 'start',
          offset: 0.0851852,
        },
        y: {
          gravity: 'middle',
          offset: 0.346,
        },
      },
      privateData: false,
      type: 'text',
    },
    {
      type: 'text',
      content: {
        key: 'firstName',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'FIRST NAME',
          },
        ],
      },
      font: {
        size: 0.0281481,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0218421,
        family: 'TitilliumWeb',
        weight: 'Bold',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.468519,
        },
        y: {
          gravity: 'middle',
          offset: 0.324,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'lastName',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'LAST NAME',
          },
        ],
      },
      font: {
        size: 0.0444444,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0216667,
        family: 'TitilliumWeb',
        weight: 'Bold',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.468519,
        },
        y: {
          gravity: 'middle',
          offset: 0.3659,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'team',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'TEAM',
          },
        ],
      },
      font: {
        size: 0.0251852,
        color: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0217647,
        family: 'TitilliumWeb',
        weight: 'Bold',
        textAllCaps: true,
      },
      privateData: false,
      position: {
        x: {
          gravity: 'start',
          offset: 0.468519,
        },
        y: {
          gravity: 'middle',
          offset: 0.43,
        },
        rotation: null,
      },
    },
    {
      content: {
        cardHint: [
          {
            key: 'eng',
            value: seasonInformation,
          },
        ],
        key: 'seasonInformation',
      },
      font: {
        color: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        family: 'TitilliumWeb',
        highlightedColor: '#FF42c1c9',
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        letterSpacing: 0.15,
        size: 0.0251852,
        textAllCaps: true,
        weight: 'Bold',
      },
      position: {
        rotation: null,
        x: {
          gravity: 'end',
          offset: 0.07,
        },
        y: {
          gravity: 'middle',
          offset: 0.43,
        },
      },
      privateData: true,
      type: 'hardcoded_text',
    },
  ];
};
