import { useState } from 'react';
import useSWR from 'swr';
import { getPromoCodes } from '../../../connectivity/admin/promoCodes/apiAdminPromoCodes';
import { useCookies } from 'react-cookie';
import { COOKIES_ADMIN, getAccessToken } from '../../../utils/cookieUtils';
import Table from '@carbon/react/es/components/DataTable/Table';
import TableRow from '@carbon/react/es/components/DataTable/TableRow';
import TableCell from '@carbon/react/es/components/DataTable/TableCell';
import TableHead from '@carbon/react/es/components/DataTable/TableHead';
import TableBody from '@carbon/react/es/components/DataTable/TableBody';
import TableHeader from '@carbon/react/es/components/DataTable/TableHeader';
import { Button } from '@carbon/react/es/components/Button';
import { strings } from '../../../localization/Localization';
import { ROUTES } from '../../../index';
import { Main } from '../../../components/main/Main';
import { ArrowLeft } from '../../../components/icons/ArrowLeft';
import { Header } from '../../../components/header/Header';
import styles from './managePromoCodesPage.module.scss';
import { useHistory } from 'react-router-dom';
import { readableDate } from '../../../utils/stringUtils';

export const ManagePromoCodesPage = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const history = useHistory();

  return (
    <>
      <Header>
        <nav>
          <Button
            hasIconOnly
            iconDescription={strings.back}
            style={{ textDecoration: 'none' }}
            href={ROUTES.admin}
            tooltipPosition="bottom"
          >
            <ArrowLeft width="16" height="16" />
          </Button>
        </nav>
      </Header>
      <Main>
        <h1>Promo codes - Free cards</h1>
        <p className={styles.description}>
          To generate promo codes with discounts, go to{' '}
          <a
            target={'_blank'}
            href={'https://dashboard.stripe.com/coupons'}
            rel="noreferrer"
          >
            Stripe
          </a>{' '}
          You will NOT see the promo codes with discounts here.
        </p>
        <Button
          className={styles.freePromoCodeButton}
          onClick={() => history.push(ROUTES.createPromoCodes)}
        >
          Create free promo codes
        </Button>
        <Button
          className={styles.discountPromoCodeButton}
          target={'_blank'}
          href={'https://dashboard.stripe.com/coupons'}
        >
          Create discount promo codes
        </Button>
        <PromoCodeTable setPageIndex={setPageIndex} index={pageIndex} />
        <div style={{ display: 'none' }}>
          <PromoCodeTable index={pageIndex + 1} />
        </div>
      </Main>
    </>
  );
};

function PromoCodeTable({ index, setPageIndex }) {
  const [cookies] = useCookies([COOKIES_ADMIN]);
  const accessToken = getAccessToken(cookies);
  const { data } = useSWR([accessToken, index], () =>
    getPromoCodes(accessToken, index)
  );
  const promoCodes = data?.data?.promoCodes ?? [];
  // ... handle loading and error states
  if ((promoCodes.length ?? 0) === 0) {
    return <div></div>;
  }

  const toTableCell = (key, value) => {
    if (key === 'createdDate' || key === 'modifiedDate') {
      return readableDate(value);
    } else {
      return value;
    }
  };

  const headers = ['Code', 'Status', 'Customer', 'Created', 'Updated'];
  return (
    <div>
      <Table size="lg" useZebraStyles={false} aria-label="sample table">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableHeader id={header.code} key={header}>
                {header}
              </TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {promoCodes.map((promoCode) => (
            <TableRow key={promoCode.code}>
              {Object.keys(promoCode).map((key) => {
                return (
                  <TableCell key={key}>
                    {toTableCell(key, promoCode[key])}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <p className={styles.pageCounter}>{`Page: ${index + 1} / ${
        data.data.totalPages
      } `}</p>
      {index !== 0 && (
        <Button onClick={() => setPageIndex(index - 1)}>Previous</Button>
      )}
      {data.data.nextPage && (
        <Button onClick={() => setPageIndex(index + 1)}>Next</Button>
      )}
    </div>
  );
}
