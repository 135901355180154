import React, { useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { ROUTES } from '../../../../index';
import { completeOrder } from '../../../../connectivity/frameCreator/apiFrameOrders';
import { strings } from '../../../../localization/Localization';
import { TextInput } from '@carbon/react/es/components/TextInput';
import styles from './checkoutForm.module.scss';
import { useHistory } from 'react-router';
import { environment } from '../../../../connectivity/environments';
import { Loading } from '@carbon/react/es/components/Loading';

const FORM_KEYS = {
  promoCode: 'promoCode',
};
const CheckoutForm = ({
  step,
  setStep,
  loading,
  setLoading,
  order,
  adminAccessToken,
  initialPromoCode,
  isFreePromoCode,
  appliedPromoCode,
  errorUpdatedPrice,
  loadingUpdatedPrice,
  onApplyPromoCode,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [promoCode, setPromoCode] = useState(initialPromoCode ?? '');
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();
  const isAdmin = adminAccessToken !== undefined;
  const handleSubmit = async (event) => {
    setLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (isFreePromoCode || isAdmin) {
      completeOrder(adminAccessToken, order.id, {
        promoCode: appliedPromoCode,
      })
        .then((response) => {
          history.push(
            `${ROUTES.cardCreatorSuccess}?redirect_status=succeeded`
          );
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setLoading(false);
        });
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: environment.STRIPE_SUCCESS_URL,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  return (
    <form onSubmit={handleSubmit} className={styles.checkoutForm}>
      {stripe && (
        <>
          {loading && (
            <Loading
              id="checkout-form-loader"
              withOverlay={true}
              small={false}
            />
          )}
          {!isFreePromoCode && !isAdmin && <PaymentElement />}

          {!isAdmin && (
            <div className={styles.promoCodeContainer}>
              {appliedPromoCode && (
                <>
                  <p className={styles.appliedPromoCode}>
                    {`Promo code: ${appliedPromoCode}`}
                  </p>
                  <button
                    disabled={loadingUpdatedPrice}
                    onClick={(event) => {
                      event.preventDefault();
                      onApplyPromoCode();
                    }}
                    className={styles.applyButton}
                  >
                    Remove
                  </button>
                </>
              )}
              {!appliedPromoCode && (
                <>
                  <TextInput
                    id="promo-code"
                    labelText="Promo code"
                    size="lg"
                    disabled={loadingUpdatedPrice}
                    className={styles.promoCodeInput}
                    value={promoCode}
                    onChange={(event) => {
                      const { value, name } = event.target;
                      setPromoCode(value);
                    }}
                  />
                  <button
                    disabled={promoCode === ''}
                    onClick={(event) => {
                      event.preventDefault();
                      onApplyPromoCode(promoCode);
                    }}
                    className={styles.applyButton}
                  >
                    Apply
                  </button>
                </>
              )}
            </div>
          )}

          {(errorMessage || errorUpdatedPrice) && (
            <div>{errorMessage || errorUpdatedPrice}</div>
          )}
          <div className={styles.buttonContainer}>
            <button
              type="button"
              className={styles.backButton}
              onClick={(event) => {
                event.preventDefault();
                setStep(step - 1);
              }}
              disabled={loading || loadingUpdatedPrice}
            >
              Back
            </button>
            <button
              disabled={loading || loadingUpdatedPrice}
              type="submit"
              className={styles.submitButton}
            >
              Submit
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default CheckoutForm;
