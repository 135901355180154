import { addAlphaChannelToHexCode } from '../../../../utils/frameCreatorUtils';

export const frameFourTextComponents = (seasonInformation, colorScheme) => {
  return [
    {
      type: 'text',
      content: {
        key: 'playerNumber',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: '#',
          },
        ],
      },
      font: {
        size: 0.067,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.0245556,
        family: 'TitilliumWeb',
        weight: 'Bold',
        textAllCaps: true,
      },
      privateData: false,
      position: {
        x: {
          gravity: 'start',
          offset: 0.084,
        },
        y: {
          gravity: 'middle',
          offset: 0.3595,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'firstName',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'FIRST NAME',
          },
        ],
      },
      font: {
        size: 0.029,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.03,
        family: 'TitilliumWeb',
        weight: 'Bold',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.445,
        },
        y: {
          gravity: 'middle',
          offset: 0.315,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'lastName',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'LAST NAME',
          },
        ],
      },
      font: {
        size: 0.045,
        color: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.primary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.02,
        family: 'TitilliumWeb',
        weight: 'Bold',
        textAllCaps: true,
      },
      privateData: true,
      position: {
        x: {
          gravity: 'start',
          offset: 0.445,
        },
        y: {
          gravity: 'middle',
          offset: 0.3595,
        },
        rotation: null,
      },
    },
    {
      type: 'text',
      content: {
        key: 'team',
        cardHint: [
          {
            key: 'eng',
            iosLanguageKey: 'en',
            value: 'TEAM',
          },
        ],
      },
      font: {
        size: 0.024,
        color: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        highlightedColor: '#FF42c1c9',
        letterSpacing: 0.03,
        family: 'TitilliumWeb',
        weight: 'Bold',
        textAllCaps: true,
      },
      privateData: false,
      position: {
        x: {
          gravity: 'start',
          offset: 0.445,
        },
        y: {
          gravity: 'middle',
          offset: 0.434,
        },
        rotation: null,
      },
    },
    {
      content: {
        cardHint: [
          {
            key: 'eng',
            value: seasonInformation,
          },
        ],
        key: 'seasonInformation',
      },
      font: {
        color: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        family: 'TitilliumWeb',
        highlightedColor: '#FF42c1c9',
        hintColor: addAlphaChannelToHexCode(colorScheme.textColors.secondary),
        letterSpacing: 0.02,
        size: 0.024,
        textAllCaps: true,
        weight: 'Bold',
      },
      position: {
        rotation: null,
        x: {
          gravity: 'middle',
          offset: 0.3,
        },
        y: {
          gravity: 'middle',
          offset: 0.434,
        },
      },
      privateData: true,
      type: 'hardcoded_text',
    },
  ];
};
