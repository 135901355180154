import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import styles from './header.module.scss';
class Header extends Component {
  render() {
    return (
      <header className={styles.header}>
        <Container fluid>
          <Row>
            <Col>
              <img src="/img/logo-blue.svg" alt="strike-logo" />
            </Col>
          </Row>
        </Container>
      </header>
    );
  }
}

export default Header;
