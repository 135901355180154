export function emptyAsUndefined(value) {
  if (value && typeof value === 'string' && value.length !== 0) {
    return value;
  }
  return undefined;
}

export function emptyAndUndefinedAsZero(value) {
  if (!value || (value && typeof value === 'string' && value.length === 0)) {
    return 0;
  }
  if (value && typeof value === 'string') {
    return Number(value);
  }
  return value;
}

export function isEmpty(value) {
  if (typeof value === 'string' && value?.length === 0) {
    return true;
  }
  return false;
}

export function convertHex(hexCode) {
  let hex = hexCode.replace('#', '');
  const opacity = parseInt(hex.substring(0, 2), 16) / 255;
  const r = parseInt(hex.substring(2, 4), 16);
  const g = parseInt(hex.substring(4, 6), 16);
  const b = parseInt(hex.substring(6, 8), 16);

  return `rgba(${r},${g},${b},${opacity})`;
}

export function readableDate(dateString) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  };
  return new Date(dateString).toLocaleDateString('sv-SE', options);
}
