import { TextInput as CarbonTextInput } from '@carbon/react/es/components/TextInput';
import React from 'react';
import { Controller } from 'react-hook-form';

function TextInput({
  name,
  className,
  defaultValue,
  control,
  helperText,
  invalidText,
  type,
  labelText,
  placeholder,
}) {
  const initValue =
    type === 'number'
      ? defaultValue
        ? defaultValue
        : ''
      : defaultValue
      ? defaultValue
      : '';
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={initValue}
      render={({ field, onChange }) => {
        return (
          <div className={className}>
            <CarbonTextInput
              id={field.name}
              onChange={onChange}
              type={type}
              invalidText={invalidText}
              labelText={labelText}
              placeholder={placeholder}
              helperText={helperText}
              {...field}
            />
          </div>
        );
      }}
    />
  );
}

export default TextInput;
