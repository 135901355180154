import { strings } from '../../localization/Localization';
import { Button } from '@carbon/react/es/components/Button';
import { ArrowLeft } from '../icons/ArrowLeft';
import { Header } from './Header';

export const BackHeader = ({ href }) => {
  return (
    <Header>
      <nav>
        <Button
          hasIconOnly
          iconDescription={strings.back}
          style={{ textDecoration: 'none' }}
          href={href}
          tooltipPosition="bottom"
        >
          <ArrowLeft width="16" height="16" />
        </Button>
      </nav>
    </Header>
  );
};
