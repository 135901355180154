import { useEffect, useState } from 'react';
import StepCounter from './stepCounter/StepCounter';
import styles from './cardCreator.module.scss';
import SelectFrame from './selectFrame/SelectFrame';
import StyleFrame from './styleFrame/StyleFrame';
import FramePreview from './framePreview/FramePreview';
import classNames from 'classnames';
import CardCreatorHeader from './cardCreatorHeader/CardCreatorHeader';
import OrganisationInformation from './organisationInformation/OrganisationInformation';
import { getCategories } from '../../connectivity/frameCreator/categories/apiCategories';
import { Theme } from '@carbon/react/es/components/Theme';
import Checkout from './checkout/Checkout';
import HelpModal from './helpModal/HelpModal';
import axios from 'axios';
import { getCurrency } from '../../utils/frameCreatorUtils';
import { getPrices } from '../../connectivity/frameCreator/apiStripe';
import { environment } from '../../connectivity/environments';

const QUERY_PARAM_PROMO_CODE = 'pc';
const QUERY_PARAM_AFFILIATE = 'af';
export const FROM = 'af';
function CardCreator() {
  const [step, setStep] = useState(1);
  const [colorScheme, setColorScheme] = useState({
    frameColors: {
      primary: '',
      secondary: '',
      tertiary: '',
    },
    textColors: {
      primary: '',
      secondary: '',
      tertiary: '',
    },
  });
  const [seasonYear, setSeasonYear] = useState('2023/24');
  const [frameId, setFrameId] = useState(undefined);
  const [information, setInformation] = useState({
    name: '',
    email: '',
    phone: '',
    country: undefined,
    sport: undefined,
    organisationType: undefined,
    organisationName: '',
    otherSport: '',
  });
  const [sports, setSports] = useState([]);
  const [countries, setCountries] = useState([]);
  const [logoUrl, setLogoUrl] = useState(undefined);
  const [currency, setCurrency] = useState(null);
  const [product, setProduct] = useState(null);
  const [logoIsLandscape, setLogoIsLandscape] = useState(false);
  const [rightsToUseLogo, setRightsToUseLogo] = useState(false);
  const [helpModalIsOpen, setHelpModalIsOpen] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const promoCode = queryParams.get(QUERY_PARAM_PROMO_CODE);
  const affiliate = queryParams.get(QUERY_PARAM_AFFILIATE);

  const content =
    step === 1 ? (
      <SelectFrame
        setStep={setStep}
        frameId={frameId}
        setFrameId={setFrameId}
        setColorScheme={setColorScheme}
        currency={currency}
        promoCode={promoCode}
        product={product}
      />
    ) : step === 2 ? (
      <StyleFrame
        logoUrl={logoUrl}
        setLogoUrl={setLogoUrl}
        colorScheme={colorScheme}
        setColorScheme={setColorScheme}
        step={step}
        setStep={setStep}
        rightsToUseLogo={rightsToUseLogo}
        setRightsToUseLogo={setRightsToUseLogo}
        setSeasonYear={setSeasonYear}
        seasonYear={seasonYear}
        frameId={frameId}
        setLogoIsLandscape={setLogoIsLandscape}
      />
    ) : step === 3 ? (
      <OrganisationInformation
        information={information}
        setInformation={setInformation}
        sports={sports}
        countries={countries}
        step={step}
        setStep={setStep}
      />
    ) : step === 4 ? (
      <Checkout
        step={step}
        setStep={setStep}
        information={information}
        colorScheme={colorScheme}
        seasonYear={seasonYear}
        promoCodeFromUrl={promoCode}
        affiliateFromUrl={affiliate}
        frameId={frameId}
        logoUrl={logoUrl}
        currency={currency}
        product={product}
      />
    ) : null;

  useEffect(() => {
    axios
      .get(environment.LOCATION_PIXEL_URL)
      .then((response) => {
        const country = response.headers['x-country'];
        setCurrency(getCurrency(country));
      })
      .catch((error) => {
        console.error(error);
      });

    getPrices()
      .then((response) => {
        setProduct(response.data.products[0]);
      })
      .catch((error) => {
        console.error(error);
      });

    getCategories()
      .then((response) => {
        const sportsList = [];
        const countryList = [];
        response.data.categories.map((category) => {
          if (category.type === 'SPORTS_TYPE') {
            const englishName = category?.name?.find(
              (name) => name.languageKey === 'eng'
            );
            sportsList.push({ name: englishName.value, id: category.id });
          }
          if (category.type === 'COUNTRY') {
            const englishName = category?.name?.find(
              (name) => name.languageKey === 'eng'
            );
            countryList.push({ name: englishName.value, id: category.id });
          }
        });
        setSports(sportsList);
        setCountries(countryList);
      })
      .catch((error) => {
        alert('Network error. Try refreshing the page');
        console.error(error);
      });
  }, []);

  useEffect(() => {
    document.getElementById('root').scrollTo(0, 0);
  }, [step]);

  return (
    <Theme as="div" theme="g90" className={styles.cardCreator}>
      <div className={styles.innerWrapper}>
        <CardCreatorHeader
          onHelpClick={() => {
            setHelpModalIsOpen(true);
          }}
        />
        <StepCounter
          step={step}
          setStep={setStep}
          frameId={frameId}
          information={information}
          logoUrl={logoUrl}
        />
        <div
          className={classNames(styles.steps, step !== 1 && styles.fullWidth)}
        >
          {step !== 1 && (
            <FramePreview
              frameId={frameId}
              logoUrl={logoUrl}
              colorScheme={colorScheme}
              seasonYear={seasonYear}
              className={classNames(
                styles.framePreviewWrapper,
                step !== 2 && styles.displayNoneIfMobile
              )}
              showImageButtons={true}
              logoIsLandscape={logoIsLandscape}
            />
          )}
          <div
            className={classNames(
              styles.stepsBody,
              step === 1 && styles.fullWidth
            )}
          >
            {content}
          </div>
        </div>
        {helpModalIsOpen && (
          <HelpModal
            onClose={() => {
              setHelpModalIsOpen(false);
            }}
          />
        )}
      </div>
    </Theme>
  );
}

export default CardCreator;
