export const Decline = (props) => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width}
      height={props.height}
    >
      <path
        d="M0 7C0 8.38447 0.410543 9.73785 1.17971 10.889C1.94888 12.0401 3.04213 12.9373 4.32122 13.4672C5.6003 13.997 7.00776 14.1356 8.36563 13.8655C9.7235 13.5954 10.9708 12.9287 11.9497 11.9497C12.9287 10.9708 13.5954 9.7235 13.8655 8.36563C14.1356 7.00776 13.997 5.6003 13.4672 4.32122C12.9373 3.04213 12.0401 1.94888 10.889 1.17971C9.73785 0.410543 8.38447 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7ZM11.575 10.875L3.125 2.425C4.27532 1.4675 5.74196 0.974489 7.23708 1.04273C8.7322 1.11097 10.1478 1.73554 11.2062 2.79385C12.2645 3.85216 12.889 5.2678 12.9573 6.76292C13.0255 8.25804 12.5325 9.72468 11.575 10.875ZM3.12 11.58C1.90916 10.5518 1.1555 9.0853 1.02428 7.5022C0.893058 5.9191 1.39499 4.34858 2.42 3.135L10.865 11.58C9.78211 12.495 8.4102 12.997 6.9925 12.997C5.5748 12.997 4.20289 12.495 3.12 11.58Z"
        fill="white"
      />
    </svg>
  );
};
