import styles from './checkout.module.scss';
import { useEffect, useState } from 'react';
import { postOrder } from '../../../connectivity/frameCreator/apiFrameOrders';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './checkoutForm/CheckoutForm';
import { Loading } from '@carbon/react/es/components/Loading';
import {
  createIntent,
  updateIntent,
} from '../../../connectivity/frameCreator/apiStripe';
import { environment } from '../../../connectivity/environments';
import { useCookies } from 'react-cookie';
import {
  COOKIE_ADMIN_ACCESS_TOKEN,
  COOKIES_ADMIN,
  getCookie,
} from '../../../utils/cookieUtils';

const stripePromise = loadStripe(environment.STRIPE_PUBLISHABLE_KEY);

function Checkout({
  step,
  setStep,
  information,
  colorScheme,
  promoCodeFromUrl,
  affiliateFromUrl,
  seasonYear,
  frameId,
  logoUrl,
  product,
  currency,
}) {
  const [clientSecret, setClientSecret] = useState(undefined);
  const [loadingUpdatedPrice, setLoadingUpdatedPrice] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(undefined);
  const [updatedPrice, setUpdatedPrice] = useState(undefined);
  const [appliedPromoCode, setAppliedPromoCode] = useState(undefined);
  const [errorUpdatedPrice, setErrorUpdatedPrice] = useState(undefined);
  const [cookies] = useCookies([COOKIES_ADMIN]);
  const adminAccessToken = getCookie(cookies, COOKIE_ADMIN_ACCESS_TOKEN);
  const isAdmin = adminAccessToken !== undefined;

  const options = {
    clientSecret: clientSecret,
    appearance: { theme: 'night' },
  };

  const foundProduct = product.priceList.find(
    (price) => price.currency === currency
  );
  let isFreePromoCode = false;
  let priceString = '';
  let oldPriceString = '';
  if (foundProduct) {
    if (updatedPrice && updatedPrice.amount !== foundProduct.amount) {
      let newPriceString;
      if (updatedPrice.amount === 0) {
        isFreePromoCode = true;
        priceString = 'Price: Free';
        oldPriceString = `${foundProduct.amount} ${foundProduct.currency}`;
      } else {
        priceString = `Price: ${updatedPrice.amount} ${foundProduct.currency}`;
        oldPriceString = `${foundProduct.amount} ${foundProduct.currency}`;
      }
    } else {
      priceString = `Price: ${foundProduct.amount} ${foundProduct.currency}`;
    }
  }
  if (isAdmin) {
    priceString = `Price: Free admin order`;
  }

  useEffect(() => {
    if (product) {
      postFrameOrder();
    }
  }, [product]);

  const onApplyPromoCode = (promoCode) => {
    setLoadingUpdatedPrice(true);
    setErrorUpdatedPrice(undefined);
    updateIntent({
      currency: foundProduct.currency,
      orderId: order.id,
      productId: product.id,
      promoCode: promoCode,
    })
      .then((response) => {
        setAppliedPromoCode(promoCode);
        setUpdatedPrice(response.data.price);
        setLoadingUpdatedPrice(false);
      })
      .catch((error) => {
        setLoadingUpdatedPrice(false);
        setErrorUpdatedPrice(error.message);
        console.error(error);
      });
  };

  useEffect(() => {
    if (clientSecret && promoCodeFromUrl) {
      onApplyPromoCode(promoCodeFromUrl);
    }
  }, [clientSecret, promoCodeFromUrl]);

  const postFrameOrder = () => {
    const categoryIds = [information.country.id];
    if (information.sport) {
      categoryIds.push(information.sport.id);
    }
    const data = {
      frameData: {
        categoryIds: categoryIds,
        clubEventInfo: information.organisationName,
        frameColors: colorScheme.frameColors,
        frameType: frameId,
        logoUrl: logoUrl,
        seasonFormat: seasonYear,
        sportInfo:
          information.sport === undefined ? information.otherSport : undefined,
        textColors: colorScheme.textColors,
      },
      orderData: {
        email: information.email,
        name: information.name,
        phone: information.phone,
        affiliate: affiliateFromUrl,
      },
    };

    postOrder(data)
      .then((response) => {
        setOrder(response.data.order);
        createIntent({
          currency: foundProduct.currency,
          orderId: response.data.order.id,
          productId: product.id,
        })
          .then((response) => {
            setLoading(false);
            setClientSecret(response.data.clientSecret);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <div className={styles.checkout}>
      <h2 className={styles.heading}>Payment</h2>
      {loading && (
        <Loading id="checkout-loader" withOverlay={true} small={false} />
      )}
      {product && (
        <p className={styles.subHeading}>
          {priceString}
          {oldPriceString && (
            <span className={styles.oldPrice}>{oldPriceString}</span>
          )}
        </p>
      )}

      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            setLoading={setLoading}
            loading={loading}
            adminAccessToken={adminAccessToken}
            errorUpdatedPrice={errorUpdatedPrice}
            loadingUpdatedPrice={loadingUpdatedPrice}
            appliedPromoCode={appliedPromoCode}
            onApplyPromoCode={onApplyPromoCode}
            initialPromoCode={promoCodeFromUrl}
            isFreePromoCode={isFreePromoCode}
            order={order}
            step={step}
            setStep={setStep}
          />
        </Elements>
      )}
    </div>
  );
}

export default Checkout;
