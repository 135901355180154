import { Select as CarbonSelect } from '@carbon/react/es/components/Select';
import { SelectItem as CarbonSelectItem } from '@carbon/react/es/components/SelectItem';
import React from 'react';
import { Controller } from 'react-hook-form';

export function SelectInput({
  name,
  className,
  defaultValue = '',
  control,
  helperText,
  invalidText,
  labelText,
  children,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ''}
      render={({ field, onChange }) => {
        return (
          <div className={className}>
            <CarbonSelect
              id={field.name}
              onChange={onChange}
              invalidText={invalidText}
              labelText={labelText}
              helperText={helperText}
              {...field}
            >
              {children}
            </CarbonSelect>
          </div>
        );
      }}
    />
  );
}

export function SelectInputItem({ text, value }) {
  return <CarbonSelectItem text={text} value={value} />;
}
