import styles from './stepCounter.module.scss';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';

function StepCounter({ step, setStep, frameId, information, logoUrl }) {
  const steps = useRef([
    { text: 'SELECT TEMPLATE', ref: useRef() },
    { text: 'STYLE YOUR TEMPLATE', ref: useRef() },
    { text: 'YOUR INFO', ref: useRef() },
    { text: 'CHECKOUT', ref: useRef() },
  ]);

  const informationIsFilledIn =
    information.organisationName !== '' &&
    information.name !== '' &&
    (information.sports !== undefined || information.otherSport !== '') &&
    information.country !== '' &&
    information.email !== '';

  useEffect(() => {
    steps.current[step - 1].ref.current.scrollIntoView();
  }, [step, steps]);

  return (
    <div id="step-counter" className={styles.stepCounter}>
      <ol className={styles.list}>
        {steps.current.map((stepData, index) => (
          <li
            className={classNames(
              styles.listItem,
              index === step - 1 && styles.active
            )}
            key={index}
          >
            <button
              className={classNames(
                styles.button,
                index === step - 1 && styles.active
              )}
              onClick={() => {
                setStep(index + 1);
              }}
              disabled={
                (index !== 0 && !frameId) ||
                (index === 2 && !logoUrl) ||
                (index === 3 && !informationIsFilledIn)
              }
              ref={stepData.ref}
            >
              {stepData.text}
            </button>
          </li>
        ))}
      </ol>
      <div
        className={classNames(
          styles.border,
          step === 1 && styles.stepOne,
          step === 2 && styles.stepTwo,
          step === 3 && styles.stepThree,
          step === 4 && styles.stepFour
        )}
      />
    </div>
  );
}

export default StepCounter;
