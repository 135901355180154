import styles from './successPage.module.scss';
import { Player } from '@lottiefiles/react-lottie-player';

function SuccessPage() {
  const queryParams = new URLSearchParams(window.location.search);

  /*TODO payment intent and client secret for future stuff*/
  /*  const payment_intent = queryParams.get('payment_intent');
  const payment_intent_client_secret = queryParams.get(
    'payment_intent_client_secret'
  );*/

  const redirect_status = queryParams.get('redirect_status'); // succeeded, processing, payment-failed
  const succeeded = redirect_status === 'succeeded';

  return (
    <div className={styles.successPage}>
      <div className={styles.content}>
        <h3 className={styles.title}>
          {succeeded ? 'Payment completed!' : 'Something went wrong!'}
        </h3>
        <p className={styles.paragraph}>
          {succeeded
            ? 'Our team will take a closer look on your sports card before we release it on the app.'
            : 'The payment failed.'}
        </p>
        {succeeded && (
          <Player
            src="https://lottie.host/a5e8a61f-0ab8-4809-bc84-14bfe29ae7ca/W5L99O0uvd.json"
            style={{ height: '300px', width: '300px' }}
            autoplay={true}
            loop={true}
          />
        )}
        <a
          href={'https://www.striketradingcards.com/'}
          className={styles.linkToHome}
        >
          Back to Strike
        </a>
      </div>
    </div>
  );
}

export default SuccessPage;
