export const ArrowLeft = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      className={props.className}
      viewBox="0 0 32 32"
      fill={props.fill ?? 'black'}
    >
      <polygon points="14 26 15.41 24.59 7.83 17 28 17 28 15 7.83 15 15.41 7.41 14 6 4 16 14 26" />
    </svg>
  );
};
