import styles from './frameOrder.module.scss';
import { Header } from '../../../../components/header/Header';
import { strings } from '../../../../localization/Localization';
import { ROUTES } from '../../../../index';
import { ArrowLeft } from '../../../../components/icons/ArrowLeft';
import { Button } from '@carbon/react/es/components/Button';
import { Main } from '../../../../components/main/Main';
import React, { useEffect, useState, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import {
  COOKIE_ADMIN_ACCESS_TOKEN,
  COOKIES_ADMIN,
  getCookie,
} from '../../../../utils/cookieUtils';
import { useParams } from 'react-router-dom';
import {
  approveFrameOrder,
  completeFrame,
  declineFrameOrder,
  getFrameOrder,
  putFrameOrder,
} from '../../../../connectivity/admin/frameOrders/apiAdminFrameOrders';
import classNames from 'classnames';
import CategoryPills from '../categoryPills/CategoryPills';
import { Modal } from '@carbon/react/es/components/Modal';
import TextInput from '@carbon/react/es/components/TextInput';
import { Theme } from '@carbon/react/es/components/Theme';
import DragDropFile from '../../../frameCreator/styleFrame/dragDropFile/DragDropFile';
import { postLogo } from '../../../../connectivity/frameCreator/apiLogo';
import {
  FrameById,
  getLogoSizeRequirements,
  getTextComponents,
  resizeFile,
  returnLogoPlacement,
} from '../../../../utils/frameCreatorUtils';
import { Save } from '@carbon/icons-react/next';
import { Decline } from '../../../../components/icons/Decline';
import { renderToString } from 'react-dom/server';
import FramePreview from '../../../frameCreator/framePreview/FramePreview';
import ApproveDeclineFrameDesignModal from './approveDeclineDesignModal/ApproveDeclineFrameDesignModal';
import { Review } from '../../../../components/icons/Review';
import Palette from '../../../frameCreator/styleFrame/palette/Palette';
import LoginForm from '../../../../login/loginForm/LoginForm';

let orderStatus;

function FrameOrder() {
  const { frameOrderId } = useParams();
  const [cookies] = useCookies([COOKIES_ADMIN]);
  const accessToken = getCookie(cookies, COOKIE_ADMIN_ACCESS_TOKEN);
  const [frameOrder, setFrameOrder] = useState(undefined);
  const [colorScheme, setColorScheme] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [clubCupOrCampName, setClubCupOrCampName] = useState();
  const [seasonInformation, setSeasonInformation] = useState(undefined);
  const [logoUrl, setLogoUrl] = useState(undefined);
  const [modalData, setModalData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [mergedImageUrl, setMergedImageUrl] = useState(undefined);
  const [approveDeclineModalIsOpen, setApproveDeclineModalIsOpen] =
    useState(false);
  const [approveDesign, setApproveDesign] = useState(false);
  const [logoIsLandscape, setLogoIsLandscape] = useState(false);
  const [needsSaving, setNeedsSaving] = useState(false);

  useEffect(() => {
    getFrameOrder(frameOrderId, accessToken)
      .then((response) => {
        setFrameOrder(response.data.order);
        setCategories(response.data.order.frameData.categories);
        setClubCupOrCampName(response.data.order.frameData.clubEventInfo);
        setSeasonInformation(response.data.order.frameData.seasonFormat);
        orderStatus = response.data.order.orderStatus;
        setLogoUrl(response.data.order.frameData.logoPath);
        const image = new Image();
        image.src = response.data.order.frameData.logoPath;
        image.onload = async () => {
          const { height, width } = image;
          if (width > height) {
            setLogoIsLandscape(true);
          }
        };
        setLogoPlacement(
          returnLogoPlacement(response.data.order.frameData.frameType)
        );
        setColorScheme({
          frameColors: {
            primary: response.data.order.frameData.frameColor1,
            secondary: response.data.order.frameData.frameColor2,
            tertiary: response.data.order.frameData.frameColor3,
          },
          textColors: {
            primary: response.data.order.frameData.textColor1,
            secondary: response.data.order.frameData.textColor2,
            tertiary: response.data.order.frameData.textColor3,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        alert(strings.alert.unableToFetchFrameOrder);
      });
  }, [accessToken, frameOrderId]);

  const updateFrameColors = useCallback(
    (event) => {
      const { name, value } = event;
      setColorScheme({
        ...colorScheme,
        frameColors: {
          ...colorScheme.frameColors,
          [name]: value,
        },
      });
      setNeedsSaving(true);
    },
    [colorScheme, setColorScheme]
  );

  const updateTextColors = useCallback(
    (event) => {
      const { name, value } = event;
      setColorScheme({
        ...colorScheme,
        textColors: {
          ...colorScheme.textColors,
          [name]: value,
        },
      });
      setNeedsSaving(true);
    },
    [colorScheme, setColorScheme]
  );

  const handleUploadLogo = async (file) => {
    if (!file) {
      setLogoUrl('');
      return;
    }
    setLoading(true);
    const logoSizeRequirements = getLogoSizeRequirements(
      frameOrder.frameData.frameType
    );

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = async () => {
        const { height, width } = image;
        if (width > height) {
          setLogoIsLandscape(true);
        }
        if (
          width >= logoSizeRequirements.width &&
          height >= logoSizeRequirements.height
        ) {
          try {
            const logo = await resizeFile(
              file,
              logoSizeRequirements.width,
              logoSizeRequirements.height
            );

            postLogo(logo)
              .then((response) => {
                setLogoUrl(response.data.fileUrl);
                setLoading(false);
                setNeedsSaving(true);
              })
              .catch((error) => {
                console.error(error);
                alert(strings.alert.somethingWentWrong);
                setLoading(false);
              });
          } catch (error) {
            console.log(error);
            alert(strings.alert.somethingWentWrong);
            setLoading(false);
          }
        } else {
          alert(strings.alert.logoIsTooSmall);
          setLoading(false);
        }
      };
    };
  };

  const handleDeleteCategoryClick = (id, text) => {
    setModalData({
      onSubmit: () => {
        const filteredCategories = categories.filter(
          (category) => category.id !== id
        );
        setCategories(filteredCategories);
        setNeedsSaving(true);
      },
      title: `${strings.doYouWantToRemoveCategory} ${text}`,
    });
  };

  const handleDeleteClubCupOrCamp = (_, text) => {
    setModalData({
      onSubmit: () => {
        setClubCupOrCampName(undefined);
        setNeedsSaving(true);
      },
      title: `${strings.doYouWantToRemoveCategory} ${text}`,
    });
  };

  const mergeFrameProps = {
    width: 1080,
    height: 1350,
    colorScheme: colorScheme,
    showText: false,
    showLogoPlaceHolder: false,
    showSeasonYear: false,
  };

  const mergeFrame = () => {
    const frameComponent = (
      <FrameById
        frameId={frameOrder.frameData.frameType}
        frameProps={mergeFrameProps}
      />
    );
    const frameString = renderToString(frameComponent);
    //remove all html-elements outside <svg>
    const svgString = frameString.replace(/(<svg.+<\/svg>).*/, '$1');

    const blob = new Blob([svgString], { type: 'image/svg+xml' });

    completeFrame(
      blob,
      frameOrderId,
      logoPlacement.y,
      logoPlacement.x,
      accessToken
    )
      .then((response) => {
        setMergedImageUrl(response.data.fileUrl);
        openModal(true);
      })
      .catch((error) => {
        console.error(error);
        alert(strings.alert.unableToCompleteFrame);
      });
  };

  const openModal = (approve) => {
    setApproveDesign(approve);
    setApproveDeclineModalIsOpen(true);
  };

  const handleSave = () => {
    const categoryIds = categories.map((category) => category.id);
    const data = {
      frameData: {
        categoryIds: categoryIds,
        frameColors: {
          primary: colorScheme.frameColors.primary,
          secondary: colorScheme.frameColors.secondary,
          tertiary: colorScheme.frameColors.tertiary,
        },
        logoUrl: logoUrl,
        seasonFormat: seasonInformation,
        textColors: {
          primary: colorScheme.textColors.primary,
          secondary: colorScheme.textColors.secondary,
          tertiary: colorScheme.textColors.tertiary,
        },
      },
    };

    putFrameOrder(data, frameOrderId, accessToken)
      .then(() => {
        location.reload();
        setNeedsSaving(false);
      })
      .catch((error) => {
        console.error(error);
        alert(strings.alert.unableToSaveChanges);
      });
  };

  const handleDeclineOrderClick = () => {
    declineFrameOrder(frameOrderId, accessToken)
      .then(() => {
        location.reload();
      })
      .catch((error) => {
        console.error(error);
        alert(strings.alert.couldNotDeclineOrder);
      });
  };

  const handleApproveOrderClick = () => {
    const data = {
      backgroundImage: {
        src: mergedImageUrl,
        type: 'url',
      },
      categoryId: null,
      categoryIds: categories.map((category) => category.id),
      code: null,
      components: getTextComponents(
        frameOrder.frameData.frameType,
        colorScheme,
        seasonInformation
      ),
      defaultImage: null,
      name: [
        {
          key: 'eng',
          value: `${frameOrder?.frameData?.clubEventInfo}-${seasonInformation}`,
        },
      ],
      priority: 0,
      size: {
        height: 1350,
        width: 1080,
      },
      status: 'ACTIVE',
    };

    approveFrameOrder(frameOrderId, accessToken, data)
      .then(() => {
        location.reload();
      })
      .catch((error) => {
        console.error(error);
        alert(strings.alert.somethingWentWrong);
      });
  };

  const [logoPlacement, setLogoPlacement] = useState({
    x: 0,
    y: 0,
  });
  const updateLogoPlacement = (event) => {
    const { value, name } = event;
    setLogoPlacement({ ...logoPlacement, [name]: value });
  };

  if (!accessToken) {
    return (
      <div className={styles.loginPage}>
        <LoginForm />
      </div>
    );
  }

  return (
    <Theme as="div" theme="g100" className={styles.frameOrder}>
      <Header>
        <nav>
          <Button
            hasIconOnly
            iconDescription={strings.back}
            style={{ textDecoration: 'none' }}
            href={ROUTES.frameOrders}
            tooltipPosition="bottom"
          >
            <ArrowLeft fill="white" width="16" height="16" />
          </Button>
        </nav>
      </Header>
      <Main>
        <h1>{strings.frameOrder}</h1>
        <div className={styles.orderContainer}>
          {frameOrder && colorScheme && (
            <>
              <div>
                <p
                  className={classNames(
                    styles.orderStatus,
                    orderStatus === 'APPROVED' && styles.approved,
                    orderStatus === 'DENIED' && styles.denied
                  )}
                >{`${strings.design} ${orderStatus}`}</p>
                <p
                  className={styles.created}
                >{`${strings.created}: ${frameOrder.created}`}</p>
                <p
                  className={styles.name}
                >{`${strings.name}: ${frameOrder.name}`}</p>
                <p
                  className={styles.email}
                >{`${strings.email}: ${frameOrder.email}`}</p>
                <p
                  className={styles.phone}
                >{`${strings.phone}: ${frameOrder.phone}`}</p>
                {frameOrder.isAdminOrder === false && (
                  <p
                    className={styles.phone}
                  >{`Paid: ${frameOrder.amount} ${frameOrder.currency}`}</p>
                )}
                {frameOrder.appliedPromoCode && (
                  <p
                    className={styles.phone}
                  >{`Promo code: ${frameOrder.appliedPromoCode}`}</p>
                )}
                {frameOrder.affiliate && (
                  <p
                    className={styles.phone}
                  >{`Affiliate: ${frameOrder.affiliate}`}</p>
                )}
                {frameOrder.isAdminOrder && (
                  <p className={styles.phone}>{`IS ADMIN ORDER`}</p>
                )}
              </div>
              <div className={styles.row}>
                <div className={styles.leftSide}>
                  <FramePreview
                    className={styles.framePreviewWrapper}
                    colorScheme={colorScheme}
                    logoUrl={logoUrl}
                    frameId={frameOrder.frameData.frameType}
                    seasonYear={seasonInformation}
                    showImageButtons={false}
                    logoIsLandscape={logoIsLandscape}
                  />
                  {orderStatus === 'PROCESSING' && (
                    <>
                      <h3
                        className={classNames(
                          styles.h3,
                          styles.logoPlacementHeading
                        )}
                      >
                        {strings.logoPlacementInPercent}
                      </h3>
                      <p className={styles.logoPlacementParagraph}>
                        {strings.ToSeeNewLogoPlacement}
                      </p>
                      <div className={styles.logoPlacementInputs}>
                        <TextInput
                          type="number"
                          id="x-axis"
                          name="x"
                          labelText={strings.xAxisLabel}
                          value={logoPlacement.x}
                          onChange={(event) => {
                            updateLogoPlacement(event.target);
                          }}
                        />
                        <TextInput
                          type="number"
                          name="y"
                          id="y-axis"
                          labelText={strings.yAxisLabel}
                          value={logoPlacement.y}
                          onChange={(event) => {
                            updateLogoPlacement(event.target);
                          }}
                        />
                      </div>
                      <div className={styles.declineApproveButtonsContainer}>
                        <Button
                          kind="primary"
                          renderIcon={(props) => (
                            <Review width={16} height={16} {...props} />
                          )}
                          onClick={mergeFrame}
                          disabled={needsSaving}
                        >
                          {strings.preview}
                        </Button>
                        <Button
                          kind="danger"
                          renderIcon={(props) => (
                            <Decline width={16} height={16} {...props} />
                          )}
                          onClick={() => {
                            openModal(false);
                          }}
                          disabled={needsSaving}
                        >
                          {strings.decline}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <h3 className={styles.h3}>{strings.chosenColors}</h3>
                  <Palette
                    colors={colorScheme.frameColors}
                    updateColors={updateFrameColors}
                    className={styles.palette}
                    frameLabel="frameColor"
                    disabled={orderStatus !== 'PROCESSING'}
                  />
                  <Palette
                    colors={colorScheme.textColors}
                    updateColors={updateTextColors}
                    className={styles.palette}
                    disabled={orderStatus !== 'PROCESSING'}
                  />
                  <h3
                    className={classNames(
                      styles.h3,
                      styles.chosenCategoriesHeading
                    )}
                  >
                    {strings.chosenCategories}
                  </h3>
                  <CategoryPills
                    categories={categories.filter(
                      (category) => category.type === 'SPORTS_TYPE'
                    )}
                    status={
                      orderStatus === 'PROCESSING' ? 'deletable' : 'disabled'
                    }
                    title={strings.sport}
                    handleDelete={handleDeleteCategoryClick}
                  />
                  <CategoryPills
                    categories={categories.filter(
                      (category) => category.type === 'COUNTRY'
                    )}
                    status={
                      orderStatus === 'PROCESSING' ? 'deletable' : 'disabled'
                    }
                    title={strings.country}
                    handleDelete={handleDeleteCategoryClick}
                  />
                  <CategoryPills
                    categories={
                      clubCupOrCampName
                        ? [
                            {
                              name: [
                                {
                                  languageKey: 'eng',
                                  value: clubCupOrCampName,
                                },
                              ],
                            },
                          ]
                        : []
                    }
                    title={strings.clubCupOrCampName}
                    status={
                      orderStatus === 'PROCESSING' ? 'deletable' : 'disabled'
                    }
                    handleDelete={handleDeleteClubCupOrCamp}
                  />
                  {frameOrder?.frameData?.sportInfo && (
                    <CategoryPills
                      categories={[
                        {
                          name: [
                            {
                              languageKey: 'eng',
                              value: frameOrder?.frameData?.sportInfo,
                            },
                          ],
                        },
                      ]}
                      title={strings.extraCategoryNeededToBeAdded}
                      status="addCategory"
                    />
                  )}
                  <h3
                    className={classNames(
                      styles.h3,
                      styles.seasonInformationHeading
                    )}
                  >
                    {strings.SelectedSeasonFormat}
                  </h3>
                  <TextInput
                    className={styles.seasonFormatInput}
                    value={seasonInformation ?? ''}
                    onChange={(event) => {
                      setSeasonInformation(event.target.value);
                    }}
                    id="admin-season-format"
                    labelText={strings.seasonFormat}
                    disabled={orderStatus !== 'PROCESSING'}
                  />
                  <h3 className={classNames(styles.h3, styles.logotypeHeading)}>
                    {strings.logotype}
                  </h3>
                  <DragDropFile
                    logoUrl={logoUrl}
                    handleUploadLogo={handleUploadLogo}
                    loading={loading}
                    isDisabled={orderStatus !== 'PROCESSING'}
                  />
                  {orderStatus === 'PROCESSING' && (
                    <div className={styles.saveButtonContainer}>
                      <Button
                        className={classNames(
                          needsSaving && styles.buttonNeedsSaving
                        )}
                        renderIcon={(props) => (
                          <Save width={16} height={16} {...props} />
                        )}
                        onClick={handleSave}
                      >
                        {strings.saveChanges}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Main>
      {/*confirm delete modal*/}
      <Modal
        open={modalData !== undefined}
        danger
        modalHeading={modalData?.title}
        primaryButtonText={strings.remove}
        secondaryButtonText={strings.cancel}
        onRequestClose={() => {
          setModalData(undefined);
        }}
        onRequestSubmit={() => {
          modalData.onSubmit();
          setModalData(undefined);
        }}
        size="sm"
      />
      {approveDeclineModalIsOpen && (
        <ApproveDeclineFrameDesignModal
          approve={approveDesign}
          closeModal={() => {
            setApproveDeclineModalIsOpen(false);
          }}
          imageSource={mergedImageUrl}
          onDeclineClick={handleDeclineOrderClick}
          onApproveClick={handleApproveOrderClick}
        />
      )}
    </Theme>
  );
}

export default FrameOrder;
