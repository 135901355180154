import styles from './dragDropFile.module.scss';
import { useState } from 'react';
import classNames from 'classnames';
import { Loading } from '@carbon/react/es/components/Loading';

function DragDropFile({
  handleUploadLogo,
  logoUrl,
  loading,
  validate,
  isDisabled,
}) {
  const [dragActive, setDragActive] = useState(false);
  let inputRef;

  const handleDrag = (event) => {
    if (isDisabled) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (event) => {
    if (isDisabled) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleUploadLogo(event.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      handleUploadLogo(event.target.files[0]);
    }
  };

  const onButtonClick = () => {
    if (inputRef !== null && !isDisabled) {
      inputRef.click();
    }
  };

  return (
    <form
      id="form-file-upload"
      className={styles.dragDropFile}
      onDragEnter={handleDrag}
      onSubmit={(event) => event.preventDefault()}
    >
      <input
        type="file"
        accept=".png,.svg,.webp"
        id="input-file-upload"
        className={styles.input}
        onChange={handleChange}
        ref={(input) => {
          inputRef = input;
        }}
        disabled={isDisabled}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={classNames(
          styles.label,
          dragActive && styles.dragActive,
          validate && styles.warningBorder
        )}
      >
        <div>
          {loading ? (
            <Loading className={styles.spinner} withOverlay={false} />
          ) : !logoUrl ? (
            <p className={styles.paragraph} />
          ) : (
            <img className={styles.uploadedLogo} src={logoUrl} />
          )}
        </div>
      </label>
      {dragActive && (
        <div
          className={styles.dragFileElement}
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
      {!isDisabled && (
        <button onClick={onButtonClick} className={styles.button}>
          {logoUrl ? 'Change logo' : 'Upload logo'}
        </button>
      )}
    </form>
  );
}

export default DragDropFile;
