import { MultiSelect } from '@carbon/react/es/components/MultiSelect';
import { Controller } from 'react-hook-form';

export function MultiSelectInput({
  name,
  control,
  label,
  items,
  initialSelectedItems,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={{ selectedItems: initialSelectedItems }}
      render={({ field, onChange }) => {
        return (
          <MultiSelect
            id={field.name}
            onChange={onChange}
            label={label}
            items={items}
            itemToString={(item) => (item ? item.text : '')}
            initialSelectedItems={initialSelectedItems}
            {...field}
          ></MultiSelect>
        );
      }}
    />
  );
}
