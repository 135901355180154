import classNames from 'classnames';
import React from 'react';
import { generateFontFamily } from '../../fonts/FontUtils';
import { getTranslationValue } from '../../localization/localtizationUtils';
import { convertHex } from '../../utils/stringUtils';

import styles from './frameViewer.module.scss';

export const PREVIEW_TYPES = {
  hint: 'hint',
  example: 'example',
  edit: 'edit',
};

function FrameViewer({ frame, previewType, frameWidth = 340 }) {
  const frameRatio = frame.size.height / frame.size.width;
  const frameHeight = frameWidth * frameRatio;
  return (
    <div
      className={styles.frameViewer}
      style={{ width: frameWidth, height: frameHeight }}
    >
      <img
        style={{ width: frameWidth, height: frameHeight }}
        className={styles.defaultImage}
        src={frame.defaultImage?.src}
      />
      <img
        style={{ width: frameWidth, height: frameHeight }}
        className={styles.backgroundImage}
        src={frame.backgroundImage?.src}
      />
      {frame.components?.map((component, index) => {
        const letterSpacing = component.font.letterSpacing;
        const size = component.font.size;
        const generatedFontFamily = generateFontFamily(
          component.font.family,
          component.font.weight
        );
        const textAllCaps = component.font.textAllCaps;
        let positionX = component.position.x.offset;
        const positionXGravity = component.position.x.gravity;
        let positionY = component.position.y.offset;
        const positionYGravity = component.position.y.gravity;
        const rotationAnchorPoint = component.position.rotation?.anchorPoint;
        const rotationValue = component.position.rotation?.value;

        let transformOrigin;
        let transform = [];
        if (rotationValue) {
          transform.push(`rotate(${rotationValue}deg)`);
          switch (rotationAnchorPoint) {
            case 'start':
              transformOrigin = 'top left';
              break;
          }
        }
        let color;
        switch (previewType) {
          case PREVIEW_TYPES.hint:
            color = component.font.hintColor;
            break;
          case PREVIEW_TYPES.example:
            color = component.font.color;
            break;
          case PREVIEW_TYPES.edit:
            color = component.font.highlightedColor;
            break;
        }
        const rgbaColor = convertHex(color);
        let left;
        let translateX;
        let bottom;
        let right;
        let top;
        let translateY;
        switch (positionXGravity) {
          case 'start':
            left = frameWidth * positionX;
            break;
          case 'middle':
            left = frameWidth * (positionX + 0.5);
            transform.push('translateX(-50%)');
            break;
          case 'end':
            right = frameWidth * positionX;
            break;
        }
        switch (positionYGravity) {
          case 'start':
            top = frameHeight * positionY;
            break;
          case 'middle':
            top = frameHeight * (positionY + 0.5);
            transform.push('translateY(-50%)');
            break;
          case 'end':
            bottom = frameHeight * positionY;
            break;
        }
        let text = getTranslationValue(component.content.cardHint);
        if (textAllCaps) {
          text = text.toUpperCase();
        }
        const fontSize = frameHeight * size;
        return (
          <p
            key={index}
            style={{
              fontFamily: generatedFontFamily,
              color: rgbaColor,
              right: right,
              bottom: bottom,
              left: left,
              top: top,
              transformOrigin: transformOrigin,
              transform: transform.join(' '),
              fontSize: fontSize,
              letterSpacing: letterSpacing
                ? letterSpacing * fontSize
                : undefined,
            }}
            className={classNames(
              styles.componentText,
              translateY && !translateX && styles.translateY,
              translateX && !translateY && styles.translateX,
              translateX && translateY && styles.translateXAndY
            )}
          >
            {text}
          </p>
        );
      })}
    </div>
  );
}

export default FrameViewer;
