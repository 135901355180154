import { Button as CarbonButton } from '@carbon/react/es/components/Button';

function Button({ children, onClick, type, disabled, className, kind, size }) {
  return (
    <div className={className}>
      <CarbonButton
        kind={kind}
        size={size}
        disabled={disabled}
        type={type}
        onClick={onClick}
      >
        {children}
      </CarbonButton>
    </div>
  );
}

export default Button;
