import React, { useCallback, useState } from 'react';
import styles from './organisationInformation.module.scss';
import SelectCountry from './selectCountry/SelectCountry';
import classNames from 'classnames';
import SelectSport from './selectSport/SelectSport';
import SelectOrganisationType from './selectOrganisationType/SelectOrganisationType';
import { TextInput } from '@carbon/react/es/components/TextInput';
import otherSportArrow from '../../../img/other-sport-arrow.png';
import ButtonsRow from '../buttonsRow/ButtonsRow';

function OrganisationInformation({
  information,
  setInformation,
  sports,
  step,
  setStep,
  countries,
}) {
  const [otherSportIsChosen, setOtherSportIsChosen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [validate, setValidate] = useState(false);

  const handleInputChange = useCallback(
    (event) => {
      const { value, name } = event;
      setInformation({
        ...information,
        [name]: value,
      });
    },
    [information, setInformation]
  );

  const sportIsFilledIn =
    (!otherSportIsChosen && information.sport !== undefined) ||
    (otherSportIsChosen && information.otherSport !== '');

  const informationIsFilledIn =
    information.name !== '' &&
    information.email !== '' &&
    information.country !== undefined &&
    information.organisationName !== '' &&
    sportIsFilledIn;

  const handleValidate = () => {
    setValidate(!informationIsFilledIn);
  };

  return (
    <div className={styles.organisationInformation}>
      <div className={styles.inputsContainer}>
        <h2 className={styles.groupTitle}>Contact info</h2>
        <div className={styles.inputWrapper}>
          <TextInput
            id="information-name"
            name="name"
            placeholder="Your name"
            labelText="Name"
            size="lg"
            onChange={(event) => {
              handleInputChange(event.target);
            }}
            value={information.name}
            invalid={validate && information.name === ''}
            invalidText={'mandatory'}
          />
        </div>
        <div className={styles.inputWrapper}>
          <TextInput
            id="information-email"
            placeholder="Email"
            type="email"
            labelText="Email"
            value={information.email}
            name="email"
            size="lg"
            onChange={(event) => {
              handleInputChange(event.target);
            }}
            invalid={validate && information.email === ''}
            invalidText={'mandatory'}
          />
        </div>
        <div className={styles.inputWrapper}>
          <TextInput
            id="information-phone"
            placeholder="Phone number"
            type="tel"
            labelText="Phone number (optional)"
            value={information.phone}
            name="phone"
            size="lg"
            onChange={(event) => {
              handleInputChange(event.target);
            }}
          />
        </div>
      </div>
      <div className={styles.inputsContainer}>
        <h2 className={classNames(styles.groupTitle, styles.clubInfoTitle)}>
          Club, Cup or Camp info
        </h2>
        <div className={styles.inputWrapper}>
          <SelectCountry
            countries={countries}
            value={information.country}
            onChange={handleInputChange}
            information={information}
            invalid={validate && !information.country}
            invalidText={'mandatory'}
          />
        </div>
        <div className={styles.inputWrapper}>
          <SelectSport
            sports={[...sports, { name: 'Other (specify underneath)' }]}
            onChange={handleInputChange}
            information={information}
            setOtherSportIsChosen={setOtherSportIsChosen}
            invalid={validate && !otherSportIsChosen && !information.sport}
            invalidText={'mandatory'}
          />
        </div>
        {otherSportIsChosen && (
          <div
            className={classNames(
              styles.otherSportContainer,
              styles.inputWrapper
            )}
          >
            <img
              className={styles.otherSportArrow}
              alt=""
              src={otherSportArrow}
            />
            <TextInput
              name="otherSport"
              size="lg"
              onChange={(event) => {
                handleInputChange(event.target);
              }}
              id="other-sport"
              labelText="Other sport"
              invalid={otherSportIsChosen && information.otherSport === ''}
              invalidText={'mandatory'}
            />
          </div>
        )}
        <div className={styles.inputWrapper}>
          <SelectOrganisationType
            onChange={handleInputChange}
            types={['Club', 'Cup', 'Camp']}
            information={information}
          />
        </div>
        <div className={styles.inputWrapper}>
          <TextInput
            id="organisation-name"
            labelText="Name of your club, cup or camp"
            name="organisationName"
            value={information.organisationName}
            onChange={(event) => {
              handleInputChange(event.target);
            }}
            size="lg"
            invalid={validate && information.organisationName === ''}
            invalidText={'mandatory'}
          />
        </div>
      </div>
      <ButtonsRow
        step={step}
        setStep={setStep}
        errorMessage={errorMessage}
        nextButtonText="Next"
        onNextClick={() => {
          !informationIsFilledIn ? handleValidate() : setStep(step + 1);
        }}
      />
    </div>
  );
}

export default OrganisationInformation;
