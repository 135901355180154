import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import AdminPage from './pages/admin/AdminPage';
import EditFramePage from './pages/admin/editFrame/EditFramePage';
import Privacy from './pages/privacy';
import Splash from './pages/start';
import { Categories } from './pages/admin/categories/categories';
import { Category } from './pages/admin/categories/category';
import './app.scss';
import '@carbon/styles/css/styles.css';
import CardCreator from './pages/frameCreator/CardCreator';
import FrameOrders from './pages/admin/frameOrders/FrameOrders';
import FrameOrder from './pages/admin/frameOrders/frameOrder/FrameOrder';
import SuccessPage from './pages/frameCreator/successPage/SuccessPage';
import TermsAndConditionsPage from './pages/frameCreator/termsAndConditions/TermsAndConditionsPage';
import { ManagePromoCodesPage } from './pages/admin/promoCodes/ManagePromoCodesPage';
import { CreatePromoCodesPage } from './pages/admin/promoCodes/CreatePromoCodesPage';

export const ROUTES = {
  home: '/',
  privacy: '/privacy',
  admin: '/admin',
  adminFrameEdit: '/admin/frames/:frameId',
  adminFrameEditNoId: '/admin/frames/',
  categories: '/admin/categories',
  category: '/admin/category',
  frameOrders: '/admin/frame-orders',
  frameOrder: '/admin/frame-order',
  cardCreator: '/card-creator',
  promoCodes: '/admin/promo-codes',
  createPromoCodes: '/admin/create-promo-codes',
  cardCreatorSuccess: '/card-creator/success',
  cardCreatorTermsAndConditions: '/card-creator/terms-and-conditions',
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path={ROUTES.home}>
          <Splash />
        </Route>
        <Route path={ROUTES.privacy}>
          <Privacy />
        </Route>
        <Route exact path={ROUTES.admin}>
          <AdminPage />
        </Route>
        <Route path={ROUTES.adminFrameEdit}>
          <EditFramePage />
        </Route>
        <Route path={ROUTES.categories}>
          <Categories />
        </Route>
        <Route path={ROUTES.promoCodes}>
          <ManagePromoCodesPage />
        </Route>
        <Route path={ROUTES.createPromoCodes}>
          <CreatePromoCodesPage />
        </Route>
        <Route path={`${ROUTES.category}/:categoryId`}>
          <Category />
        </Route>
        <Route path={ROUTES.category}>
          <Category />
        </Route>
        <Route path={ROUTES.frameOrders}>
          <FrameOrders />
        </Route>
        <Route path={`${ROUTES.frameOrder}/:frameOrderId`}>
          <FrameOrder />
        </Route>
        <Route path={`${ROUTES.cardCreatorSuccess}`}>
          <SuccessPage />
        </Route>
        <Route path={`${ROUTES.cardCreatorTermsAndConditions}`}>
          <TermsAndConditionsPage />
        </Route>
        <Route path={`${ROUTES.cardCreator}`}>
          <CardCreator />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
