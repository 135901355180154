import styles from './framePreview.module.scss';
import { useState } from 'react';
import exampleImageOne from '../../../img/example_white.jpg';
import exampleImageTwo from '../../../img/example2-cropped.jpg';
import classNames from 'classnames';
import BackgroundSVG from './backgroundSVG/BackgroundSVG';
import { FrameById } from '../../../utils/frameCreatorUtils';

function FramePreview({
  colorScheme,
  logoUrl,
  frameId,
  seasonYear,
  className,
  showImageButtons,
  logoIsLandscape,
}) {
  const [showPhoto, setShowPhoto] = useState(false);
  const [imageSrc, setImageSrc] = useState(undefined);

  const frameProps = {
    colorScheme: colorScheme,
    logoUrl: logoUrl ?? '',
    showText: true,
    showLogoPlaceHolder: !logoUrl,
    showSeasonYear: false,
    seasonYear: seasonYear,
    logoIsLandscape: logoIsLandscape,
  };

  const handleImageButtonClick = (value) => {
    if (value === 'no-image') {
      setShowPhoto(false);
      setImageSrc(undefined);
    } else {
      setShowPhoto(true);
      if (value === 'example-one') {
        setImageSrc(exampleImageOne);
      }
      if (value === 'example-two') {
        setImageSrc(exampleImageTwo);
      }
    }
  };

  return (
    <div className={className}>
      <div className={styles.framePreviewInnerWrapper}>
        {colorScheme && (
          <div className={styles.frameWrapper}>
            <FrameById
              frameId={frameId}
              frameProps={frameProps}
              logoIsLandscape={logoIsLandscape}
            />
          </div>
        )}
        {showPhoto && (
          <img
            className={styles.placeholderPhoto}
            src={imageSrc}
            alt={'placeholder'}
          />
        )}
        <BackgroundSVG className={styles.backgroundSVG} />
      </div>
      {showImageButtons && (
        <>
          <div className={styles.imageButtonsContainer}>
            <button
              className={classNames(
                styles.button,
                !showPhoto && styles.activeButton
              )}
              onClick={() => {
                handleImageButtonClick('no-image');
              }}
            >
              No image
            </button>
            <button
              className={classNames(
                styles.button,
                imageSrc === exampleImageOne && styles.activeButton
              )}
              onClick={() => {
                handleImageButtonClick('example-one');
              }}
            >
              Example 1
            </button>
            <button
              className={classNames(
                styles.button,
                imageSrc === exampleImageTwo && styles.activeButton
              )}
              onClick={() => {
                handleImageButtonClick('example-two');
              }}
            >
              Example 2
            </button>
          </div>
          <p className={styles.paragraph}>
            Try the example images on your design
          </p>
        </>
      )}
    </div>
  );
}

export default FramePreview;
