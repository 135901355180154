import classNames from 'classnames';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import Button from '../../components/button/Button';
import ErrorAndLoader from '../../components/errorAndLoader/ErrorAndLoader';
import {
  updateFrame as apiUpdateFrame,
  createFrame as apiCreateFrame,
} from '../../connectivity/admin/frames/ApiAdminFrames';
import { ROUTES } from '../../index';
import { strings } from '../../localization/Localization';
import { COOKIES_ADMIN, getAccessToken } from '../../utils/cookieUtils';
import FrameViewer, { PREVIEW_TYPES } from '../frameViewer/FrameViewer';

import styles from './frameHolder.module.scss';

function FrameHolder({ frame, className }) {
  const [previewType, setPreviewType] = useState(PREVIEW_TYPES.example);
  const [cookies] = useCookies([COOKIES_ADMIN]);
  const accessToken = getAccessToken(cookies);
  const [loading, setLoading] = useState(undefined);
  const [error, setError] = useState(undefined);
  const history = useHistory();
  const updateFrame = () => {
    setLoading(true);
    setError(undefined);
    apiUpdateFrame(accessToken, frame.id, frame)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };
  const createFrame = () => {
    setLoading(true);
    setError(undefined);
    apiCreateFrame(accessToken, frame)
      .then(() => {
        history.push(ROUTES.admin);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };
  const isUpdateFrame = frame?.id !== undefined;
  return (
    <div className={classNames(styles.frameHolder, className)}>
      <FrameViewer frame={frame} previewType={previewType} />
      <div className={styles.typeButtons}>
        {Object.values(PREVIEW_TYPES).map((type, index) => {
          return (
            <div key={index} className={styles.typeButton}>
              <Button
                className={styles.typeButton}
                disabled={type === previewType}
                onClick={() => setPreviewType(type)}
              >
                {type}
              </Button>
            </div>
          );
        })}
      </div>
      <ErrorAndLoader loading={loading} error={error} />
      <div>
        <Button
          disabled={loading}
          className={styles.updateCreateButton}
          onClick={isUpdateFrame ? updateFrame : createFrame}
        >
          {isUpdateFrame ? strings.publishUpdates : strings.publishFrame}
        </Button>
      </div>
    </div>
  );
}

export default FrameHolder;
