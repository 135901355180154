import styles from './termsAndConditionsPage.module.scss';
import strikeLogo from '../../../img/strike-logo.svg';

function TermsAndConditionsPage() {
  return (
    <div className={styles.termsAndAgreementsPage}>
      <div className={styles.strikeIconContainer}>
        <a href="https://www.striketradingcards.com">
          <img height={65} width={112} src={strikeLogo} alt="strike-logo" />
        </a>
      </div>
      <div className={styles.contentContainer}>
        <h1 className={styles.heading}>
          General Terms and Conditions for Strike Trading Cards Card creator:
        </h1>
        <ol>
          <li>
            <h3 className={styles.h3}>Use of Strike:</h3>
            <ol className={styles.innerList}>
              <li className={styles.innerListItem}>
                <span>
                  Strike allows You to create a template frame with Your logo
                  and name that users of Strike can use to create personalized
                  sport cards of.
                </span>
              </li>
              <li className={styles.innerListItem}>
                By using Strike, you grant StrikeTradingCards permission to use
                Your logo and name to create the template frames for digital and
                printed sport cards.
              </li>
              <li className={styles.innerListItem}>
                StrikeTradingCards may use Your logo as marketing material,
                including but not limited to promotional campaigns, website,
                social media, and other marketing channels.
              </li>
            </ol>
          </li>
          <li>
            <h3 className={styles.h3}>Intellectual Property:</h3>
            <ol className={styles.innerList}>
              <li className={styles.innerListItem}>
                <span>
                  You represent and warrant that you have the necessary rights
                  and permissions to grant StrikeTradingCards the permission
                  specified in section 1.
                </span>
              </li>
              <li className={styles.innerListItem}>
                You shall be solely responsible for any intellectual property
                rights infringements that may arise due to the use of Your logo
                and name in the Strike template frames.
              </li>
              <li className={styles.innerListItem}>
                Copyright, patent and all other intellectual property rights to
                the Strike product, are vested in StrikeTradingCards and
                StrikeTradingCards represents the holder of such rights.
              </li>
            </ol>
          </li>
          <li>
            <h3 className={styles.h3}>Warranties and Disclaimers:</h3>
            <ol className={styles.innerList}>
              <li className={styles.innerListItem}>
                <span>
                  StrikeTradingCards does not provide any warranties or
                  guarantees regarding the functionality, accuracy, or
                  reliability of the Strike product.
                </span>
              </li>
              <li className={styles.innerListItem}>
                You understand and agree that the use of Strike is at Your own
                risk, and StrikeTradingCards shall not be liable for any
                damages, losses, or liabilities arising from the use of the
                product.
              </li>
            </ol>
          </li>
          <li>
            <h3 className={styles.h3}>Indemnification:</h3>
            <ol className={styles.innerList}>
              <li className={styles.innerListItem}>
                <span>
                  You agree to indemnify, defend and hold harmless
                  StrikeTradingCards and its officers, directors, employees, and
                  agents from any claims, demands, liabilities, costs, or
                  expenses, including reasonable attorneys' fees, arising out of
                  or related to Your use of the Strike product or any breach of
                  this Agreement.
                </span>
              </li>
            </ol>
          </li>
          <li>
            <h3 className={styles.h3}>Limitation of Liability:</h3>
            <ol className={styles.innerList}>
              <li className={styles.innerListItem}>
                <span>
                  To the maximum extent permitted by law, StrikeTradingCards
                  shall not be liable for any indirect, incidental,
                  consequential, or punitive damages arising out of or in
                  connection with Your use or inability to use the Strike
                  product.
                </span>
              </li>
              <li className={styles.innerListItem}>
                <span>
                  StrikeTradingCards’ total liability under this Agreement,
                  whether in contract, tort (including negligence), or
                  otherwise, shall not exceed the amount paid by You for the use
                  of the Strike product.
                </span>
              </li>
            </ol>
          </li>
          <li>
            <h3 className={styles.h3}>Termination:</h3>
            <ol className={styles.innerList}>
              <li className={styles.innerListItem}>
                <span>
                  Either party may terminate this Agreement at any time by
                  providing a five (7) days written notice to the other party.
                </span>
              </li>
              <li className={styles.innerListItem}>
                <span>
                  Upon termination, You shall immediately cease using the Strike
                  product, and Strike shall no longer have the right to use Your
                  logo and name for any purpose.
                </span>
              </li>
            </ol>
          </li>
          <li>
            <h3 className={styles.h3}>Miscellaneous:</h3>
            <ol className={styles.innerList}>
              <li className={styles.innerListItem}>
                <span>
                  This Agreement shall be governed by and construed in
                  accordance with the laws of the State of Sweden without
                  reference to the principles of conflict of laws.
                </span>
              </li>
              <li className={styles.innerListItem}>
                <span>
                  In the event that any word, phrase, clause, sentence or other
                  provision herein shall violate any applicable statute,
                  ordinance or rule of law in any jurisdiction which governs
                  this Agreement, such provisions shall be effective to the
                  extent of such violation without invalidating any other
                  provision herein.
                </span>
              </li>
              <li className={styles.innerListItem}>
                <span>
                  Any dispute arising out of or in connection with this
                  Agreement shall be subject to the exclusive jurisdiction of
                  the courts in the jurisdiction in which Strike is located.
                </span>
              </li>
            </ol>
          </li>
        </ol>
        <p className={styles.bottomParagraph}>
          Please read these General Terms and Conditions carefully before using
          the Strike product. By using the Strike product, You hereby agree to
          be bound by these terms and conditions set out in this Agreement.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditionsPage;
