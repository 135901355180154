import styles from './pill.module.scss';
import { XIcon } from '../../../../components/icons/XIcon';
import classNames from 'classnames';

function Pill({ text, status, onClick }) {
  switch (status) {
    case 'deletable':
      return (
        <div className={classNames(styles.pill, styles.grey)}>
          <p className={styles.text}>{text}</p>
          {status === 'deletable' && (
            <button
              className={styles.button}
              onClick={() => {
                onClick();
              }}
            >
              {<XIcon width={16} height={16} color={'white'} />}
            </button>
          )}
        </div>
      );
    case 'addCategory':
      return (
        <div className={classNames(styles.pill, styles.turquoise)}>
          <p className={styles.text}>{text}</p>
        </div>
      );
    case 'disabled':
      return (
        <div className={classNames(styles.pill, styles.disabled)}>
          <p className={styles.text}>{text}</p>
        </div>
      );
  }
}

export default Pill;
