import styles from './categoryPills.module.scss';
import Pill from '../pill/Pill';

function CategoryPills({ categories, status, title, handleDelete }) {
  return (
    <div className={styles.categoryPills}>
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.pillsContainer}>
        {categories.map((category, index) => {
          const text = category.name.find(
            (name) => name.languageKey === 'eng'
          ).value;
          return (
            <Pill
              key={category.id ?? index}
              text={text}
              status={status}
              onClick={() => {
                handleDelete(category.id, text);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default CategoryPills;
