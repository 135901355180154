import styles from './frameOrderCard.module.scss';
import classNames from 'classnames';
import { Button } from '@carbon/react/es/components/Button';
import { ROUTES } from '../../../../index';
import { Review } from '../../../../components/icons/Review';
import { strings } from '../../../../localization/Localization';

function FrameOrderCard({ frameOrder }) {
  const getClassName = (days) => {
    return classNames(
      styles.daysLeft,
      days === 0
        ? styles.red
        : days > 0 && days < 3
        ? styles.yellow
        : styles.green
    );
  };
  return (
    <div className={styles.frameOrderCard}>
      <div className={styles.informationColumn}>
        <div className={styles.dateRow}>
          <span>{frameOrder.created}</span>
          {frameOrder.orderStatus === 'PROCESSING' ? (
            <span className={getClassName(frameOrder.daysLeft)}>
              {`${frameOrder.daysLeft} ${strings.daysLeftToApprove}`}
            </span>
          ) : (
            <span
              className={
                frameOrder.orderStatus === 'APPROVED'
                  ? styles.approved
                  : styles.denied
              }
            >
              {frameOrder.orderStatus}
            </span>
          )}
        </div>
        {frameOrder.isAdminOrder === false && (
          <p
            className={styles.nameAndEmailParagraph}
          >{`Paid: ${frameOrder.amount} ${frameOrder.currency}`}</p>
        )}
        {frameOrder.appliedPromoCode && (
          <p
            className={styles.nameAndEmailParagraph}
          >{`Promo code: ${frameOrder.appliedPromoCode}`}</p>
        )}
        {frameOrder.affiliate && (
          <p
            className={styles.nameAndEmailParagraph}
          >{`Affiliate: ${frameOrder.affiliate}`}</p>
        )}
        {frameOrder.isAdminOrder === true && (
          <p className={styles.nameAndEmailParagraph}>ORDER BY ADMIN</p>
        )}
        <p className={styles.nameAndEmailParagraph}>{frameOrder.name}</p>
        <p className={styles.nameAndEmailParagraph}>{frameOrder.email}</p>
      </div>
      <Button
        href={`${ROUTES.frameOrder}/${frameOrder.id}`}
        renderIcon={(props) => <Review width={16} height={16} {...props} />}
        style={{ textDecoration: 'none' }}
      >
        {frameOrder.orderStatus === 'PROCESSING'
          ? strings.review
          : strings.view}
      </Button>
    </div>
  );
}

export default FrameOrderCard;
