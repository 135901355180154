import { API_URL, getBaseRequestConfig } from '../../baseRequestConfig';
import httpRequest from '../../httpRequest';

export function getFrameOrders(accessToken) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/admin-api/v1/orders`,
    method: 'GET',
  });

  return httpRequest(requestConfig);
}

export function getFrameOrder(orderId, accessToken) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/admin-api/v1/orders/${orderId}`,
    method: 'GET',
  });

  return httpRequest(requestConfig);
}

export function putFrameOrder(data, orderId, accessToken) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/admin-api/v1/orders/${orderId}`,
    method: 'PUT',
    data,
  });

  return httpRequest(requestConfig);
}

export function declineFrameOrder(orderId, accessToken) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/admin-api/v1/orders/${orderId}/decline`,
    method: 'PUT',
  });

  return httpRequest(requestConfig);
}

export function approveFrameOrder(orderId, accessToken, data) {
  const baseRequestConfig = getBaseRequestConfig(accessToken);

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/admin-api/v1/orders/${orderId}/accept`,
    method: 'POST',
    data,
  });

  return httpRequest(requestConfig);
}

export function completeFrame(
  frameImage,
  orderId,
  logoPlacementPercentHeight,
  logoPlacementPercentWidth,
  accessToken
) {
  const baseRequestConfig = getBaseRequestConfig(
    accessToken,
    'multipart/form-data'
  );

  const formData = new FormData();
  formData.append('frameSvg', frameImage);

  const requestConfig = Object.assign({}, baseRequestConfig, {
    url: `${API_URL}/admin-api/v1/images/complete-frame?orderId=${orderId}&logoPlacementPercentHeight=${logoPlacementPercentHeight}&logoPlacementPercentWidth=${logoPlacementPercentWidth}`,
    method: 'POST',
    data: formData,
  });

  return httpRequest(requestConfig);
}
