import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styleFrame.module.scss';
import Palette from './palette/Palette';
import DragDropFile from './dragDropFile/DragDropFile';
import classNames from 'classnames';
import { Select } from '@carbon/react/es/components/Select';
import { SelectItem } from '@carbon/react/es/components/SelectItem';
import { TextInput } from '@carbon/react/es/components/TextInput';
import ButtonsRow from '../buttonsRow/ButtonsRow';
import { postLogo } from '../../../connectivity/frameCreator/apiLogo';
import {
  getLogoSizeRequirements,
  resizeFile,
} from '../../../utils/frameCreatorUtils';
import { ROUTES } from '../../../index';

function StyleFrame({
  colorScheme,
  setColorScheme,
  logoUrl,
  setLogoUrl,
  step,
  setStep,
  rightsToUseLogo,
  setRightsToUseLogo,
  seasonYear,
  setSeasonYear,
  frameId,
  setLogoIsLandscape,
}) {
  const [seasonFormatInformation, setSeasonFormatInformation] =
    useState('yyyy/yy');
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);

  const logoParagraphRef = useRef(null);

  const updateFrameColors = useCallback(
    (event) => {
      const { name, value } = event;
      setColorScheme({
        ...colorScheme,
        frameColors: {
          ...colorScheme.frameColors,
          [name]: value,
        },
      });
    },
    [colorScheme, setColorScheme]
  );

  const updateTextColors = useCallback(
    (event) => {
      const { name, value } = event;
      setColorScheme({
        ...colorScheme,
        textColors: {
          ...colorScheme.textColors,
          [name]: value,
        },
      });
    },
    [colorScheme, setColorScheme]
  );

  const handleUploadLogo = async (file) => {
    if (!file) {
      setLogoUrl('');
      return;
    }
    setLoading(true);

    const logoSizeRequirements = getLogoSizeRequirements(frameId);

    //check if logo is too small and see if landscape or portrait
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = async () => {
        const { height, width } = image;
        if (width > height) {
          setLogoIsLandscape(true);
        } else {
          setLogoIsLandscape(false);
        }
        if (
          width >= logoSizeRequirements.width &&
          height >= logoSizeRequirements.height
        ) {
          try {
            const image = await resizeFile(
              file,
              logoSizeRequirements.width,
              logoSizeRequirements.height
            );

            postLogo(image)
              .then((response) => {
                setLogoUrl(response.data.fileUrl);
                setLoading(false);
              })
              .catch((error) => {
                console.error(error);
                alert('Something went wrong, please try again');
                setLoading(false);
              });
          } catch (error) {
            console.log(error);
            alert('Something went wrong, please try again');
            setLoading(false);
          }
        } else {
          setLoading(false);
          alert(
            'The logo you are trying to upload is to small (low quality). It will not look defined on the final card. Please upload a larger (better quality) image.'
          );
        }
      };
    };
  };

  const handleValidation = () => {
    setValidate(true);
    logoParagraphRef.current.scrollIntoView();
  };

  useEffect(() => {
    if (logoUrl && rightsToUseLogo) {
      setValidate(false);
    }
  }, [logoUrl, rightsToUseLogo, validate]);

  return (
    <div className={styles.styleFrame}>
      <div className={styles.uploadLogoContainer}>
        <h2 className={styles.heading}>Logotype</h2>
        <p ref={logoParagraphRef} className={styles.subHeading}>
          Make sure to use a logotype with format PNG or a SVG with transparent
          background. For best result the images width and height should be as
          equal as possible.
        </p>
        <DragDropFile
          handleUploadLogo={handleUploadLogo}
          logoUrl={logoUrl}
          loading={loading}
          validate={validate && !logoUrl}
        />
      </div>
      <div
        className={classNames(
          validate && !rightsToUseLogo && styles.warningBorder,
          styles.rightsLabelAndCheckbox
        )}
      >
        <input
          id="rights-to-use-logo-checkbox"
          type="checkbox"
          checked={rightsToUseLogo}
          onChange={() => {
            setRightsToUseLogo(!rightsToUseLogo);
          }}
        />
        <p className={styles.rightsLabel}>
          I have read the{' '}
          <a
            className={styles.termsAndAgreements}
            href={ROUTES.cardCreatorTermsAndConditions}
            target="_blank"
            rel="noreferrer"
          >
            general terms and conditions
          </a>{' '}
          and assure that I have the rights to use and to give permissions for
          Strike to use this logo
        </p>
      </div>
      <h2 className={styles.heading}>Add your colors</h2>
      <p className={styles.subHeading}>
        Change colors to make your template follow your branding. If you are not
        on a mobile device, you can try the pipette tool by clicking on the
        color box and choosing the pipette icon
      </p>
      <Palette
        colors={colorScheme.frameColors}
        updateColors={updateFrameColors}
        frameLabel="frameColor"
        className={styles.firstPalette}
      />
      <Palette
        colors={colorScheme.textColors}
        updateColors={updateTextColors}
        className={styles.secondPalette}
      />

      <h2 className={classNames(styles.heading, styles.seasonInformation)}>
        Season information
      </h2>
      <p className={styles.subHeading}>
        Choose if your season spans over one or two years
      </p>
      <div className={styles.seasonInformationInputs}>
        <div className={styles.inputWrapper}>
          <Select
            className={styles.input}
            id="season-format-information"
            labelText="Season format"
            value={seasonFormatInformation}
            onChange={(event) => {
              setSeasonYear('');
              setSeasonFormatInformation(event.target.value);
            }}
          >
            <SelectItem text="Overlapping (yyyy/yy)" value={'yyyy/yy'} />
            <SelectItem text="One year (yyyy)" value={'yyyy'} />
          </Select>
        </div>
        <div className={styles.inputWrapper}>
          <TextInput
            className={styles.input}
            id="season-information"
            labelText={`Season year (${seasonFormatInformation})`}
            placeholder={`Season year (${seasonFormatInformation})`}
            value={seasonYear}
            onChange={(event) => {
              setSeasonYear(event.target.value);
            }}
          />
        </div>
      </div>
      <ButtonsRow
        step={step}
        setStep={setStep}
        /*     nextButtonIsDisabled={!rightsToUseLogo || !logoUrl}*/
        nextButtonText="Next"
        onNextClick={() => {
          !rightsToUseLogo || !logoUrl ? handleValidation() : setStep(step + 1);
        }}
      />
    </div>
  );
}

export default StyleFrame;
