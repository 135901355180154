import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import styles from './footer.module.scss';
class Footer extends Component {
  render() {
    return (
      <footer className={styles.footer}>
        <Container fluid>
          <Row>
            <Col xs={12} md={4} className={styles.footerLeft}>
              <span>
                Created by{' '}
                <a
                  href="https://www.wearelevels.com"
                  target="_BLANK"
                  rel="noreferrer"
                >
                  Levels
                </a>
              </span>
            </Col>
            <Col
              xs={12}
              md={{ span: 4, offset: 4 }}
              className={styles.footerRight}
            >
              <span>
                <Link to="/privacy">Privacy Notice</Link>
              </span>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
