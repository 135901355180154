import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import styles from './index.module.scss';

function Privacy() {
  return (
    <div className={styles.privacyPolicy}>
      <img src="img/logo-blue.svg" alt="logo" style={{ textAlign: 'middle' }} />
      <h1> Privacy policy </h1>
      <h2>Processing of personal data</h2>
      <p>
        We take care of your privacy. You should be able to feel secure when
        entrusting your personal data to us. For this reason, we have drawn up
        this policy. It is based on current data protection legislation and
        clarifies how we work to safeguard your rights and privacy.
      </p>
      <br />
      <p>
        The purpose of this policy is to make you aware of how we process your
        personal data, what we use them for, who may view them and under what
        conditions, and how you can safeguard your rights.
      </p>
      <br />
      <br />

      <h2>Why do we process your personal data?</h2>
      <p>
        We process your personal data in order to provide you with our services
        and products, i.e. only to enter into or fulfil our obligations to you.
      </p>
      <br />
      <br />

      <h2>What information do we collect and why?</h2>
      <p>
        We collect personal information that you enter such as name, e-mail,
        pictures, text on pictures and workflow data for optimizing the product
        to make our services work efficiently and give you the best possible
        experience.
      </p>
      <br />
      <br />

      <h2>How do we protect your personal data?</h2>
      <p>
        Your security is important to us. Therefore, we have taken appropriate
        technical, organisational and administrative security measures to
        protect your personal data from unauthorised access and other
        unauthorised processing. We regularly analyse and evaluate the measures
        in order to ensure that protection of your data is as safe as possible.
      </p>
      <br />
      <br />

      <h2>Who do we disclose your data to?</h2>
      <p>
        We do not disclose your data to other companies or organisations unless
        required by law or necessary to perform our statutory or contractual
        obligations towards you.
      </p>
      <br />
      <p>
        We may disclose your personal data to any of our partners, suppliers or
        subcontractors, but only if that is necessary for us to meet our
        obligations in relation to you as a customer. We never provide more
        personal data than is necessary.
      </p>
      <br />
      <p>
        When required by law, we may need to disclose your data to public
        authorities and other organisations. We may also need to disclose your
        data if it is necessary to exercise, establish or monitor our legal
        claims.
      </p>
      <br />
      <br />

      <h2>How long do we process your personal data?</h2>
      <p>
        We retain data about you as a customer for the duration of the agreement
        with you and for a reasonable time thereafter. In most cases, this means
        that we do not retain your personal data for a period of more than one
        year from the end of the agreement, but under certain conditions we may
        keep your data for a longer period of time. This applies, for example,
        when required by law or when the data may be needed to establish,
        exercise and monitor legal claims.
      </p>
      <br />
      <br />

      <h2>Your rights</h2>
      <p>
        When we process personal data about you, you as registered have a number
        of rights. You have the right to contact us at any time with respect to
        these, and if you wish to exercise any of the rights described below,
        you can reach us most easily at{' '}
        <a
          href="mailto:contact@striketradingcards.com?Subject=Strike%20app%20feedback"
          target="_top"
        >
          contact@striketradingcards.com
        </a>
        .
      </p>
      <br />
      <p>
        We reserve the right to take appropriate protective and security
        measures in order to ensure that you are the person you claim to be when
        you contact us. If you cannot satisfactorily demonstrate your identity,
        we may not be able to respond to your request.
      </p>
      <br />
      <i>Access to personal data</i>
      <br />
      <p>
        You have the right to know what personal data we process about you. If
        you wish to know, you can get a compiled register extract from us that
        contains all the personal data we process about you.
      </p>
      <br />
      <i>Correction and deletion</i>
      <br />
      <p>
        If we process your personal data incorrectly or if we no longer need the
        data, you are entitled to have it deleted. If the data is incomplete,
        you have the right to have it supplemented. Please keep in mind that we
        may not be able to provide you with our services if you request to have
        your personal data deleted.
      </p>
      <br />
      <i>Right to lodge a complaint</i>
      <br />
      <p>
        You have the right to lodge a complaint with the Swedish Data Protection
        Authority if you believe that we are processing your personal data in an
        inappropriate manner. You can read more about this on the Swedish Data
        Protection Authority's website{' '}
        <a
          href="https://www.datainspektionen.se"
          target="_BLANK"
          rel="noreferrer"
        >
          www.datainspektionen.se
        </a>
        .
      </p>
      <br />

      <h2>Third party products and services</h2>
      <p>
        In general, our services and products access third party information
        through application interfaces. This Policy does not address and we are
        not responsible for the privacy practices of web sites operated by third
        parties whether they are linked to or accessible from any services or
        products.
      </p>
      <br />
      <br />

      <h2>Users with whom you share your information</h2>
      <p>
        We cannot, and do not, control the information you share with other
        users using our services and products or how such other users will use
        or share such information. We are not responsible for third party
        circumvention of our security measures.
      </p>
      <br />
      <p>
        You are responsible for your created content and what you are sharing
        within and outside the platform.
      </p>
      <br />
      <br />

      <h2>Sensitive information</h2>
      <p>
        We ask that you not send us, and you not disclose, any sensitive
        personal Information (e.g., information related to racial or ethnic
        origin, political opinions, religion or other beliefs, health, sexual
        orientation, criminal background or membership in past organizations,
        including trade union memberships) on or through our services and
        products or otherwise to us.
      </p>
      <br />
      <br />

      <h2>Use of our services and products</h2>
      <p>
        If you object to your Personal Information being transferred or used as
        described in this policy, please do not use any of our services or
        products and immediately delete all applications from your user devices.
      </p>
      <br />
      <br />
      <h2>Privacy policy changes</h2>
      <p>
        If you do not agree to any modifications to this policy, your sole
        recourse is to immediately stop all use of all services and products.
        Your continued use of any services or products following the posting of
        any modifications to this policy will constitute your acceptance of the
        revised policy.
      </p>
      <br />
      <br />
      <h2>Contact</h2>
      <p>
        If you have any questions or comments about this policy or our privacy
        practices, or to report any violations of this policy, or abuse of any
        services or products, please contact us at{' '}
        <a
          href="mailto:contact@striketradingcards.com?Subject=Strike%20app%20feedback"
          target="_top"
        >
          contact@striketradingcards.com
        </a>
        .
      </p>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default Privacy;
