import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../sections/header';
import Content from '../../sections/content';
import Footer from '../../sections/footer';
import styles from './index.module.scss';

class Splash extends Component {
  render() {
    return (
      <div className={styles.strike}>
        <Header />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default Splash;
